import { useState, useEffect } from "react";
import {
    Box,
    Button,
    Flex,
    FormControl,
    FormLabel,
    Grid,
    GridItem,
    Icon,
    Image,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Switch,
    Text,
    useToast
} from "@chakra-ui/react";
import ProductService from "../../services/ProductService";
import { useDispatch, useSelector } from "react-redux";
import { FaTrash } from "react-icons/fa";
import { useTranslation } from "react-i18next";

interface EditImagesProps {
    isOpen: any;
    onClose: any;
    getProductData: any;
}

const EditImages: React.FC<EditImagesProps> = ({ isOpen, onClose, getProductData }) => {
    // redux
    const dispatch = useDispatch();

    const { t } = useTranslation();

    const toast = useToast();
    const [productImages, setProductImages] = useState([]);
    const [selectedImages, setSelectedImages] = useState([]);
    const ProductTableData = useSelector((state: any) => state.Product.selectedProduct);

    const onSelectFile = (event: any) => {
        const selectedFiles = event.target.files;

        const selectedFilesArray: any = Array.from(selectedFiles);
        setProductImages(selectedFilesArray);

        const imagesArray = selectedFilesArray.map((file: any) => {
            return URL.createObjectURL(file);
        });

        setSelectedImages((previousImages: any) => previousImages.concat(imagesArray));

        // FOR BUG IN CHROME
        event.target.value = "";
    };

    function deleteHandler(image: any) {
        setSelectedImages(selectedImages.filter((e) => e !== image));
        setProductImages(selectedImages.filter((e) => e !== image));
        URL.revokeObjectURL(image);
    }

    const uploadProductImages = () => {
        const data = new FormData();

        for (let i = 0; i < productImages.length; i++) {
            data.append("product_photos", productImages[i], "[PROXY]");
        }

        dispatch(
            ProductService.uploadProductImages(
                {
                    data,
                    id: ProductTableData._id
                },
                (responseData: any) => {
                    setProductImages([]);
                    setSelectedImages([]);
                    onClose();
                    getProductData();
                },
                (errorData: any) => {
                    return toast({
                        title: errorData?.message ? errorData?.message : errorData.response?.data?.message,
                        status: "error",
                        variant: "solid",
                        duration: 2000,
                        position: "top-right",
                        isClosable: true
                    });
                }
            )
        );
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{t("product_mgmt.upload_image")}</ModalHeader>
                <ModalCloseButton />

                <ModalBody>
                    <Grid templateColumns="repeat(3, 1fr)" gap={3} my={3}>
                        {selectedImages &&
                            selectedImages.map((image, index) => {
                                return (
                                    <GridItem w="100%" key={image} bgColor={"blackAlpha.300"} position={"relative"}>
                                        <Flex h={"24"} justifyContent={"center"}>
                                            <Image
                                                src={image}
                                                objectFit={"cover"}
                                                align={"center"}
                                                h={{ base: "full", sm: "full", lg: "full" }}
                                            />
                                        </Flex>

                                        <Icon
                                            as={FaTrash}
                                            pos={"absolute"}
                                            top={0}
                                            right={0}
                                            cursor={"pointer"}
                                            onClick={() => deleteHandler(image)}
                                        />
                                    </GridItem>
                                );
                            })}
                    </Grid>

                    {selectedImages.length > 0 ? (
                        selectedImages.length > 10 ? (
                            <p className="error">
                                {t("product_mgmt.you_cannot_upload_more_than_10_images")} <br />
                                <span>
                                    {t("product_mgmt.please_delete")}
                                    <b> {selectedImages.length - 10} </b> {t("product_mgmt.of_them")}
                                </span>
                            </p>
                        ) : (
                            <Button onClick={uploadProductImages} backgroundColor={"green"} color={"white"} size={"sm"}>
                                {t("product_mgmt.upload")} {selectedImages.length} {t("product_mgmt.image")}
                                {selectedImages.length === 1 ? "" : "S"}
                            </Button>
                        )
                    ) : (
                        <Box pos={"relative"}>
                            <Flex
                                h={"xs"}
                                justifyContent={"center"}
                                border={"4px"}
                                borderRadius={"xl"}
                                borderStyle={"dashed"}
                                borderColor={"blackAlpha.100"}
                                backgroundColor={"blackAlpha.50"}
                                alignItems={"center"}
                                cursor={"pointer"}
                            >
                                <Image
                                    alt={"product"}
                                    src={"/images/photo-gallery-icon.svg"}
                                    objectFit={"cover"}
                                    align={"center"}
                                    h={"28"}
                                />
                                <input
                                    type="file"
                                    name="images"
                                    style={{
                                        height: "100%",
                                        width: "100%",
                                        position: "absolute",
                                        top: 0,
                                        left: 0,
                                        opacity: 0
                                    }}
                                    onChange={onSelectFile}
                                    multiple
                                    accept="image/*"
                                />
                            </Flex>

                            {/* <Input
                            type="file"
                            height="100%"
                            width="100%"
                            position="absolute"
                            top="0"
                            left="0"
                            opacity="0"
                            aria-hidden="true"
                            name={"images"}
                            accept="image/*"
                            onChange={onSelectFile}
                        /> */}
                        </Box>
                    )}
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

export default EditImages;
