import { useEffect, useState } from "react";
import { Box, Divider, Flex, Icon, SimpleGrid } from "@chakra-ui/react";

import { useTranslation } from "react-i18next";

// icon
import IconBox from "../../../components/icons/IconBox";
import { MdBarChart } from "react-icons/md";

import { FaUser, FaBook } from "react-icons/fa";
import { ImPriceTag } from "react-icons/im";

// component
import MiniStatistics from "../../../components/card/MiniStatistics";
import DailyTraffic from "./components/DailyTraffic";
import PieCard from "./components/PieCard";
import TotalSpent from "./components/TotalSpent";

// css
import "./index.css";
import { useSelector } from "react-redux";
import { numberWithCommas } from "../../../utils/helperFunctions";

export default function UserReports() {
    const DashboardData = useSelector((state) => state?.Dashboard?.allDashboard);

    const brandColor = "brand.500";
    const boxBg = "secondaryGray.300";
    const { t } = useTranslation();
    //Initializing Data

    //setting Data value
    const PieChartData = useSelector((state) => state?.Dashboard?.pieChartDetails);

    //weekly revenue chart Data
    const WeeklyRevenueData = useSelector((state) => state?.Dashboard?.weeklyRevenueDetails);
    const WeeklyProductsSoldData = useSelector((state) => state?.Dashboard?.weeklyProductsSold);

    const [statisticsData, setStatissticsData] = useState({
        earnings: `¥${numberWithCommas(0)}`,
        products_sold: `${numberWithCommas(0)}`,
        available_products: `${numberWithCommas(0)}`,
        new_users: `${numberWithCommas(0)}`,
        average_order_value: `¥${numberWithCommas(0)}`
    });

    const [revenueData, setRevenueData] = useState([]);

    const [itemsSell, setItemsSell] = useState([]);

    useEffect(() => {
        if (Object.keys(DashboardData).length > 0) {
            setStatissticsData({
                earnings: `¥${numberWithCommas(DashboardData?.totalEarning)}`,
                products_sold: `${numberWithCommas(DashboardData?.productSold)}`,
                available_products: `${numberWithCommas(DashboardData?.product)}`,
                new_users: `${numberWithCommas(DashboardData?.users)}`,
                average_order_value: `¥${numberWithCommas(DashboardData?.averageOrderValue)}`
            });
        }

        const revenueArray = [];
        const itemsSoldArray = [];

        for (let i = 0; i < PieChartData.length; i++) {
            revenueArray.push(PieChartData[i].totalSell);
            itemsSoldArray.push(PieChartData[i].totalItemsSell);
        }

        setRevenueData(revenueArray);
        setItemsSell(itemsSoldArray);
    }, [DashboardData]);

    const DashboardStatistics = [
        {
            id: 1,
            icon: MdBarChart,
            name: t("dashboard.total_earnings"),
            value: statisticsData.earnings,
            path: "/sales-management",
            label: t("sales")
        },
        {
            id: 2,
            icon: FaBook,
            name: t("dashboard.total_number_of_products_sold"),
            value: statisticsData.products_sold,
            path: "/sales-management",
            label: t("sales")
        },
        {
            id: 3,
            icon: ImPriceTag,
            name: t("dashboard.available_products"),
            value: statisticsData.available_products,
            path: "/product-management",
            label: t("product")
        },
        {
            id: 4,
            icon: FaUser,
            name: t("dashboard.new_users"),
            value: statisticsData.new_users,
            path: "/user-management",
            label: t("product")
        },
        {
            id: 5,
            icon: ImPriceTag,
            name: t("dashboard.average_order_value"),
            value: statisticsData.average_order_value,
            path: "/sales-management",
            label: t("sales")
        }
    ];

    return (
        <Box w="full">
            <SimpleGrid columns={{ base: 1, md: 2, lg: 5 }} my={4} gap={4}>
                {DashboardStatistics.map((statistics) => (
                    <MiniStatistics
                        startContent={
                            <IconBox
                                w={10}
                                h={10}
                                bg={boxBg}
                                icon={<Icon w={5} h={5} as={statistics.icon} color={brandColor} />}
                            />
                        }
                        name={statistics.name}
                        value={statistics.value}
                        path={statistics.path}
                        label={statistics.label}
                        key={statistics.id}
                    />
                ))}
            </SimpleGrid>

            <Divider my={5} border={"2px"} />

            <Flex gap={5} my={5} flexDir={{ base: "column", lg: "row" }}>
                <Box flex={1}>
                    <TotalSpent />
                </Box>
                <Flex flex={1} gap={4}>
                    <PieCard title={t("dashboard.monthly_revenue")} datas={revenueData} />

                    <PieCard title={t("dashboard.monthly_products_sold")} datas={itemsSell} />
                </Flex>
            </Flex>

            <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap="20px" my={5}>
                <DailyTraffic
                    title={t("dashboard.weekly_revenue")}
                    total={`¥${numberWithCommas(WeeklyRevenueData?.total)}`}
                    data={WeeklyRevenueData}
                />
                <DailyTraffic
                    title={t("dashboard.weekly_products_sold")}
                    total={`${numberWithCommas(WeeklyProductsSoldData?.total)}`}
                    data={WeeklyProductsSoldData}
                />
            </SimpleGrid>
        </Box>
    );
}
