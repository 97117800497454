import { useEffect, useState, lazy, Suspense } from "react";
import {
    Box,
    Container,
    Stack,
    Image,
    Flex,
    Heading,
    Divider,
    Icon,
    Input,
    Grid,
    GridItem,
    useToast,
    useDisclosure,
    Skeleton
} from "@chakra-ui/react";

import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../store";

import CategoryService from "../../../services/CategoryService";
import GroupService from "../../../services/GroupService";

import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { globalStyles } from "../../../theme/styles";

import * as yup from "yup";
import { useFormik } from "formik";

// icons

import { FaPen, FaPlus, FaPlusCircle, FaTrash } from "react-icons/fa";

const ReturnButton = lazy(() => import("../../../components/fields/ReturnButton"));
const ProductTestimonials = lazy(() => import("./components/ProductTestimonials"));
const ProductForm = lazy(() => import("./components/ProductForm"));
const ProductInformation = lazy(() => import("./components/ProductInformation"));
const ProductDescription = lazy(() => import("./components/ProductDescription"));
const ProductBlog = lazy(() => import("./components/ProductBlog"));
const ProductCoverPhoto = lazy(() => import("./components/ProductCoverPhoto"));

const ImageUpload = lazy(() => import("../../../components/modals/ImageUpload"));

import Loader from "../../../components/scrollbar/Loader";
import ProductService from "../../../services/ProductService";
import dayjs from "dayjs";
import { MdDelete } from "react-icons/md";
import EditImages from "../../../components/modals/EditImages";
import { emptySelectedProduct } from "../../../store/actions/product";
import ProductCarousel from "./components/ProductCarousel";

const ProductEdit = () => {
    // redux
    const dispatch = useDispatch<AppDispatch>();
    const ProductData = useSelector((state: any) => state.Product.selectedProduct);
    const [formStatus, setFormStatus] = useState("create");

    const { t } = useTranslation();
    const navigate = useNavigate();
    const { productId } = useParams();
    const toast = useToast();

    const { isOpen, onOpen, onClose } = useDisclosure();

    const [selectedFile, setSelectedFile] = useState(null);
    const [imageUrl, setImageUrl] = useState<any | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [productStatus, setProductStatus] = useState("DEACTIVATED");

    const [productImages, setProductImages] = useState([]);
    const [selectedImages, setSelectedImages] = useState([]);

    const handleImage = (e: any) => {
        const photo = e.target.files[0];

        setSelectedFile(photo);
        setImageUrl(URL.createObjectURL(photo));
    };

    const [description, setDescription] = useState("");

    const updateCoverImage = (data: any, id: string) => {
        dispatch(
            ProductService.uploadProductImages(
                {
                    data,
                    id
                },
                (responseData: any) => {
                    setProductImages([]);
                    setSelectedImages([]);

                    toast({
                        title: t("product_mgmt.product_saved_successfully"),
                        status: "success",
                        variant: "solid",
                        duration: 2000,
                        position: "top-right",
                        isClosable: true
                    });
                },
                (errorData: any) => {
                    return toast({
                        title: errorData?.message ? errorData?.message : errorData.response?.data?.message,
                        status: "error",
                        variant: "solid",
                        duration: 2000,
                        position: "top-right",
                        isClosable: true
                    });
                }
            )
        );
    };

    const onSubmit = async (values: any, actions: any) => {
        setIsLoading(true);

        const formdata = new FormData();

        if (formStatus === "create" && !imageUrl) {
            setIsLoading(false);

            return toast({
                title: t("product_mgmt.image_is_required"),
                status: "error",
                variant: "solid",
                duration: 2000,
                position: "top-right",
                isClosable: true
            });
        }

        if (selectedFile) {
            formdata.append("cover_photo", selectedFile || "", "[PROXY]");
        }

        formdata.append("name", values.name);
        formdata.append("product_number", values.product_number);
        formdata.append("arraival_date", dayjs(values.arraival_date).format("YYYY/MM/DD"));
        formdata.append("flag", values.flag);
        formdata.append("category", values.category);
        formdata.append("group", values.group);

        if (values.priority > 0) {
            formdata.append("priority", values.priority);
        } else {
            formdata.append("priority", "0");
        }
        if (values.cat_priority > 0) {
            formdata.append("cat_priority", values.cat_priority);
        } else {
            formdata.append("cat_priority", "0");
        }

        if (values.group_priority > 0) {
            formdata.append("group_priority", values.group_priority);
        } else {
            formdata.append("group_priority", "0");
        }

        formdata.append("price", values.price);
        formdata.append("weight", values.weight);
        formdata.append("is_free_delivery", values.is_free_delivery);

        formdata.append("status", productStatus);
        formdata.append("details", JSON.stringify(description));

        const data = formdata;

        if (formStatus === "create") {
            dispatch(
                ProductService.createProduct(
                    {
                        data
                    },
                    (responseData: any) => {
                        actions.resetForm();

                        if (productImages.length === 0) {
                            toast({
                                title: t("product_mgmt.product_saved_successfully"),
                                status: "success",
                                variant: "solid",
                                duration: 2000,
                                position: "top-right",
                                isClosable: true
                            });
                            actions.resetForm();
                            navigate("/product-management");
                            return;
                        }
                        const data = new FormData();

                        for (let i = 0; i < productImages.length; i++) {
                            data.append("product_photos", productImages[i], "[PROXY]");
                        }

                        updateCoverImage(data, responseData?.data?._id);

                        navigate("/product-management");
                    },
                    (errorData: any) => {
                        return toast({
                            title: errorData?.message ? errorData?.message : errorData.response?.data?.message,
                            status: "error",
                            variant: "solid",
                            duration: 2000,
                            position: "top-right",
                            isClosable: true
                        });
                    }
                )
            );
        } else {
            const data = values;
            data.details = JSON.stringify(description);
            data.status = productStatus;

            dispatch(
                ProductService.updateProduct(
                    { id: productId, data },
                    (responseData: any) => {
                        setIsLoading(false);

                        getProductData();
                        setFormStatus("view");
                        toast({
                            title: responseData?.message ? responseData?.message : responseData.response?.data?.message,
                            status: "success",
                            variant: "solid",
                            duration: 2000,
                            position: "top-right",
                            isClosable: true
                        });
                    },
                    (errorData: any) => {
                        setIsLoading(false);

                        toast({
                            title: errorData?.message ? errorData?.message : errorData.response?.data?.message,
                            status: "error",
                            variant: "solid",
                            duration: 2000,
                            position: "top-right",
                            isClosable: true
                        });
                    }
                )
            );
        }
    };

    const productSchema = yup.object().shape({
        name: yup.string().required(t("form_errors.required_fields")),
        price: yup.string().required(t("form_errors.required_fields")),
        product_number: yup.string().required(t("form_errors.required_fields")),
        weight: yup.string().required(t("form_errors.required_fields")),
        arraival_date: yup.string().required(t("form_errors.required_fields"))
    });

    const [isCategoryAdded, setIsCategoryAdded] = useState(false);
    const [isGroupAdded, setIsGroupAdded] = useState(false);

    const { values, errors, touched, isSubmitting, handleBlur, handleChange, handleSubmit, setFieldValue } = useFormik({
        initialValues: {
            name: "",
            product_number: "",
            arraival_date: "",
            flag: "",
            priority: 0,
            category: "",
            cat_priority: 0,
            group: "",
            group_priority: 0,
            price: 0,
            weight: "",
            is_free_delivery: "false"
        },
        validationSchema: productSchema,
        onSubmit
    });

    const handleEditProduct = () => {
        setFormStatus("edit");
        setFieldValue("name", ProductData?.name);

        setFieldValue("product_number", ProductData?.product_number);
        setFieldValue("weight", ProductData.weight);
        setFieldValue("price", ProductData.price);

        setFieldValue("flag", ProductData?.flag);
        setFieldValue("is_free_delivery", String(ProductData?.is_free_delivery));

        if (ProductData?.category) {
            setIsCategoryAdded(true);
            setFieldValue("category", ProductData?.category?._id);
        }
        if (ProductData.group) {
            setIsGroupAdded(true);
            setFieldValue("group", ProductData?.group?._id);
        }

        setFieldValue("arraival_date", dayjs(ProductData?.arraival_date).format("YYYY-MM-DD"));
        setFieldValue("priority", ProductData?.priority);

        setFieldValue("cat_priority", ProductData?.cat_priority);

        setFieldValue("group_priority", ProductData.group_priority);

        setProductStatus(ProductData?.status);

        setFieldValue("status", ProductData.status);
        setImageUrl(ProductData?.cover_photo?.url);

        setDescription(JSON.parse(ProductData?.details));
    };

    const getCategoryAndGroupData = () => {
        dispatch(
            CategoryService.getActiveCategory(
                (responseData: any) => {},
                (errorData: any) => {
                    toast({
                        title: errorData?.message ? errorData?.message : errorData.response?.data?.message,
                        status: "error",
                        variant: "solid",
                        duration: 2000,
                        position: "top-right",
                        isClosable: true
                    });
                }
            )
        );

        dispatch(
            GroupService.getActiveGroup(
                (responseData: any) => {},
                (errorData: any) => {
                    toast({
                        title: errorData?.message ? errorData?.message : errorData.response?.data?.message,
                        status: "error",
                        variant: "solid",
                        duration: 2000,
                        position: "top-right",
                        isClosable: true
                    });
                }
            )
        );

        if (productId != "create") {
            dispatch(
                ProductService.getProductReview(
                    { id: productId },
                    (responseData: any) => {},
                    (errorData: any) => {
                        toast({
                            title: errorData?.message ? errorData?.message : errorData.response?.data?.message,
                            status: "error",
                            variant: "solid",
                            duration: 2000,
                            position: "top-right",
                            isClosable: true
                        });
                    }
                )
            );
        }

        setIsLoading(false);
    };

    const getProductData = () => {
        dispatch(
            ProductService.getProduct(
                {
                    id: productId
                },
                (responseData: any) => {},
                (errorData: any) => {
                    toast({
                        title: errorData?.message ? errorData?.message : errorData.response?.data?.message,
                        status: "error",
                        variant: "solid",
                        duration: 2000,
                        position: "top-right",
                        isClosable: true
                    });
                }
            )
        );
    };

    useEffect(() => {
        if (productId === "create") {
            setFormStatus("create");
        } else {
            setFormStatus("view");
            getProductData();
        }
        getCategoryAndGroupData();

        return () => {
            dispatch(emptySelectedProduct({}));
        };
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const deleteProductImages = (imageId: any) => {
        dispatch(
            ProductService.deleteImage(
                {
                    productId: productId,
                    imageId: imageId
                },
                (responseData: any) => {
                    getProductData();
                },
                (errorData: any) => {
                    toast({
                        title: errorData?.message ? errorData?.message : errorData.response?.data?.message,
                        status: "error",
                        variant: "solid",
                        duration: 2000,
                        position: "top-right",
                        isClosable: true
                    });
                }
            )
        );
    };

    const onSelectFile = (event: any) => {
        const selectedFiles = event.target.files;

        const selectedFilesArray: any = Array.from(selectedFiles);
        setProductImages(selectedFilesArray);

        const imagesArray = selectedFilesArray.map((file: any) => {
            return URL.createObjectURL(file);
        });

        setSelectedImages((previousImages: any) => previousImages.concat(imagesArray));

        // FOR BUG IN CHROME
        event.target.value = "";
    };

    const adjustImages = () => {
        //mixing images
        const arr: any = [];

        for (let i = 0; i < ProductData?.product_photos?.length; i++) {
            let url = ProductData?.product_photos[i]?.url;
            arr.push(url);
        }
        // arr.push(ProductData?.cover_photo?.url);
        return arr;
    };

    //all images
    const img = adjustImages();

    return (
        <Box>
            {isLoading && Object.keys(ProductData).length === 0 ? (
                <Loader />
            ) : (
                <Suspense fallback={<Loader />}>
                    <Container maxW={"7xl"}>
                        <Flex pos={"relative"} mb={5} alignItems={"center"} justifyContent={"center"}>
                            <Box pos={"absolute"} left={0}>
                                <ReturnButton />
                            </Box>

                            <Heading>
                                {formStatus === "create"
                                    ? t("product_mgmt.create_product")
                                    : t("product_mgmt.product_details")}
                            </Heading>
                        </Flex>

                        <Box bgColor={"white"} rounded={"lg"} p={5} mb={5} pos={"relative"}>
                            <form onSubmit={handleSubmit}>
                                <Flex w={"full"} flexDir={{ base: "column", md: "row" }} gap={5}>
                                    <Flex w={"full"} flexDir={"column"}>
                                        {formStatus === "view" ? (
                                            <Box pos={"relative"}>
                                                <Flex
                                                    // h={"xs"}
                                                    justifyContent={"center"}
                                                    borderRadius={"xl"}
                                                    backgroundColor={"blackAlpha.50"}
                                                    alignItems={"center"}
                                                    cursor={"pointer"}
                                                >
                                                    <ProductCarousel
                                                        cover={ProductData?.cover_photo?.url}
                                                        images={img}
                                                    />

                                                    {/* <Image src={ProductData?.cover_photo?.url} h={"full"} /> */}
                                                </Flex>
                                            </Box>
                                        ) : formStatus === "create" ? (
                                            <Box pos={"relative"}>
                                                {/* image  */}
                                                {imageUrl ? (
                                                    <Flex
                                                        h={"xs"}
                                                        justifyContent={"center"}
                                                        borderRadius={"xl"}
                                                        backgroundColor={"blackAlpha.50"}
                                                        alignItems={"center"}
                                                        cursor={"pointer"}
                                                    >
                                                        <Image src={imageUrl} h={"full"} />
                                                    </Flex>
                                                ) : (
                                                    <Flex
                                                        h={"xs"}
                                                        justifyContent={"center"}
                                                        border={"4px"}
                                                        borderRadius={"xl"}
                                                        borderStyle={"dashed"}
                                                        borderColor={"blackAlpha.100"}
                                                        backgroundColor={"blackAlpha.50"}
                                                        alignItems={"center"}
                                                        cursor={"pointer"}
                                                    >
                                                        <Image
                                                            alt={"product"}
                                                            src={"/images/photo-gallery-icon.svg"}
                                                            objectFit={"cover"}
                                                            align={"center"}
                                                            h={"28"}
                                                        />
                                                    </Flex>
                                                )}
                                                <Input
                                                    type="file"
                                                    height="100%"
                                                    width="100%"
                                                    position="absolute"
                                                    top="0"
                                                    left="0"
                                                    opacity="0"
                                                    aria-hidden="true"
                                                    accept="image/*"
                                                    onChange={(e) => {
                                                        handleImage(e);
                                                    }}
                                                />
                                            </Box>
                                        ) : (
                                            <ProductCoverPhoto getProductData={getProductData} />
                                        )}

                                        {formStatus === "create" ? (
                                            <ImageUpload
                                                getProductData={getProductData}
                                                productImages={productImages}
                                                selectedImages={selectedImages}
                                                setProductImages={setProductImages}
                                                setSelectedImages={setSelectedImages}
                                            />
                                        ) : formStatus === "edit" ? (
                                            <Grid templateColumns="repeat(5, 1fr)" gap={3} my={3}>
                                                {ProductData?.product_photos &&
                                                    ProductData?.product_photos?.map((image: any, i: number) => (
                                                        <GridItem
                                                            w="100%"
                                                            key={i}
                                                            bgColor={"blackAlpha.300"}
                                                            position={"relative"}
                                                        >
                                                            <Flex
                                                                justifyContent={"center"}
                                                                alignItems={"center"}
                                                                h={"full"}
                                                            >
                                                                <Image
                                                                    src={image?.url}
                                                                    objectFit={"cover"}
                                                                    align={"center"}
                                                                    h={"16"}
                                                                />
                                                            </Flex>
                                                            <Icon
                                                                as={MdDelete}
                                                                pos={"absolute"}
                                                                top={0}
                                                                right={0}
                                                                cursor={"pointer"}
                                                                onClick={() => {
                                                                    deleteProductImages(image?._id);
                                                                }}
                                                            />
                                                        </GridItem>
                                                    ))}
                                                <Box pos={"relative"}>
                                                    <Flex
                                                        h={"24"}
                                                        justifyContent={"center"}
                                                        cursor={"pointer"}
                                                        alignItems={"center"}
                                                        bgColor={"blackAlpha.300"}
                                                        position={"relative"}
                                                        rounded={"xl"}
                                                        border={"4px"}
                                                        borderRadius={"xl"}
                                                        borderStyle={"dashed"}
                                                        borderColor={"blackAlpha.100"}
                                                        w={"28"}
                                                        onClick={onOpen}
                                                    >
                                                        <Icon as={FaPlusCircle} w={"12"} />
                                                    </Flex>
                                                </Box>
                                                <EditImages
                                                    isOpen={isOpen}
                                                    onClose={onClose}
                                                    getProductData={getProductData}
                                                />
                                            </Grid>
                                        ) : (
                                            <Grid
                                                templateColumns={{ base: "repeat(3, 1fr)", md: "repeat(5, 1fr)" }}
                                                my={3}
                                                gap={3}
                                            >
                                                {ProductData?.product_photos &&
                                                    ProductData?.product_photos?.map((image: any, i: number) => {
                                                        return (
                                                            <GridItem
                                                                w={"full"}
                                                                key={i}
                                                                cursor={"pointer"}
                                                                h={"24"}
                                                                display={"flex"}
                                                                justifyContent={"center"}
                                                                pos={"relative"}
                                                            >
                                                                <Image
                                                                    src={image?.url}
                                                                    objectFit={"cover"}
                                                                    align={"center"}
                                                                    h={"full"}
                                                                />
                                                                {/* <ProductCarousel cover={""} images={img} /> */}
                                                            </GridItem>
                                                        );
                                                    })}
                                            </Grid>
                                        )}
                                    </Flex>

                                    <Flex flexDir={"column"} w={"full"}>
                                        <Stack spacing={{ base: 4, sm: 6 }} direction={"column"}>
                                            <Box>
                                                {formStatus === "view" ? (
                                                    <ProductInformation />
                                                ) : (
                                                    <ProductForm
                                                        values={values}
                                                        handleChange={handleChange}
                                                        handleBlur={handleBlur}
                                                        errors={errors}
                                                        touched={touched}
                                                        setFieldValue={setFieldValue}
                                                        isCategoryAdded={isCategoryAdded}
                                                        isGroupAdded={isGroupAdded}
                                                    />
                                                )}
                                            </Box>
                                        </Stack>
                                    </Flex>
                                </Flex>
                                {formStatus === "view" ? (
                                    <Box>
                                        <Box pos={"absolute"} right={0} top={0}>
                                            <Flex>
                                                {/* Edit Product */}
                                                <Flex
                                                    justifyContent={"center"}
                                                    alignItems={"center"}
                                                    cursor={"pointer"}
                                                    w={"7"}
                                                    h={"7"}
                                                    backgroundColor={"blue"}
                                                    onClick={() => handleEditProduct()}
                                                >
                                                    <Icon as={FaPen} color={"white"} />
                                                </Flex>
                                            </Flex>
                                        </Box>
                                        <ProductDescription />

                                        <Divider borderColor={"gray.200"} my={5} />

                                        <ProductTestimonials />
                                    </Box>
                                ) : (
                                    <Box>
                                        <ProductBlog
                                            description={description}
                                            setDescription={setDescription}
                                            setProductStatus={setProductStatus}
                                            formStatus={formStatus}
                                            setFormStatus={setFormStatus}
                                            productStatus={productStatus}
                                        />
                                    </Box>
                                )}
                            </form>
                        </Box>
                    </Container>
                </Suspense>
            )}
        </Box>
    );
};

export default ProductEdit;
