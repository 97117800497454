import { useState, useEffect } from "react";
import {
    Button,
    Flex,
    FormControl,
    FormLabel,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Switch,
    Text,
    useDisclosure,
    useToast
} from "@chakra-ui/react";

// redux
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../store";
import { setSelectedGroup } from "../../store/actions/group";
import GroupService from "../../services/GroupService";

import * as yup from "yup";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";

import { FaTrashAlt } from "react-icons/fa";
import { globalStyles } from "../../theme/styles";
import SmallInputField from "../fields/SmallInputField";

interface IProps {
    isOpen: boolean;
    onClose: () => void;
    getAllGroups: any;
}

const AddGroup = ({ isOpen, onClose, getAllGroups }: IProps) => {
    // redux
    const groupDetails = useSelector((state: any) => state.Group.selectedGroup);
    const dispatch = useDispatch<AppDispatch>();

    const { t } = useTranslation();
    const toast = useToast();
    const [groupEnabled, setGroupEnabled] = useState(false);

    const groupSchema = yup.object().shape({
        name: yup.string().required(t("form_errors.required_fields"))
    });

    const onSubmit = async (values: any, actions: any) => {
        // create
        if (groupDetails?.create) {
            dispatch(
                GroupService.createGroup(
                    { name: values.name, enabled: groupEnabled },
                    (responseData: any) => {
                        toast({
                            title: t("messages.group_create_success"),
                            status: "success",
                            variant: "solid",
                            duration: 2000,
                            position: "top-right",
                            isClosable: true
                        });
                        getAllGroups();
                        handleModalClose();
                    },
                    (errorData: any) => {
                        toast({
                            title: errorData?.message ? errorData?.message : errorData.response?.data?.message,
                            status: "error",
                            variant: "solid",
                            duration: 2000,
                            position: "top-right",
                            isClosable: true
                        });
                    }
                )
            );
        }
        // edit
        else if (groupDetails?.edit) {
            dispatch(
                GroupService.updateGroup(
                    {
                        data: {
                            name: values.name,
                            enabled: groupEnabled
                        },
                        id: groupDetails?._id
                    },
                    (responseData: any) => {
                        toast({
                            title: t("messages.group_update_success"),
                            status: "success",
                            variant: "solid",
                            duration: 2000,
                            position: "top-right",
                            isClosable: true
                        });
                        getAllGroups();
                        handleModalClose();
                    },
                    (errorData: any) => {
                        toast({
                            title: errorData?.message ? errorData?.message : errorData.response?.data?.message,
                            status: "error",
                            variant: "solid",
                            duration: 2000,
                            position: "top-right",
                            isClosable: true
                        });
                    }
                )
            );
        }

        await new Promise((resolve) => setTimeout(resolve, 1000));
        actions.resetForm();
    };

    const { values, errors, touched, isSubmitting, handleBlur, handleChange, handleSubmit, setFieldValue } = useFormik({
        initialValues: {
            name: ""
        },
        validationSchema: groupSchema,
        onSubmit
    });

    useEffect(() => {
        if (groupDetails?.edit) {
            setFieldValue("name", groupDetails?.name);
            setGroupEnabled(groupDetails?.enabled);
        } else if (groupDetails?.create) {
            setFieldValue("name", "");
            setGroupEnabled(false);
        }
    }, [groupDetails?.edit, groupDetails?.create]);

    const handleSwitch = () => {
        if (groupEnabled) {
            return setGroupEnabled(false);
        }
        setGroupEnabled(true);
    };

    const handleModalClose = () => {
        dispatch(setSelectedGroup({}));
        onClose();
    };

    const handleDeleteGroup = () => {
        dispatch(
            GroupService.deleteGroup(
                { id: groupDetails?._id },
                (responseData: any) => {
                    toast({
                        title: t("messages.group_delete_success"),
                        status: "success",
                        variant: "solid",
                        duration: 2000,
                        position: "top-right",
                        isClosable: true
                    });
                    getAllGroups("", "");
                    handleModalClose();
                },
                (errorData: any) => {
                    toast({
                        title: t("messages.group_delete_reject"),
                        status: "error",
                        variant: "solid",
                        duration: 2000,
                        position: "top-right",
                        isClosable: true
                    });
                }
            )
        );
    };

    const model2 = useDisclosure();
    return (
        <>
            <Modal isOpen={isOpen} onClose={() => handleModalClose()}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>
                        {groupDetails?.edit ? t("group_mgmt.edit_group") : t("group_mgmt.add_group")}
                    </ModalHeader>
                    <ModalCloseButton />
                    <form onSubmit={handleSubmit}>
                        <ModalBody>
                            <SmallInputField
                                label={t("group_mgmt.enter_group")}
                                name={"name"}
                                type={"string"}
                                value={values.name}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                errors={errors.name}
                                touched={touched.name}
                            />

                            <FormControl display="flex" alignItems="center">
                                <FormLabel htmlFor="email-alerts" mb="0" fontSize={"md"} fontWeight={"light"}>
                                    {t("group_mgmt.enable_this_group")}?
                                </FormLabel>
                                <Switch
                                    id="email-alerts"
                                    name="enabled"
                                    isChecked={groupEnabled}
                                    onChange={handleSwitch}
                                />
                            </FormControl>
                        </ModalBody>

                        <ModalFooter>
                            <Flex w={"full"} justifyContent={"space-between"}>
                                {groupDetails?.edit && (
                                    <Flex
                                        bgColor={"red.500"}
                                        w={8}
                                        h={8}
                                        justifyContent={"center"}
                                        alignItems={"center"}
                                        borderRadius={"xl"}
                                        cursor={"pointer"}
                                        onClick={() => {
                                            // handleDeleteGroup();
                                        }}
                                    >
                                        <FaTrashAlt color="white" onClick={model2.onOpen} />
                                    </Flex>
                                )}

                                <Flex>
                                    <Button
                                        bgColor={globalStyles.colors.btn.success}
                                        color={"white"}
                                        mr={3}
                                        type={"submit"}
                                        isLoading={isSubmitting}
                                    >
                                        {t("group_mgmt.save")}
                                    </Button>
                                    <Button
                                        bgColor={globalStyles.colors.btn.blue}
                                        color={"white"}
                                        onClick={() => handleModalClose()}
                                    >
                                        {t("group_mgmt.cancel")}
                                    </Button>
                                </Flex>
                            </Flex>
                        </ModalFooter>
                    </form>
                </ModalContent>
            </Modal>

            {/* modal for delete  */}
            <Modal isOpen={model2.isOpen} onClose={model2.onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{t("group_mgmt.delete_group")}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        {t("messages.group_delete_confirm")} <br />
                        {t("messages.this_action_is_irreversible")}
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            colorScheme="red"
                            onClick={() => {
                                handleDeleteGroup();
                            }}
                            mr={3}
                        >
                            {t("group_mgmt.confirm")}
                        </Button>
                        <Button variant="solid" colorScheme={"blue"} onClick={model2.onClose}>
                            {t("group_mgmt.cancel")}
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};

export default AddGroup;
