import React, { useCallback, useEffect, useMemo, useState } from "react";

import {
    Badge,
    Box,
    Button,
    Flex,
    Grid,
    GridItem,
    HStack,
    Input,
    Select,
    Stat,
    StatLabel,
    StatNumber,
    Text,
    useToast
} from "@chakra-ui/react";

// redux
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../store";

import SalesService from "../../../services/SalesService";

import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import dayjs from "dayjs";
import { globalStyles } from "../../../theme/styles";

// components
import DataTableComponent from "../../../components/dataTable/DataTable";
import { ScrollToTop } from "../../../components/scroll/ScrollToTop";
import DatePicker from "react-datepicker";
import LoaderComponent from "../../../components/LoaderComponent";

import config from "../../../utils/config";

// css
import "react-datepicker/dist/react-datepicker.css";
import "./sales.scss";
import ExportBtn from "../../../components/button/ExportBtn";
import { numberWithCommas } from "../../../utils/helperFunctions";

const Sales = () => {
    const disaptch = useDispatch<AppDispatch>();
    const salesTableData = useSelector((state: any) => state?.Sales?.allSales);

    const toast = useToast();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const today = new Date();

    const [totalAmount, setTotalAmount] = useState(0);
    const [totalSalesProducts, setTotalSalesProducts] = useState(0);
    const [cancelledOrder, setCancelledOrder] = useState(0);

    const [startDate, setStartDate] = useState(new Date(today.getFullYear(), today.getMonth(), 1));
    const [endDate, setEndDate] = useState(today);

    // loader
    const [isLoading, setIsLoading] = useState(false);
    const [exportData, setExportData] = useState([]);

    const emptySearchForm = {
        name: "",
        orderId: "",
        status: ""
    };

    const [searchForm, setSearchForm] = useState(emptySearchForm);
    const handleViewUser = (id: string) => {
        navigate(`/user-management/${id}`);
    };

    const handleViewOrder = (row: any) => {
        navigate(`/sales-management/${row?._id}`);
    };

    //get sales by date
    const getSalesByDate = (startDate: any, endDate: any) => {
        setIsLoading(true);
        disaptch(
            SalesService.getSalesByDate(
                { startDate, endDate },
                (responseData: any) => {
                    if (responseData?.status === 200) {
                        setTotalAmount(responseData?.data?.total_sale);
                        setTotalSalesProducts(responseData?.data?.total_sold_product);
                        setCancelledOrder(responseData?.data?.total_cancel_product);
                    } else {
                        setTotalAmount(0);
                        setTotalSalesProducts(0);
                    }

                    setIsLoading(false);
                },
                (errorData: any) => {
                    toast({
                        title: errorData?.message ? errorData?.message : errorData.response?.data?.message,
                        status: "error",
                        variant: "solid",
                        duration: 2000,
                        position: "top-right",
                        isClosable: true
                    });
                }
            )
        );
    };

    const [tableColumn, setTableColumn] = useState<any[]>([
        {
            id: 1,
            name: t("sales_mgmt.order_id").toUpperCase(),
            omit: false,
            cell: (row: any) => (
                <Text
                    as={"span"}
                    cursor={"pointer"}
                    _hover={{ textDecoration: "underline" }}
                    onClick={() => handleViewOrder(row)}
                    color={"red"}
                    fontWeight={"normal"}
                >
                    #{row._id?.substring(row._id.length - 5)}
                </Text>
            )
        },
        {
            id: 2,
            name: t("sales_mgmt.user_name").toUpperCase(),
            sortable: true,
            wrap: true,
            omit: false,
            cell: (row: any) => {
                let fullName = row.user.name;
                if (row.user.last_name || row.user.first_name) {
                    fullName = row.user.last_name + " " + row.user.first_name;
                }
                return (
                    <Text
                        as="span"
                        cursor={"pointer"}
                        _hover={{ textDecoration: "underline" }}
                        onClick={() => {
                            handleViewUser(row?.user?._id);
                        }}
                        fontWeight={"normal"}
                    >
                        {fullName}
                    </Text>
                );
            }
        },
        {
            id: 3,
            name: t("sales_mgmt.order_date").toUpperCase(),
            selector: (row: any) => dayjs(row.createdAt).format("YYYY/MM/DD HH:mm"),
            sortable: true,
            wrap: true,
            omit: false
        },

        {
            id: 4,
            name: t("sales_mgmt.amount"),
            selector: (row: any) => "¥" + numberWithCommas(Number(row?.total_amount)),
            sortable: true,
            omit: false
        },
        {
            id: 5,
            name: t("sales_mgmt.status").toUpperCase(),

            selector: (row: any) => row.status,
            sortable: true,
            cell: (row: any) => (
                <Badge
                    variant="solid"
                    bgColor={
                        row.status === "DELIVERED"
                            ? "green"
                            : row.status === "CANCEL"
                            ? "red"
                            : row.status === "PAYMENT-PENDING"
                            ? "orange"
                            : globalStyles.colors.btn.blue
                    }
                >
                    <Text as="span"> {t(`status.${row.status}`)}</Text>
                </Badge>
            ),
            omit: false
        }
    ]);

    const onChange = (dates: any) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    };

    // export

    const handleChange = ({ selectedRows }: { selectedRows: any }) => {
        setExportData(selectedRows);
    };

    // filter

    const filteredSales = useMemo(() => {
        let filterData = salesTableData.length > 0 ? [...salesTableData] : [];

        if (searchForm.orderId?.trim()) {
            filterData = filterData.filter((user) => user._id?.toString().includes(searchForm.orderId));
        }

        if (searchForm.name?.trim()) {
            const lowerName = searchForm.name?.toLowerCase();
            filterData = filterData.filter((row) => {
                if (row.user.last_name || row.user.first_name)
                    return (
                        String(row.user.last_name + " " + row.user.first_name)
                            .toLowerCase()
                            .includes(lowerName) ||
                        String(row.user.last_name + row.user.first_name)
                            .toLowerCase()
                            .includes(lowerName) ||
                        String(row.user.first_name + " " + row.user.last_name)
                            .toLowerCase()
                            .includes(lowerName) ||
                        String(row.user.first_name + row.user.last_name)
                            .toLowerCase()
                            .includes(lowerName)
                    );

                if (row.name) return String(row.user.name).toLowerCase().includes(lowerName);
            });
        }

        if (searchForm.status) {
            filterData = filterData.filter((user) => user.status.includes(searchForm.status));
        }

        return filterData;
    }, [salesTableData, searchForm]);

    const handlerSearchValue = useCallback((event: any, keyName: any) => {
        const value = event.target.value;

        setSearchForm((prev) => {
            return { ...prev, [keyName]: value };
        });
    }, []);

    useEffect(() => {
        ScrollToTop();
        getSalesByDate(dayjs(startDate).format("YYYY/MM/DD"), dayjs(endDate).format("YYYY/MM/DD"));
    }, []);

    //excel

    const getExcelData = async () => {
        const orderId = exportData.map((data: any) => data?._id);

        const excelData: any = await new Promise((resolve, reject) => {
            disaptch(
                SalesService.getExcelData(
                    { orderId },
                    (responseData: any) => {
                        resolve(responseData.data.rows);
                    },
                    (errorData: any) => {
                        toast({
                            title: errorData?.message ? errorData?.message : errorData.response?.data?.message,
                            status: "error",
                            variant: "solid",
                            duration: 2000,
                            position: "top-right",
                            isClosable: true
                        });
                        reject();
                    }
                )
            );
        });

        return excelData;
    };

    return (
        <Box w={"full"}>
            {isLoading ? (
                <LoaderComponent />
            ) : (
                <Box w="full">
                    <Box px={{ base: 2, md: 0 }}>
                        {/* heading  */}
                        <Text fontSize={"2xl"} fontWeight={"bold"} lineHeight="full" textAlign={"left"}>
                            {t("sales_mgmt.sales_management")}
                        </Text>

                        {/* date picking  */}

                        <Flex
                            w="full"
                            alignItems={{ base: "baseline", md: "center" }}
                            justify="space-between"
                            direction={{ base: "column-reverse", sm: "row" }}
                            mt={6}
                            gap={2}
                        >
                            <Flex
                                alignItems={{ base: "flex-start", md: "center" }}
                                direction={{ base: "column", md: "row" }}
                                justifyContent={{ base: "flex-start" }}
                                gap={4}
                            >
                                <Text fontSize="md" fontWeight="medium">
                                    {t("common.select_date_range")}
                                </Text>
                                <DatePicker
                                    dateFormat="yyyy/MM/dd"
                                    selected={startDate}
                                    placeholderText={t("common.select_date_range")}
                                    onChange={onChange}
                                    startDate={startDate}
                                    endDate={endDate}
                                    selectsRange
                                    className="custom"
                                    popperClassName="popper-class"
                                    popperPlacement="bottom-start"
                                    todayButton="Today"
                                    showPopperArrow={false}
                                    maxDate={new Date()}
                                    popperModifiers={[
                                        {
                                            name: "offset",
                                            options: {
                                                offset: [0, 0]
                                            }
                                        },
                                        {
                                            name: "preventOverflow",
                                            options: {
                                                rootBoundary: "viewport",
                                                tether: false,
                                                altAxis: true
                                            }
                                        }
                                    ]}
                                />
                                <Button
                                    bgColor={globalStyles.colors.btn.blue}
                                    onClick={() => {
                                        let EndDate = endDate;
                                        if (!endDate) {
                                            EndDate = startDate;
                                        }
                                        getSalesByDate(
                                            dayjs(startDate).format("YYYY/MM/DD"),
                                            dayjs(EndDate).format("YYYY/MM/DD")
                                        );
                                    }}
                                    color={"white"}
                                >
                                    {t("common.show_data")}
                                </Button>
                            </Flex>

                            <ExportBtn exportData={exportData} fileName={t("sales")} getExcelData={getExcelData} />
                        </Flex>
                    </Box>

                    <HStack align="center" py={5} w="full">
                        <Grid
                            templateColumns={{
                                base: "repeat(1, 1fr)",
                                md: "repeat(3, 1fr)",
                                lg: "repeat(4, 1fr)"
                            }}
                            width="full"
                            gap={6}
                        >
                            <GridItem
                                w="full"
                                border="1px solid"
                                borderRadius="lg"
                                px={{ base: 2, md: 4 }}
                                pt={2}
                                shadow="sm"
                            >
                                <Flex flexDirection={"column"}>
                                    <Stat>
                                        <StatLabel>{t("sales_mgmt.total_sales")}</StatLabel>
                                        <StatNumber pb={3}>¥{numberWithCommas(totalAmount)}</StatNumber>
                                    </Stat>
                                </Flex>
                            </GridItem>

                            <GridItem
                                w="full"
                                border="1px solid"
                                borderRadius="lg"
                                px={{ base: 2, md: 4 }}
                                pt={2}
                                shadow="sm"
                            >
                                <Flex flexDirection={"column"}>
                                    <Stat>
                                        <StatLabel>{t("sales_mgmt.sold_products")}</StatLabel>
                                        <StatNumber pb={3}>{numberWithCommas(totalSalesProducts)}</StatNumber>
                                    </Stat>
                                </Flex>
                            </GridItem>

                            <GridItem
                                w="full"
                                border="1px solid"
                                borderRadius="lg"
                                px={{ base: 2, md: 4 }}
                                pt={2}
                                shadow="sm"
                            >
                                <Flex flexDirection={"column"}>
                                    <Stat>
                                        <StatLabel>{t("sales_mgmt.cancelled_order")}</StatLabel>
                                        <StatNumber pb={3}>{numberWithCommas(cancelledOrder)}</StatNumber>
                                    </Stat>
                                </Flex>
                            </GridItem>
                        </Grid>
                    </HStack>

                    {/* filters  */}

                    <Grid
                        templateColumns={{
                            base: "repeat(1, 1fr)",
                            md: "repeat(3, 1fr)",
                            lg: "repeat(4, 1fr)"
                        }}
                        templateRows={{
                            base: "repeat(4, 1fr)",
                            md: "repeat(2, 1fr)",
                            lg: "repeat(1, 1fr)"
                        }}
                        mt={3}
                        mb={2}
                        p={3}
                        justifyContent={{
                            base: "center"
                        }}
                        gap={3}
                        alignItems="center"
                        bg={"white"}
                        borderRadius="lg"
                        shadow="sm"
                    >
                        <GridItem w="full">
                            <Text pr={{ base: "unset", md: "2" }} fontSize="md" fontWeight="medium" flex={2}>
                                {t("common.filter_data")} :
                            </Text>
                        </GridItem>

                        <GridItem w="full">
                            <Input
                                type={"text"}
                                name="orderId"
                                value={searchForm.orderId}
                                onChange={(e) => {
                                    handlerSearchValue(e, "orderId");
                                }}
                                placeholder={t("sales_mgmt.enter_order_id")}
                                variant="main"
                            />
                        </GridItem>
                        <GridItem w="full">
                            <Input
                                type={"text"}
                                name="name"
                                value={searchForm.name}
                                onChange={(e) => {
                                    handlerSearchValue(e, "name");
                                }}
                                placeholder={t("sales_mgmt.enter_user_name")}
                                variant="main"
                            />
                        </GridItem>
                        <GridItem w="full">
                            <Select
                                name="status"
                                value={searchForm.status}
                                onChange={(e) => {
                                    handlerSearchValue(e, "status");
                                }}
                                variant="main"
                            >
                                {config.ORDER_STATUS.map((order: any) => {
                                    return (
                                        <option value={order.value} key={order.value}>
                                            {t(`status.${order.label}`)}
                                        </option>
                                    );
                                })}
                            </Select>
                        </GridItem>
                    </Grid>

                    <Box>
                        <DataTableComponent
                            column={tableColumn}
                            data={filteredSales}
                            handleChange={handleChange}
                            selectableRows={true}
                        />
                    </Box>
                </Box>
            )}
        </Box>
    );
};

export default Sales;
