//react imports
import { BrowserRouter, Routes, Route } from "react-router-dom";

//layout
import ProtectedLayout from "./layouts/ProtectedLayout";

//pages
import Home from "./pages/PublicPages/Home";
import Login from "./pages/Authentication/Login";

// user
import Users from "./pages/ProtectedPages/Users/Users";
import UserInformation from "./pages/ProtectedPages/Users/UserInformation";

import Inquiry from "./pages/ProtectedPages/Inquiry/Inquiry";
import Sales from "./pages/ProtectedPages/Sales/Sales";
import Reviews from "./pages/ProtectedPages/Reviews/Reviews";
import Category from "./pages/ProtectedPages/Category/Category";
import HomeAdmin from "./pages/ProtectedPages/ProtectedPage";
import ChangePassword from "./pages/ProtectedPages/auth/ChangePassword";
import Product from "./pages/ProtectedPages/Product/Product";
import Group from "./pages/ProtectedPages/Group/Group";
import NotFound from "./pages/PublicPages/NotFound";
import OrderInformation from "./pages/ProtectedPages/Sales/OrderInformation";
import InquiryInformation from "./pages/ProtectedPages/Inquiry/InquiryInformation";
import ProductEdit from "./pages/ProtectedPages/Product/ProductEdit";

const App = () => {
    return (
        <BrowserRouter>
            <Routes>
                {/* Authentication Routes */}
                <Route index element={<Home />} />
                <Route path="/login" element={<Login />} />

                {/* protected routes */}
                <Route element={<ProtectedLayout />}>
                    <Route path="/home" element={<HomeAdmin />} />
                    <Route path="/change-password" element={<ChangePassword />} />

                    {/* sales  */}
                    <Route path="/sales-management" element={<Sales />} />
                    <Route path="/sales-management/:orderId" element={<OrderInformation />} />

                    {/* user  */}
                    <Route path="/user-management" element={<Users />} />
                    <Route path="/user-management/:userId" element={<UserInformation />} />
                    {/* <Route path="/add-user" element={<UserForm />} /> */}

                    {/* product  */}
                    <Route path="/product-management" element={<Product />} />

                    <Route path="/product-management/:productId" element={<ProductEdit />} />

                    <Route path="/category-management" element={<Category />} />
                    <Route path="/group-management" element={<Group />} />
                    <Route path="/review-management" element={<Reviews />} />

                    {/* inquiry  */}
                    <Route path="/inquiry-management" element={<Inquiry />} />
                    <Route path="/inquiry-management/:inquiryId" element={<InquiryInformation />} />
                </Route>

                <Route path="*" element={<NotFound />} />
            </Routes>
        </BrowserRouter>
    );
};

export default App;
