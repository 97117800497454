import { Flex, Stat, StatLabel, StatNumber, Text, ScaleFade } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import Card from "./Card";

const Default = ({ startContent, endContent, name, value, path, label }: any) => {
    const navigate = useNavigate();

    const handleRedirection = () => {
        navigate(path);
    };

    return (
        <ScaleFade initialScale={0.9} in={true}>
            <Card py={3}>
                <Flex
                    my="auto"
                    h="full"
                    cursor="pointer"
                    align={"center"}
                    justify={"center"}
                    onClick={() => handleRedirection()}
                >
                    {startContent}

                    <Stat my="auto" ms={startContent ? 5 : 0}>
                        <StatLabel
                            lineHeight="full"
                            color={"secondaryGray.600"}
                            fontSize={{
                                base: "sm"
                            }}
                        >
                            {name}
                        </StatLabel>
                        <StatNumber
                            color={"secondaryGray.900"}
                            fontSize={{
                                base: "2xl"
                            }}
                        >
                            {value}
                        </StatNumber>
                    </Stat>
                    <Flex ms="auto" w="max-content">
                        {endContent}
                    </Flex>
                </Flex>
            </Card>
        </ScaleFade>
    );
};

export default Default;
