import React, { useCallback, useEffect, useMemo, useState } from "react";

import {
    Box,
    Button,
    Flex,
    FormControl,
    Grid,
    GridItem,
    HStack,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Switch,
    Text,
    Textarea,
    Tooltip,
    useDisclosure,
    useToast,
    VStack
} from "@chakra-ui/react";

//redux
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../store";

import ReviewService from "../../../services/ReviewService";

import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import dayjs from "dayjs";
import DatePicker from "react-datepicker";

// icons
import { AiOutlineEdit } from "react-icons/ai";
import { CgExport } from "react-icons/cg";

// components
import DataTableComponent from "../../../components/dataTable/DataTable";
import ReviewDone from "../../../components/modals/ReviewDone";
import { ScrollToTop } from "../../../components/scroll/ScrollToTop";
import LatestReviews from "./Components/LatestReviews";
import Ratings from "../Product/components/Ratings";
import { globalStyles } from "../../../theme/styles";

import AddReply from "../../../components/modals/AddReply";
import LoaderComponent from "../../../components/LoaderComponent";
import ExportBtn from "../../../components/button/ExportBtn";
import { setSelectedReview } from "../../../store/actions/review";
import { MdDoneOutline, MdOutlineDelete } from "react-icons/md";
import { FaTrash } from "react-icons/fa";
import DeleteReview from "../../../components/modals/DeleteReview";
import EnableReview from "../../../components/modals/EnableReview";

const Reviews = () => {
    const disaptch = useDispatch<AppDispatch>();
    const reviewTableData = useSelector((state: any) => state?.Review?.allReviews);

    const toast = useToast();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const today = new Date();

    const [startDate, setStartDate] = useState(new Date(today.getFullYear(), today.getMonth(), 1));
    const [endDate, setEndDate] = useState(today);

    // loader
    const [isLoading, setIsLoading] = useState(false);
    const [exportData, setExportData] = useState([]);

    const [reply, setReply] = useState<string>("");
    const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
    const [confirmReview, setConfirmReview] = useState<boolean>(false);

    //review
    const [review, setReview] = useState<any>({});
    useEffect(() => {
        if (confirmReview) {
            toast({
                title: "Review submitted successfully",
                status: "info",
                position: "top-right",
                duration: 1500,
                isClosable: true
            });
        }
    }, [confirmReview]);

    const onChange = (dates: any) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    };

    const { isOpen: isEditOpen, onOpen: onEditOpen, onClose: onEditClose } = useDisclosure();
    const { isOpen: isDeleteOpen, onOpen: onDeleteOpen, onClose: onDeleteClose } = useDisclosure();
    const { isOpen: isEnableReviewOpen, onOpen: onEnableReviewOpen, onClose: onEnableReviewClsoe } = useDisclosure();

    const handleReply = (row: any) => {
        disaptch(setSelectedReview(row));
        onEditOpen();
    };

    const handleDelete = (row: any) => {
        disaptch(setSelectedReview(row));
        onDeleteOpen();
    };

    const handleViewProduct = (id: any) => {
        navigate(`/product-management/${id}`);
    };

    const handleViewUser = (id: any) => {
        navigate(`/user-management/${id}`);
    };

    //review using date
    const getReviewsByDate = (startDate: any, endDate: any) => {
        setIsLoading(true);
        disaptch(
            ReviewService.getReviewsByDate(
                { startDate, endDate },
                (responseData: any) => {
                    setIsLoading(false);
                },
                (errorData: any) => {
                    toast({
                        title: errorData?.message ? errorData?.message : errorData.response?.data?.message,
                        status: "error",
                        variant: "solid",
                        duration: 2000,
                        position: "top-right",
                        isClosable: true
                    });
                }
            )
        );
    };

    useEffect(() => {
        ScrollToTop();
        getReviewsByDate(dayjs(startDate).format("YYYY/MM/DD"), dayjs(endDate).format("YYYY/MM/DD"));
    }, []);

    const [tableColumn, setTableColumn] = useState<any[]>([
        {
            id: 1,
            name: t("reviews.products"),
            sortable: true,
            omit: false,
            cell: (row: any) => (
                <Text
                    as={"span"}
                    cursor={"pointer"}
                    _hover={{ textDecoration: "underline" }}
                    onClick={() => handleViewProduct(row?.product?._id)}
                    fontWeight={"normal"}
                >
                    {row.product.name}
                </Text>
            )
        },
        {
            id: 2,
            name: t("reviews.user_name"),
            sortable: true,
            omit: false,
            cell: (row: any) => (
                <Text
                    as={"span"}
                    cursor={"pointer"}
                    _hover={{ textDecoration: "underline" }}
                    onClick={() => handleViewUser(row?.user?._id)}
                    fontWeight={"normal"}
                >
                    {row.user.name}
                </Text>
            )
        },
        {
            id: 3,
            name: t("reviews.review_date"),
            selector: (row: any) => dayjs(row?.createdAt).format("YYYY/MM/DD HH:mm"),
            sortable: true,
            omit: false
        },
        {
            id: 4,
            name: t("reviews.ratings"),
            selector: (row: any) => row?.review_point,
            sortable: true,
            omit: false
        },
        {
            id: 5,
            name: t("reviews.review"),
            omit: false,
            grow: 1,
            cell: (row: any) => <Box>{row?.content}</Box>
        },

        {
            id: 6,
            name: <Text>{t("reviews.reply_date")}</Text>,
            selector: (row: any) =>
                row?.admin_reply_date ? dayjs(row?.admin_reply_date).format("YYYY/MM/DD HH:mm") : "",
            sortable: true,
            omit: false
        },
        {
            id: 7,
            name: <Text>{t("reviews.reply")}</Text>,
            selector: (row: any) => row.reply,
            sortable: true,
            omit: false,
            cell: (row: any) => <Box>{row?.admin_reply}</Box>
        },
        {
            id: 8,
            name: t("common.action"),
            sortable: true,
            omit: false,
            cell: (row: any) => (
                <HStack spacing={5}>
                    <AiOutlineEdit cursor="pointer" onClick={() => handleReply(row)} />
                    <Box
                        onClick={() => {
                            onEnableReviewOpen();
                            setReview(row);
                        }}
                    >
                        <Switch defaultChecked={row?.enabled} isReadOnly />
                    </Box>

                    <FaTrash cursor="pointer" onClick={() => handleDelete(row)} />
                </HStack>
            )
        }
    ]);

    // search form
    const emptySearchForm = {
        product: "",
        user: ""
    };

    const [searchForm, setSearchForm] = useState(emptySearchForm);

    const filteredReview = useMemo(() => {
        let filterData = reviewTableData.length > 0 ? [...reviewTableData] : [];

        if (searchForm.product?.trim()) {
            const lowerName = searchForm.product.toLowerCase();
            filterData = filterData.filter((review) => review.product.name.toLowerCase().includes(lowerName));
        }

        if (searchForm.user?.trim()) {
            const lowerName = searchForm.user.toLowerCase();
            filterData = filterData.filter((review) => review.user.name.toLowerCase().includes(lowerName));
        }

        return filterData;
    }, [reviewTableData, searchForm]);

    const handlerSearchValue = useCallback((event: any, keyName: any) => {
        const value = event.target.value;

        setSearchForm((prev) => {
            return { ...prev, [keyName]: value };
        });
    }, []);

    // export

    const handleChange = ({ selectedRows }: { selectedRows: any }) => {
        setExportData(selectedRows);
    };

    const updateReview = () => {
        const data = {
            review_id: review?._id,
            enabled: !review?.enabled
        };
        disaptch(
            ReviewService.replyReview(
                { data },
                (responseData: any) => {
                    getReviewsByDate(
                        dayjs(new Date(today.getFullYear(), today.getMonth(), 1)).format("YYYY/MM/DD"),
                        dayjs(today).format("YYYY/MM/DD")
                    );
                    onEnableReviewClsoe();
                },
                (errorData: any) => {
                    toast({
                        title: errorData?.message ? errorData?.message : errorData.response?.data?.message,
                        status: "error",
                        variant: "solid",
                        duration: 2000,
                        position: "top-right",
                        isClosable: true
                    });
                }
            )
        );
    };

    // excel

    const getExcelData = async () => {
        const reviewId = exportData.map((data: any) => data?._id);

        const excelData: any = await new Promise((resolve, reject) => {
            disaptch(
                ReviewService.getExcelData(
                    { reviewId },
                    (responseData: any) => {
                        resolve(responseData.data.rows);
                    },
                    (errorData: any) => {
                        toast({
                            title: errorData?.message ? errorData?.message : errorData.response?.data?.message,
                            status: "error",
                            variant: "solid",
                            duration: 2000,
                            position: "top-right",
                            isClosable: true
                        });
                        reject();
                    }
                )
            );
        });

        return excelData;
    };

    return (
        <>
            <Box>
                {isLoading ? (
                    <LoaderComponent />
                ) : (
                    <>
                        <AddReply isOpen={isEditOpen} onClose={onEditClose} getReviewsByDate={getReviewsByDate} />

                        <DeleteReview
                            isOpen={isDeleteOpen}
                            onClose={onDeleteClose}
                            getReviewsByDate={getReviewsByDate}
                        />

                        <EnableReview
                            isOpen={isEnableReviewOpen}
                            onClose={onEnableReviewClsoe}
                            updateReview={updateReview}
                        />

                        <Box w="full">
                            <Box px={{ base: 2, md: 0 }}>
                                {/* heading  */}
                                <Text fontSize={"2xl"} fontWeight={"bold"} lineHeight="full" textAlign={"left"}>
                                    {t("review")}
                                </Text>

                                {/* date picking  */}
                                <Flex
                                    w="full"
                                    alignItems={{ base: "baseline", md: "center" }}
                                    justify="space-between"
                                    direction={{ base: "column-reverse", sm: "row" }}
                                    mt={6}
                                    gap={2}
                                >
                                    <Flex
                                        alignItems={{ base: "flex-start", md: "center" }}
                                        direction={{ base: "column", md: "row" }}
                                        justifyContent={{ base: "flex-start" }}
                                        gap={4}
                                    >
                                        <Text fontSize="md" fontWeight="medium">
                                            {t("common.select_date_range")}
                                        </Text>
                                        <DatePicker
                                            dateFormat="yyyy/MM/dd"
                                            selected={startDate}
                                            placeholderText={t("common.select_date_range")}
                                            onChange={onChange}
                                            startDate={startDate}
                                            endDate={endDate}
                                            selectsRange
                                            className="custom"
                                            popperClassName="popper-class"
                                            popperPlacement="bottom-start"
                                            todayButton="Today"
                                            showPopperArrow={false}
                                            maxDate={new Date()}
                                            popperModifiers={[
                                                {
                                                    name: "offset",
                                                    options: {
                                                        offset: [0, 0]
                                                    }
                                                },
                                                {
                                                    name: "preventOverflow",
                                                    options: {
                                                        rootBoundary: "viewport",
                                                        tether: false,
                                                        altAxis: true
                                                    }
                                                }
                                            ]}
                                        />
                                        <Button
                                            bgColor={globalStyles.colors.btn.blue}
                                            onClick={() => {
                                                let EndDate = endDate;
                                                if (!endDate) {
                                                    EndDate = startDate;
                                                }
                                                getReviewsByDate(
                                                    dayjs(startDate).format("YYYY/MM/DD"),
                                                    dayjs(EndDate).format("YYYY/MM/DD")
                                                );
                                            }}
                                            color={"white"}
                                        >
                                            {t("common.show_data")}
                                        </Button>
                                    </Flex>

                                    <ExportBtn
                                        exportData={exportData}
                                        fileName={t("review")}
                                        getExcelData={getExcelData}
                                    />
                                </Flex>
                            </Box>

                            {/* filter_data */}

                            <Flex
                                w="full"
                                gap={5}
                                bg={"white"}
                                p={3}
                                borderRadius="lg"
                                shadow="sm"
                                align="center"
                                flexDir={{ base: "column", md: "row" }}
                                my={3}
                            >
                                <Text fontWeight="semibold" color={"gray.600"} w={{ base: "full", md: "32" }}>
                                    {t("common.filter_data")} :
                                </Text>

                                <Input
                                    type={"text"}
                                    name="product"
                                    value={searchForm.product}
                                    onChange={(e) => handlerSearchValue(e, "product")}
                                    placeholder={t("reviews.type_product_name")}
                                    variant="main"
                                    w={{ base: "full", md: "3xs" }}
                                />

                                <Input
                                    type={"text"}
                                    name="user"
                                    value={searchForm.user}
                                    onChange={(e) => handlerSearchValue(e, "user")}
                                    placeholder={t("reviews.type_user_name")}
                                    variant="main"
                                    w={{ base: "full", md: "3xs" }}
                                />
                            </Flex>

                            <Box mt={4}>
                                <DataTableComponent
                                    column={tableColumn}
                                    data={filteredReview}
                                    handleChange={handleChange}
                                    selectableRows={true}
                                />
                            </Box>
                        </Box>
                    </>
                )}
            </Box>
        </>
    );
};

export default Reviews;
