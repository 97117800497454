import {
    API_INVOCATION,
    CREATE_PRODUCT,
    DELETE_IMAGE,
    DELETE_PRODUCT,
    GET_ALL_PRODUCTS,
    GET_PRODUCT,
    GET_PRODUCT_REVIEWS,
    UPDATE_COVER_IMAGE,
    UPDATE_PRODUCT,
    UPLOAD_PRODUCT_IMAGES
} from "../store/actionTypes";
import { DELETE, GET, PATCH, POST, PUT } from "../utils/apiConstant";
import {
    ALL_PRODUCTS,
    PRODUCT,
    PRODUCT_COVER_PHOTO,
    PRODUCT_DELETE_PHOTOS,
    PRODUCT_PHOTOS,
    PRODUCT_REVIEW_URL
} from "../utils/url";

class ProductService {
    // getAllProducts : GET
    getAllProducts(resolve: any, reject: any) {
        const payload = {
            action: GET_ALL_PRODUCTS,
            method: GET,
            apiConfig: {},
            url: ALL_PRODUCTS,
            resolve,
            reject
        };
        return { type: API_INVOCATION, payload };
    }

    // getProduct by ID : GET

    getProduct(_payload: any, resolve: any, reject: any) {
        const payload = {
            action: GET_PRODUCT,
            method: GET,
            apiConfig: {},
            url: PRODUCT + _payload.id,
            resolve,
            reject
        };

        return { type: API_INVOCATION, payload };
    }

    // createProduct  : POST

    createProduct(_payload: any, resolve: any, reject: any) {
        const payload = {
            action: CREATE_PRODUCT,
            method: POST,
            apiConfig: {},
            url: ALL_PRODUCTS,
            resolve,
            reject,
            data: _payload.data
        };

        return { type: API_INVOCATION, payload };
    }

    // Upload other images : POST

    uploadProductImages(_payload: any, resolve: any, reject: any) {
        const payload = {
            action: UPLOAD_PRODUCT_IMAGES,
            method: POST,
            apiConfig: {},
            url: PRODUCT_PHOTOS + "/" + _payload.id,
            resolve,
            reject,
            data: _payload.data
        };

        return { type: API_INVOCATION, payload };
    }

    // deleteProduct(_payload: any, resolve: any, reject: any) {
    //     const payload = {
    //         action: DELETE_PRODUCT,
    //         method: DELETE,
    //         apiConfig: {},
    //         url: "",
    //         resolve,
    //         reject,
    //         data: _payload.data
    //     };

    //     return { type: API_INVOCATION, payload };
    // }

    // Delete Image by Id : DELETE

    deleteImage(_payload: any, resolve: any, reject: any) {
        const payload = {
            action: DELETE_IMAGE,
            method: DELETE,
            apiConfig: {},
            url: `${PRODUCT_DELETE_PHOTOS}/${_payload.productId}/${_payload.imageId}`,
            resolve,
            reject
        };

        return { type: API_INVOCATION, payload };
    }

    // Update Product : PUT

    updateProduct(_payload: any, resolve: any, reject: any) {
        const payload = {
            action: UPDATE_PRODUCT,
            method: PUT,
            apiConfig: {},
            url: `${ALL_PRODUCTS}/${_payload.id}`,
            resolve,
            reject,
            data: _payload.data
        };

        return { type: API_INVOCATION, payload };
    }

    //  Update cover image : PATCH

    updateCoverImage(_payload: any, resolve: any, reject: any) {
        const payload = {
            action: UPDATE_COVER_IMAGE,
            method: PATCH,
            apiConfig: {},
            url: PRODUCT_COVER_PHOTO + "/" + _payload.id,
            resolve,
            reject,
            data: _payload.data
        };

        return { type: API_INVOCATION, payload };
    }

    // Product Review : GET

    getProductReview(_payload: any, resolve: any, reject: any) {
        const payload = {
            action: GET_PRODUCT_REVIEWS,
            method: GET,
            apiConfig: {},
            url: PRODUCT_REVIEW_URL + _payload.id + "&deleted=false&enabled=true",
            resolve,
            reject
        };

        return { type: API_INVOCATION, payload };
    }
}

export default new ProductService();
