export default {
    // USER
    GENDER: ["MALE", "FEMALE", "OTHER"],
    USER_PROFILE_STATUS: [
        {
            label: "all",
            value: ""
        },
        {
            label: "active",
            value: "ACTIVE"
        },
        {
            label: "deleted",
            value: "DELETED"
        },
        {
            label: "deactivated",
            value: "DEACTIVATED"
        }
    ],

    // PRODUCT
    PRODUCT_FLAG: ["NEW", "OLD"],
    PRODUCT_STATUS: [
        {
            label: "all",
            value: ""
        },
        {
            label: "ACTIVE",
            value: "ACTIVE"
        },
        {
            label: "DEACTIVATED",
            value: "DEACTIVATED"
        }
    ],

    CATEGORY_STATUS: [
        {
            label: "ALL",
            value: ""
        },
        {
            label: "active",
            value: "true"
        },
        {
            label: "deactive",
            value: "false"
        }
    ],

    // inquiry

    INQUIRY_STATUS: [
        {
            label: "all",
            value: ""
        },
        {
            label: "INQUIRY_PENDING",
            value: "INQUIRY_PENDING"
        },
        {
            label: "INQUIRY_REJECTED",
            value: "INQUIRY_REJECTED"
        },
        {
            label: "INQUIRY_ACCEPTED",
            value: "INQUIRY_ACCEPTED"
        },
        {
            label: "INQUIRY_CANCELLED",
            value: "INQUIRY_CANCELLED"
        },
        {
            label: "INQUIRY_FINISH",
            value: "INQUIRY_FINISH"
        }
    ],

    INQUIRY_STATUS2: [
        {
            label: "INQUIRY_PENDING",
            value: "INQUIRY_PENDING"
        },
        {
            label: "INQUIRY_REJECTED",
            value: "INQUIRY_REJECTED"
        },
        {
            label: "INQUIRY_ACCEPTED",
            value: "INQUIRY_ACCEPTED"
        },
        {
            label: "INQUIRY_CANCELLED",
            value: "INQUIRY_CANCELLED"
        },
        {
            label: "INQUIRY_FINISH",
            value: "INQUIRY_FINISH"
        }
    ],

    // ORDER
    ORDER_STATUS: [
        {
            label: "all",
            value: ""
        },
        {
            label: "ORDER",
            value: "ORDER"
        },
        {
            label: "PAYMENT-PENDING",
            value: "PAYMENT-PENDING"
        },
        // {
        //     label: "OUT-OF-DELIVERED",
        //     value: "OUT-OF-DELIVERED"
        // },

        {
            label: "DELIVERED",
            value: "DELIVERED"
        },
        {
            label: "CANCEL",
            value: "CANCEL"
        }
    ],
    ORDER_STATUS2: [
        {
            label: "ORDER",
            value: "ORDER"
        },
        {
            label: "DELIVERED",
            value: "DELIVERED"
        },
        {
            label: "CANCEL",
            value: "CANCEL"
        }
    ],
    ORDER_CANCEL_STATUS: ["ORDER"],
    ORDER_PAYMENT_TYPE: ["COD", "LINE"],
    ORDER_CANCEL_BY: ["USER", "ADMIN"],
    ORDER_PAYMENT_STATUS: [
        "INITIATED",
        "PROCESSING",
        "SUCCESS",
        "FAILED",
        "REQUEST_FOR_REFUND",
        "REFUND_SUCCESS",
        "REFUND_FAILED"
    ], // 0 = Initiated, 1 = Success, 2 = Failed, 3 = Request For Refund, 4 = Refund Success, 5 = Refund Faile
    ORDER_CANCEL_PAYMENT_STATUS: ["REQUEST_FOR_REFUND", "REFUND_SUCCESS", "REFUND_FAILED"],

    // REVIEW
    REVIEW_POINTS: [0, 1, 2, 3, 4, 5]
};
