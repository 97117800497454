import { API_INVOCATION, DELETE_REVIEW, GET_ALL_REVIEWS, GET_EXCEL_DATA, REPLY_REVIEW } from "../store/actionTypes";
import { DELETE, GET, POST, PUT } from "../utils/apiConstant";
import { EXCELREVIEW, REPLYREVIEW, REVIEW } from "../utils/url";

class ReviewService {
    getReviewsByDate(_payload: any, resolve: any, reject: any) {
        const payload = {
            action: GET_ALL_REVIEWS,
            method: GET,
            apiConfig: {},
            url: `${REVIEW}?start_date=${_payload.startDate}&end_date=${_payload.endDate}`,
            resolve,
            reject
        };
        return { type: API_INVOCATION, payload };
    }
    replyReview(_payload: any, resolve: any, reject: any) {
        const payload = {
            action: REPLY_REVIEW,
            method: PUT,
            apiConfig: {},
            url: REPLYREVIEW,
            resolve,
            reject,
            data: _payload.data
        };
        return { type: API_INVOCATION, payload };
    }
    deleteReview(_payload: any, resolve: any, reject: any) {
        const payload = {
            action: DELETE_REVIEW,
            method: DELETE,
            apiConfig: {},
            url: REVIEW + "/" + _payload.id,
            resolve,
            reject
        };
        return { type: API_INVOCATION, payload };
    }

    getExcelData(_payload: any, resolve: any, reject: any) {
        const payload = {
            action: GET_EXCEL_DATA,
            method: POST,
            apiConfing: {},
            url: EXCELREVIEW,
            resolve,
            reject,
            data: _payload
        };
        return { type: API_INVOCATION, payload };
    }
}

export default new ReviewService();
