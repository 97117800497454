import {
    API_INVOCATION,
    DELETE_INQUIRY,
    GET_ALL_INQUIRY_DETAILS,
    GET_EXCEL_DATA,
    GET_INQUIRY_DETAILS,
    SELECTED_INQUIRY,
    UPDATE_INQUIRY
} from "../store/actionTypes";
import { DELETE, GET, POST, PUT } from "../utils/apiConstant";
import { ALL_INQUIRY_URL, EXCELINQUIRY } from "../utils/url";

class InquiryService {
    getInquiryByDate(_payload: any, resolve: any, reject: any) {
        const payload = {
            action: GET_ALL_INQUIRY_DETAILS,
            method: GET,
            apiConfig: {},
            url: `${ALL_INQUIRY_URL}?start_date=${_payload.startDate}&end_date=${_payload.endDate}`,
            resolve,
            reject
        };
        return { type: API_INVOCATION, payload };
    }

    getInquiry(_payload: any, resolve: any, reject: any) {
        const payload = {
            action: GET_INQUIRY_DETAILS,
            method: GET,
            apiConfig: {},
            url: `${ALL_INQUIRY_URL}?inquiryId=${_payload.id}`,
            resolve,
            reject
        };
        return { type: API_INVOCATION, payload };
    }

    addInquiryData(_payload: any, resolve: any, reject: any) {
        const payload = {
            action: UPDATE_INQUIRY,
            method: PUT,
            apiConfig: {},
            url: ALL_INQUIRY_URL,
            resolve,
            reject,
            data: _payload.data
        };
        return { type: API_INVOCATION, payload };
    }

    deleteInquiry(_payload: any, resolve: any, reject: any) {
        const payload = {
            action: DELETE_INQUIRY,
            method: DELETE,
            apiConfig: {},
            url: ALL_INQUIRY_URL + "/" + _payload.id,
            resolve,
            reject
        };
        return { type: API_INVOCATION, payload };
    }

    getExcelData(_payload: any, resolve: any, reject: any) {
        const payload = {
            action: GET_EXCEL_DATA,
            method: POST,
            apiConfing: {},
            url: EXCELINQUIRY,
            resolve,
            reject,
            data: _payload
        };
        return { type: API_INVOCATION, payload };
    }
}

export default new InquiryService();
