export const BASE_URL = process.env.REACT_APP_BACKEND;
export const ADMIN_AUTH = BASE_URL + "/admin/auth/login";
export const REFRESH_TOKEN_URL = BASE_URL + "/admin/auth/refresh-token";
export const UPDATEPASSWORD = BASE_URL + "/admin/auth/reset-password";
export const GETPROFILE = BASE_URL + "/admin/profile";

//dashboard
export const DASHBOARD_DETAILS_URL = BASE_URL + "/admin/dashboard";

export const PIE_CHART_URL = BASE_URL + "/admin/dashboard/one-month";

export const WEEKLY_REVENUE_URL = BASE_URL + "/admin/dashboard/weekly-revenue";
export const WEEKLY_PRODUCT_SOLD_URL = BASE_URL + "/admin/dashboard/weekly-product-sell";

export const SIX_MONTH_REVENUE_URL = BASE_URL + "/admin/dashboard/six-month";

//Category
export const CATEGORY = BASE_URL + "/category";

// Group
export const GROUP = BASE_URL + "/group";

//Product
export const ALL_PRODUCTS = BASE_URL + "/product";
export const PRODUCT_COVER_PHOTO = BASE_URL + "/product/cover-photo";
export const PRODUCT_PHOTOS = BASE_URL + "/product/photos";
export const PRODUCT_DELETE_PHOTOS = BASE_URL + "/product/delete";
export const PRODUCT = BASE_URL + "/product?id=";
export const PRODUCT_REVIEW_URL = BASE_URL + "/review/?product=";

// User
export const GET_ALL_USERS = BASE_URL + "/admin/user/get-users";
export const GET_USER_ADDRESS_URL = BASE_URL + "/user/address";
export const DEACTIVATEACCOUNT = BASE_URL + "/admin/user/deactivate";
export const ACTIVATEACCOUNT = BASE_URL + "/admin/user/activate";
export const EXCELUSER = BASE_URL + "/admin/user/get-users/excel";

// Review
export const REVIEW = BASE_URL + "/review";
export const USERREVIEW = BASE_URL + "/review/?user=";
export const REPLYREVIEW = BASE_URL + "/review/reply";
export const EXCELREVIEW = BASE_URL + "/review/excel";

// ADMIN PROFILE
export const ADMIN_PROFILE = BASE_URL + "/admin/profile";

///INQUIRY
export const ALL_INQUIRY = BASE_URL + "/inquiry/store?offset=0&limit=20";
export const CREATE_INQUIRY = BASE_URL + "/inquiry/store";
export const UPDATE_INQUIRY = BASE_URL + "/inquiry/comment";
export const DELETE_INQUIRY = BASE_URL + "/inquiry";
export const EXCELINQUIRY = BASE_URL;

//ORDERS
export const ALL_ORDER = BASE_URL + "/order?limit=10";
export const GET_ORDER = BASE_URL + "/order";
export const COMPLETE_ORDER = BASE_URL + "/order/delivered";

//Sales
export const ALL_SALES_URL = BASE_URL + "/order";
export const ALL_RESERVATION_URL = BASE_URL + "/order";
export const SALES_EXPORT = BASE_URL + "/order/excel";

export const DASHBOARD_URL = BASE_URL + "/adminDashboard/dashboard";

//inquiry
export const ALL_INQUIRY_URL = BASE_URL + "/inquiry";

// Invoice
export const DOWNLOADINVOICE = BASE_URL + "/order/invoice";
