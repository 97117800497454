import { SELECTED_GROUP, GET_ALL_GROUPS_SUCCESS, ACTIVE_GROUP_SUCCESS } from "../actionTypes";

const INITIAL_STATE = {
    allGroup: [],
    selectedGroup: {},
    activeGroup: []
};

const Group = (state = INITIAL_STATE, action: { type: string; payload: any }) => {
    switch (action.type) {
        case GET_ALL_GROUPS_SUCCESS:
            return {
                ...state,
                allGroup: [...action.payload.data.rows]
            };
        case SELECTED_GROUP:
            return {
                ...state,
                selectedGroup: action.payload
            };
        case ACTIVE_GROUP_SUCCESS:
            return {
                ...state,
                activeGroup: action.payload.data.rows
            };
        default:
            return state;
    }
};

export default Group;
