import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

//Chakra UI imports
import {
    Box,
    Flex,
    Text,
    IconButton,
    Button,
    Stack,
    Popover,
    Avatar,
    PopoverTrigger,
    PopoverContent,
    PopoverArrow,
    PopoverBody,
    VStack,
    useToast,
    HStack,
    Radio,
    RadioGroup,
    Select,
    Image
} from "@chakra-ui/react";

// redux
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../store";

// icons
import { FiMenu } from "react-icons/fi";
import { BsTranslate } from "react-icons/bs";
import { BiStoreAlt, BiUser } from "react-icons/bi";
import { FaSignOutAlt, FaExchangeAlt } from "react-icons/fa";
import { ImProfile } from "react-icons/im";
import TokenService from "../../services/TokenService";
import { useState } from "react";

const AuthHeader = ({ open }: any) => {
    const navigate = useNavigate();
    const toast = useToast();
    const { t, i18n } = useTranslation();

    const Email = useSelector((state: any) => state?.Auth?.profile);

    const handleLogout = () => {
        TokenService.removeUser();
        navigate("/login");
        sessionStorage.removeItem("selectedtabadmin");
        toast({
            title: t("messages.logout_success"),
            status: "success",
            position: "top-right",
            duration: 2000,
            isClosable: true
        });
    };

    const handleHome = () => {
        navigate("/home");
    };

    const handleChangePassword = () => {
        navigate("/change-password");
    };

    return (
        <Flex
            as="header"
            align="center"
            justify="space-between"
            w="full"
            px="4"
            position="fixed"
            top={0}
            zIndex={5}
            bg="white"
            _dark={{
                bg: "gray.900"
            }}
            borderBottomWidth="1px"
            color="inherit"
            h="14"
        >
            <IconButton
                aria-label="Menu"
                display={{
                    base: "inline-flex",
                    lg: "none"
                }}
                onClick={open}
                icon={<FiMenu />}
                size="sm"
            />

            <Flex onClick={handleHome} cursor={"pointer"} alignItems={"center"} gap={4}>
                {/* <Image src="/images/logo-lotus.png" boxSize={"150px"} height={"40px"} /> */}
                <Text color={"black"}>{t("heading")}</Text>
            </Flex>

            <Flex align="center">
                <Stack
                    flex={{ base: 1, md: 1 }}
                    spacing={{ base: 2, md: 5 }}
                    justify="flex-end"
                    direction="row"
                    alignItems="center"
                >
                    <Box>
                        <Popover>
                            <PopoverTrigger>
                                <Avatar size="sm" name={Email} cursor="pointer" />
                            </PopoverTrigger>
                            <PopoverContent>
                                <PopoverArrow />
                                <PopoverBody>
                                    <VStack align="flex-start">
                                        <HStack>
                                            <BiUser />
                                            <Text>{Email}</Text>
                                        </HStack>

                                        <HStack>
                                            <FaExchangeAlt />
                                            <Text onClick={handleChangePassword} cursor="pointer">
                                                {t("login.change_password")}
                                            </Text>
                                        </HStack>
                                        <HStack>
                                            <FaSignOutAlt />
                                            <Text onClick={handleLogout} cursor="pointer">
                                                {t("login.logout")}
                                            </Text>
                                        </HStack>
                                    </VStack>
                                </PopoverBody>
                            </PopoverContent>
                        </Popover>
                    </Box>
                </Stack>
            </Flex>
        </Flex>
    );
};

export default AuthHeader;
