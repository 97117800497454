import { Box, Flex, Text, useColorModeValue, VStack } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { adjustPincode } from "../../../../utils/helperFunctions";

interface IProps {
    address: any;
}
const ShippingAddress = ({ address }: IProps) => {
    const { t } = useTranslation();

    return (
        <Box bg={"white"} width="full" rounded="lg" shadow="base" boxShadow={"lg"}>
            <Box p="6">
                <Flex mt="1" justifyContent="space-between" alignContent="center">
                    <Box fontSize="2xl" fontWeight="semibold" as="h4" lineHeight="tight">
                        <Text fontSize="lg" fontWeight="medium">
                            {address?.user_name}
                        </Text>
                        <VStack align="flex-start" spacing={0}>
                            {/* <Text fontSize="sm" fontWeight="medium">
                                {address?.type}
                            </Text> */}
                            <Text fontSize="sm" fontWeight="medium">
                                {`${adjustPincode(address?.zipcode)} , ${address?.state} , ${address?.city} , ${
                                    address?.street_address
                                }`}
                            </Text>
                        </VStack>
                    </Box>
                </Flex>
            </Box>
        </Box>
    );
};

export default ShippingAddress;
