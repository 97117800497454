import { useEffect, useId, useState } from "react";

import { Box, Text, Flex, Image, chakra, Button, Avatar, useToast, Spinner } from "@chakra-ui/react";

// redux
import { useDispatch, useSelector } from "react-redux";

import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

import { FaEnvelope, FaPhone } from "react-icons/fa";
import { AppDispatch } from "../../../../store";
import UserDetailService from "../../../../services/UserDetailService";
import { createPhoneNumber } from "../../../../utils/helperFunctions";

const ViewUser = () => {
    const disaptch = useDispatch<AppDispatch>();

    const { t } = useTranslation();

    const toast = useToast();

    const userDetail = useSelector((state: any) => state?.User?.selectedUserDetail);

    function numberWithCommas(x: any) {
        return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    const fullName = `${userDetail?.last_name && userDetail?.last_name} ${
        userDetail?.first_name && userDetail?.first_name
    }`;

    const getUserDetail = () => {
        disaptch(
            UserDetailService.getUserDetails(
                { id: userDetail._id },
                (responseData: any) => {},
                (errorData: any) => {
                    toast({
                        title: errorData?.message ? errorData?.message : errorData.response?.data?.message,
                        status: "error",
                        variant: "solid",
                        duration: 2000,
                        position: "top-right",
                        isClosable: true
                    });
                }
            )
        );
    };

    const suspendAccount = () => {
        disaptch(
            UserDetailService.deactivateAccount(
                { user_id: userDetail._id },
                (responseData: any) => {
                    getUserDetail();
                    toast({
                        title: t("messages.user_deactivate_success"),
                        status: "error",
                        variant: "solid",
                        duration: 2000,
                        position: "top-right",
                        isClosable: true
                    });
                },
                (errorData: any) => {
                    toast({
                        title: errorData?.message ? errorData?.message : errorData.response?.data?.message,
                        status: "error",
                        variant: "solid",
                        duration: 2000,
                        position: "top-right",
                        isClosable: true
                    });
                }
            )
        );
    };

    const activateAccount = () => {
        disaptch(
            UserDetailService.activateAccount(
                { user_id: userDetail._id },
                (responseData: any) => {
                    getUserDetail();
                    toast({
                        title: t("messages.user_activate_success"),
                        status: "error",
                        variant: "solid",
                        duration: 2000,
                        position: "top-right",
                        isClosable: true
                    });
                },
                (errorData: any) => {
                    toast({
                        title: errorData?.message ? errorData?.message : errorData.response?.data?.message,
                        status: "error",
                        variant: "solid",
                        duration: 2000,
                        position: "top-right",
                        isClosable: true
                    });
                }
            )
        );
    };

    return (
        <Box>
            <Flex w="full" alignItems="center" justifyContent="center" direction={"column"}>
                <Box
                    w="full"
                    py={4}
                    px={8}
                    bg="white"
                    _dark={{
                        bg: "gray.800"
                    }}
                    shadow="lg"
                    rounded="lg"
                >
                    <Flex w={"full"}>
                        <Flex
                            direction={"column"}
                            alignItems={"center"}
                            mt={4}
                            borderRight={"1px"}
                            px={5}
                            flex={1}
                            gap={3}
                        >
                            {userDetail?.profile?.url ? (
                                <Avatar size={"2xl"} src={userDetail?.profile?.url} />
                            ) : userDetail?.last_name === undefined ? (
                                <Avatar size={"2xl"} name={userDetail.name} />
                            ) : (
                                <Avatar size={"2xl"} name={fullName} />
                            )}
                            <Text mt={2} size={"lg"}>
                                #{userDetail?._id}
                            </Text>
                            {userDetail?.email && (
                                <Flex alignItems={"center"} gap={2}>
                                    <FaEnvelope />
                                    <Text flex={1}>{userDetail?.email}</Text>
                                </Flex>
                            )}

                            {userDetail?.phone_number && (
                                <Flex alignItems={"center"} gap={2}>
                                    <FaPhone />
                                    <Text flex={1}>{createPhoneNumber(userDetail?.phone_number)}</Text>
                                </Flex>
                            )}
                        </Flex>

                        <Box px={4} pt={4} flex={2}>
                            <chakra.h2
                                color="gray.800"
                                fontSize={{
                                    base: "2xl",
                                    md: "5xl"
                                }}
                                mt={{
                                    base: 2,
                                    md: 0
                                }}
                                fontWeight="bold"
                                as="div"
                            >
                                {userDetail?.last_name === undefined ? userDetail.name : fullName}
                            </chakra.h2>
                            <Flex flexDir={"column"} gap={2} mt={3} ml={4}>
                                {userDetail.date_of_birth && (
                                    <Flex>
                                        <Text flex={1}>{t("user_mgmt.date_of_birth")}</Text>
                                        <Text flex={1}>{dayjs(userDetail.date_of_birth).format("YYYY/MM/DD")}</Text>
                                    </Flex>
                                )}
                                {userDetail.gender && (
                                    <Flex>
                                        <Text flex={1}>{t("user_mgmt.gender")}</Text>
                                        <Text flex={1}>{userDetail.gender}</Text>
                                    </Flex>
                                )}

                                {userDetail.last_login && (
                                    <Flex>
                                        <Text flex={1}>{t("user_mgmt.last_login")}</Text>
                                        <Text flex={1}>{dayjs(userDetail?.last_login).format("YYYY/MM/DD HH:mm")}</Text>
                                    </Flex>
                                )}
                                {userDetail.status && (
                                    <Flex>
                                        <Text flex={1}>{t("user_mgmt.account_status")}</Text>
                                        <Text flex={1}>{t(`status.${userDetail?.status}`)}</Text>
                                    </Flex>
                                )}

                                <Flex>
                                    <Text flex={1}>{t("user_mgmt.line_id")}</Text>
                                    <Text flex={1}>{userDetail.line_id}</Text>
                                </Flex>

                                <Flex>
                                    <Text flex={1}>{t("user_mgmt.life_time_purchase_amount")}</Text>
                                    <Text flex={1}>
                                        &#165;{numberWithCommas(Number(userDetail?.totalPurchaseAmount))}
                                    </Text>
                                </Flex>

                                <Flex>
                                    <Text flex={1}>{t("user_mgmt.life_time_purchase_products")}</Text>
                                    <Text flex={1}>{numberWithCommas(Number(userDetail?.totalItemPurchase))}</Text>
                                </Flex>
                                <Box mt={7}>
                                    {userDetail?.status === "DELETED" ? (
                                        <Button colorScheme={"green"} onClick={activateAccount}>
                                            {t("user_mgmt.activate_Account")}
                                        </Button>
                                    ) : (
                                        <Button colorScheme={"red"} onClick={suspendAccount}>
                                            {t("user_mgmt.suspend_account")}
                                        </Button>
                                    )}
                                </Box>
                            </Flex>
                        </Box>
                    </Flex>
                </Box>
            </Flex>
        </Box>
    );
};

export default ViewUser;
