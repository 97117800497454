import { API_INVOCATION, GET_PROFILE, GET_TOKEN_VALIDATION, LOGIN, UPDATE_PASSWORD } from "../store/actionTypes";
import { GET, POST } from "../utils/apiConstant";
import { ADMIN_AUTH, GETPROFILE, REFRESH_TOKEN_URL, UPDATEPASSWORD } from "../utils/url";

class AuthService {
    // login
    auth(_payload: any, resolve: any, reject: any) {
        const payload = {
            action: LOGIN,
            method: POST,
            apiConfig: {
                headers: {
                    "Content-Type": "application/json"
                }
            },
            url: ADMIN_AUTH,
            resolve,
            reject,
            data: _payload.data
        };
        return { type: API_INVOCATION, payload };
    }

    // changePassword
    changePassword(_payload: any, resolve: any, reject: any) {
        const payload = {
            action: UPDATE_PASSWORD,
            method: POST,
            apiConfig: {
                headers: {
                    "Content-Type": "application/json"
                }
            },
            url: UPDATEPASSWORD,
            resolve,
            reject,
            data: _payload
        };
        return { type: API_INVOCATION, payload };
    }

    // refreshToken
    refreshToken(_payload: any, resolve: any, reject: any) {
        const payload = {
            action: GET_TOKEN_VALIDATION,
            method: POST,
            apiConfig: {
                headers: {
                    "Content-Type": "application/json"
                }
            },
            url: REFRESH_TOKEN_URL,
            resolve,
            reject,
            data: _payload
        };
        return { type: API_INVOCATION, payload };
    }

    // get Profile
    getProfile( resolve: any, reject: any) {
        const payload = {
            action: GET_PROFILE,
            method: GET,
            apiConfig: {
                headers: {
                    "Content-Type": "application/json"
                }
            },
            url: GETPROFILE,
            resolve,
            reject,
        };
        return { type: API_INVOCATION, payload };
    }
}

export default new AuthService();
