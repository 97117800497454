import { useEffect, useState } from "react";
import { Flex, Box, useToast } from "@chakra-ui/react";

// redux

import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../store";
import UserDetailService from "../../../services/UserDetailService";

import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ScrollToTop } from "../../../components/scroll/ScrollToTop";

// component
import ReturnButton from "../../../components/fields/ReturnButton";
import LoaderComponent from "../../../components/LoaderComponent";
import UserInformationTabs from "./components/UserInformationTabs";

const UserInformation = () => {
    const disaptch = useDispatch<AppDispatch>();

    const [isLoading, setIsLoading] = useState(false);
    const { userId } = useParams();
    const toast = useToast();

    useEffect(() => {
        ScrollToTop();
    }, []);

    const getUserDetail = () => {
        setIsLoading(true);
        disaptch(
            UserDetailService.getUserDetails(
                { id: userId },
                (responseData: any) => {},
                (errorData: any) => {
                    toast({
                        title: errorData?.message ? errorData?.message : errorData.response?.data?.message,
                        status: "error",
                        variant: "solid",
                        duration: 2000,
                        position: "top-right",
                        isClosable: true
                    });
                }
            )
        );

        //get address details
        disaptch(
            UserDetailService.getUserAddress(
                { id: userId },
                (responseData: any) => {},
                (errorData: any) => {
                    toast({
                        title: errorData?.message ? errorData?.message : errorData.response?.data?.message,
                        status: "error",
                        variant: "solid",
                        duration: 2000,
                        position: "top-right",
                        isClosable: true
                    });
                }
            )
        );

        disaptch(
            UserDetailService.getUserPurchasInformation(
                { id: userId },
                (responseData: any) => {},
                (errorData: any) => {
                    toast({
                        title: errorData?.message ? errorData?.message : errorData.response?.data?.message,
                        status: "error",
                        variant: "solid",
                        duration: 2000,
                        position: "top-right",
                        isClosable: true
                    });
                }
            )
        );

        disaptch(
            UserDetailService.getUserReviewHistory(
                { id: userId },
                (responseData: any) => {
                    setIsLoading(false);
                },
                (errorData: any) => {
                    toast({
                        title: errorData?.message ? errorData?.message : errorData.response?.data?.message,
                        status: "error",
                        variant: "solid",
                        duration: 2000,
                        position: "top-right",
                        isClosable: true
                    });
                }
            )
        );
    };

    useEffect(() => {
        getUserDetail();
    }, []);
    const { t } = useTranslation();
    return (
        <Box>
            {/* store info section */}
            <Box px={{ base: 4, md: 8, xl: 2 }} py={5}>
                <Flex w="full">
                    <ReturnButton />
                </Flex>
                <Box my={5}>{isLoading ? <LoaderComponent /> : <UserInformationTabs />}</Box>
            </Box>
        </Box>
    );
};

export default UserInformation;
