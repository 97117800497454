import { AiOutlineControl, AiOutlineQuestion } from "react-icons/ai";
import { BiCategory } from "react-icons/bi";
import { BsCurrencyYen } from "react-icons/bs";
import { FaCcStripe, FaLine, FaUsers } from "react-icons/fa";
import { ImPriceTag } from "react-icons/im";
import { MdHome, MdOutlineRateReview } from "react-icons/md";
import { SiGoogleanalytics } from "react-icons/si";

//react scripts flag if heap memory is not enough --max_old_space_size=8000
export interface INavItem {
    id: number;
    icon?: any;
    path: string;
    label: string;
    // subLabel?: string;
    // children?: Array<INavItem>;
    // href?: string;
}

export const NAV_ITEMS: Array<INavItem> = [
    {
        id: 1,
        icon: () => <MdHome />,
        path: "/home",
        label: "home"
    },
    {
        id: 2,
        icon: () => <BsCurrencyYen />,
        path: "/sales-management",
        label: "sales"
    },
    {
        id: 3,
        icon: () => <FaUsers />,
        path: "/user-management",
        label: "users"
    },
    {
        id: 4,
        icon: () => <ImPriceTag />,
        path: "/product-management",
        label: "products"
    },
    {
        id: 5,
        icon: () => <BiCategory />,
        path: "/category-management",
        label: "category"
    },
    {
        id: 6,
        icon: () => <AiOutlineControl />,
        path: "/group-management",
        label: "group"
    },
    {
        id: 7,
        icon: () => <MdOutlineRateReview />,
        path: "/review-management",
        label: "review"
    },
    {
        id: 8,
        icon: () => <AiOutlineQuestion />,
        path: "/inquiry-management",
        label: "inquiry"
    },
    {
        id: 9,
        icon: () => <SiGoogleanalytics />,
        path: "/analytics",
        label: "analytics"
    },
    // {
    //     id: 10,
    //     icon: () => <FaCcStripe />,
    //     path: "/",
    //     label: "stripe"
    // },
    {
        id: 11,
        icon: () => <FaLine />,
        path: "/line",
        label: "line"
    }
];
