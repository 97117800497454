import {
    SELECTED_CATEGORY,
    SET_ACTIVE_CATEGORY,
    GET_ALL_CATEGORIES_SUCCESS,
    ACTIVE_CATEGORY_SUCCESS
} from "../actionTypes";

const INITIAL_STATE = {
    allCategory: [],
    selectedCategory: {},
    activeCategory: []
};
const Category = (state = INITIAL_STATE, action: { type: string; payload: any }) => {
    switch (action.type) {
        case GET_ALL_CATEGORIES_SUCCESS:
            return {
                ...state,
                allCategory: [...action.payload.data.rows]
            };
        case SELECTED_CATEGORY:
            return {
                ...state,
                selectedCategory: action.payload
            };
        case ACTIVE_CATEGORY_SUCCESS:
            return {
                ...state,
                activeCategory: action.payload.data.rows
            };
        default:
            return state;
    }
};

export default Category;
