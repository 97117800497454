import React, { useEffect, useState } from "react";
import {
    Box,
    Button,
    Flex,
    Text,
    Textarea,
    chakra,
    Link,
    Image,
    Avatar,
    Badge,
    Container,
    FormControl,
    FormLabel,
    useToast,
    Stack,
    Select
} from "@chakra-ui/react";

import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../store";

import InquireService from "../../../services/InquireService";

import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import dayjs from "dayjs";

import * as yup from "yup";
import { useFormik } from "formik";

import { globalStyles } from "../../../theme/styles";
import LoaderComponent from "../../../components/LoaderComponent";
import config from "../../../utils/config";
import ReturnButton from "../../../components/fields/ReturnButton";

const InquiryInformation = () => {
    const disaptch = useDispatch<AppDispatch>();
    const InquiryDetails = useSelector((state: any) => state?.Inquiry?.selectedInquires);

    const { t } = useTranslation();
    const toast = useToast();

    const navigate = useNavigate();
    const { inquiryId } = useParams();

    const [isLoading, setIsLoading] = useState(false);

    const handleViewUser = (id: any) => {
        navigate(`/user-management/${id}`);
    };

    const getInquiryInformation = () => {
        setIsLoading(true);
        disaptch(
            InquireService.getInquiry(
                { id: inquiryId },
                (responseData: any) => {
                    setFieldValue("memo", responseData.data.rows[0].memo);
                    setFieldValue("status", responseData.data.rows[0].status);
                    setIsLoading(false);
                },
                (errorData: any) => {
                    toast({
                        title: errorData?.message ? errorData?.message : errorData.response?.data?.message,
                        status: "error",
                        variant: "solid",
                        duration: 2000,
                        position: "top-right",
                        isClosable: true
                    });
                }
            )
        );
    };
    useEffect(() => {
        window.scrollTo(0, 0);
        getInquiryInformation();
    }, []);

    const onSubmit = async (values: any, actions: any) => {
        setIsLoading(true);
        disaptch(
            InquireService.addInquiryData(
                { data: values },
                (responseData: any) => {
                    setIsLoading(false);
                    getInquiryInformation();
                    toast({
                        title: responseData?.message ? responseData?.message : responseData.response?.data?.message,
                        status: "success",
                        variant: "solid",
                        duration: 2000,
                        position: "top-right",
                        isClosable: true
                    });
                },
                (errorData: any) => {
                    toast({
                        title: errorData?.message ? errorData?.message : errorData.response?.data?.message,
                        status: "error",
                        variant: "solid",
                        duration: 2000,
                        position: "top-right",
                        isClosable: true
                    });
                }
            )
        );
    };

    const { values, errors, touched, isSubmitting, handleBlur, handleChange, handleSubmit, setFieldValue } = useFormik({
        initialValues: {
            inquiryId: inquiryId,
            status: "",
            memo: ""
        },
        onSubmit
    });

    return (
        <Box>
            {isLoading ? (
                <LoaderComponent />
            ) : (
                <>
                    <Flex pos={"relative"} my={5} alignItems={"center"} justifyContent={"center"}>
                        <Box pos={"absolute"} left={0}>
                            <ReturnButton />
                        </Box>
                    </Flex>
                    <Container maxW={"2xl"} mx={"auto"}>
                        <Flex flexDir={"column"} mt={4} gap={4} w={"full"}>
                            <Box p={4} rounded="lg" shadow="lg" bg="white">
                                <Flex justifyContent="space-between" alignItems="center">
                                    <Box flex={1}>#{InquiryDetails?._id}</Box>

                                    <Badge
                                        variant="solid"
                                        colorScheme={
                                            InquiryDetails.status === "INQUIRY_ACCEPTED"
                                                ? "cyan"
                                                : InquiryDetails.status === "INQUIRY_REJECTED"
                                                ? "red"
                                                : InquiryDetails.status === "INQUIRY_PENDING"
                                                ? "telegram"
                                                : InquiryDetails.status === "INQUIRY_FINISH"
                                                ? "green"
                                                : "yellow"
                                        }
                                    >
                                        {t(`status.${InquiryDetails?.status}`)}
                                    </Badge>
                                </Flex>

                                <Box mt={2}>
                                    <Text fontSize="2xl" color="gray.700" fontWeight="700">
                                        {InquiryDetails?.title}
                                    </Text>
                                    <chakra.p
                                        mt={2}
                                        color="gray.600"
                                        _dark={{
                                            color: "gray.300"
                                        }}
                                    >
                                        {InquiryDetails?.content}
                                    </chakra.p>
                                </Box>

                                <Flex justifyContent="space-between" alignItems="center" mt={4}>
                                    <chakra.span
                                        fontSize="sm"
                                        color="gray.600"
                                        _dark={{
                                            color: "gray.400"
                                        }}
                                    >
                                        {InquiryDetails?.createdAt &&
                                            dayjs(InquiryDetails?.createdAt).format("YYYY/MM/DD HH:mm")}
                                    </chakra.span>

                                    <Flex
                                        alignItems="center"
                                        gap={3}
                                        onClick={() => {
                                            handleViewUser(InquiryDetails?.user);
                                        }}
                                    >
                                        <Avatar
                                            name={InquiryDetails?.name}
                                            cursor={"pointer"}
                                            size="sm"
                                            textAlign={"center"}
                                        />

                                        <Text color="gray.700" fontWeight="700" cursor="pointer">
                                            {InquiryDetails?.name}
                                        </Text>
                                    </Flex>
                                </Flex>
                            </Box>

                            <Box w={"full"} bgColor={"white"} rounded={"lg"} shadow={"lg"} p={4} mb={4}>
                                <Text fontWeight={"semibold"} fontSize={"2xl"}>
                                    {t("product_mgmt.add_additional_details")}
                                </Text>
                                <form onSubmit={handleSubmit}>
                                    <Flex direction={"column"} gap={3} mt={3}>
                                        <FormControl>
                                            <FormLabel>{t("inquiries.select_status")} </FormLabel>

                                            <Select
                                                placeholder={t("inquiries.select_status")}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                name={"status"}
                                                value={values.status}
                                            >
                                                {config.INQUIRY_STATUS2.map((status: any) => {
                                                    return (
                                                        <option value={status.value} key={status.label}>
                                                            {t(`status.${status.label}`)}
                                                        </option>
                                                    );
                                                })}
                                            </Select>

                                            {errors.status && touched.status && (
                                                <Text fontSize={"sm"} mt={1} color={"red.300"}>
                                                    {errors.status}
                                                </Text>
                                            )}
                                        </FormControl>
                                        <FormControl>
                                            <FormLabel>{t("inquiries.add_memo")}</FormLabel>
                                            <Textarea
                                                h={"44"}
                                                border={"2px solid"}
                                                name={"memo"}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.memo}
                                            />
                                        </FormControl>

                                        <Button bgColor={globalStyles.colors.btn.blue} color={"white"} type={"submit"}>
                                            {t("product_mgmt.save")}
                                        </Button>
                                    </Flex>
                                </form>
                            </Box>
                        </Flex>
                    </Container>
                </>
            )}
        </Box>
    );
};

export default InquiryInformation;
