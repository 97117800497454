import { Badge, Box, Flex, Text } from "@chakra-ui/react";

import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { globalStyles } from "../../../../theme/styles";
import { adjustPincode, createPhoneNumber } from "../../../../utils/helperFunctions";

const OrderDetailsTab = () => {
    const { t } = useTranslation();
    const OrderData = useSelector((state: any) => state?.Sales?.selectedSales);

    let fullName = "";

    if (Object.keys(OrderData).length > 0) {
        fullName = OrderData.user.name;
        if (OrderData.user.last_name || OrderData.user.first_name) {
            fullName = OrderData.user.last_name + " " + OrderData.user.first_name;
        }
    }

    return (
        <Box bgColor={"white"} position={"relative"} rounded={"lg"} shadow={"lg"} p={6} w={"full"}>
            <Box position={"absolute"} right={5}>
                <Badge
                    variant="solid"
                    bgColor={
                        OrderData.status === "DELIVERED"
                            ? "green"
                            : OrderData.status === "CANCEL"
                            ? "red"
                            : OrderData.status === "PAYMENT-PENDING"
                            ? "orange"
                            : globalStyles.colors.btn.blue
                    }
                >
                    <Text as="span"> {t(`status.${OrderData.status}`)}</Text>
                </Badge>
            </Box>
            <Flex gap={2}>
                <Text>{t("sales_mgmt.order_number")}:</Text>
                <Text color={"red"}>#{OrderData?._id}</Text>
            </Flex>

            <Flex gap={2} justifyContent={"space-between"} my={5}>
                <Flex flexDir={"column"}>
                    <Text fontWeight={"semibold"}>{t("sales_mgmt.order_date")}</Text>
                    <Text>{dayjs(OrderData?.createdAt).format("YYYY/MM/DD HH:mm")}</Text>
                </Flex>
                <Flex flexDir={"column"}>
                    <Text fontWeight={"semibold"}>{t("sales_mgmt.name")}</Text>
                    <Text>{fullName}</Text>
                </Flex>
                {/* <Flex flexDir={"column"}>
                    <Text fontWeight={"semibold"}>{t("sales_mgmt.email")}</Text>
                    <Text>{OrderData?.user?.email}</Text>
                </Flex> */}
                <Flex flexDir={"column"}>
                    <Text fontWeight={"semibold"}>{t("sales_mgmt.phone_number")}</Text>
                    {OrderData?.user?.phone_number && createPhoneNumber(OrderData?.user?.phone_number)}
                </Flex>
            </Flex>

            <Flex gap={3} justifyContent={"space-between"}>
                <Box p={5} rounded={"xl"} border={"1px"} flex={1}>
                    <Text fontWeight={"semibold"} fontSize={"lg"}>
                        {t("sales_mgmt.delivery_address")}
                    </Text>
                    <Flex flexDirection={"column"} gap={1} mt={3}>
                        <Flex gap={2}>
                            <Text>{t("sales_mgmt.name")}: </Text>
                            <Text>{OrderData?.address?.user_name}</Text>
                        </Flex>

                        {/* <Text>{OrderData?.address?.type}</Text> */}
                        <Text>
                            {`${OrderData?.address?.zipcode && adjustPincode(OrderData?.address?.zipcode)} , ${
                                OrderData?.address?.state
                            } ,  ${OrderData?.address?.city}
                            , ${OrderData?.address?.street_address}`}
                        </Text>
                    </Flex>
                </Box>
                <Box p={5} rounded={"xl"} border={"1px"} flex={1}>
                    <Text fontWeight={"semibold"} fontSize={"lg"}>
                        {t("sales_mgmt.payment_info")}
                    </Text>
                    <Flex flexDirection={"column"} gap={2} mt={3}>
                        <Flex gap={2}>
                            {/* <Text>{t("sales_mgmt.credit_card_number")}: </Text> */}
                            <Text>{t("sales_mgmt.payment_tyoe")}:</Text>
                            <Text> {`${t(`sales_mgmt.${OrderData?.payment_type}`)}`} </Text>
                        </Flex>
                        {/*   <Flex gap={2}>
                            <Text>{t("sales_mgmt.stripe_customer_id")}: </Text>
                            <Text> {paymentData.stripe_id} </Text>
                        </Flex> */}
                    </Flex>
                </Box>
            </Flex>
        </Box>
    );
};

export default OrderDetailsTab;
