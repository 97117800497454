import {
    useColorModeValue,
    Grid,
    GridItem,
    Tabs,
    TabList,
    Tab,
    TabPanel,
    TabPanels,
    useToast,
    Flex
} from "@chakra-ui/react";

// redux
import { useSelector } from "react-redux";

import { globalStyles } from "../../../../theme/styles";
import { useTranslation } from "react-i18next";

// component
import PurchaseCard from "./PurchaseCard";
import ReviewCard from "./ReviewCard";
import ShippingAddress from "./ShippingAddress";
import ViewUser from "./ViewUser";

const UserInformationTabs = () => {
    const { t } = useTranslation();

    const UserDetail = useSelector((state: any) => state?.User?.address);
    const PurchaseInformation = useSelector((state: any) => state?.User?.purchase);
    const ReviewHistory = useSelector((state: any) => state?.User?.reviews);

    const tabs = [
        { name: t("user_mgmt.user_information") },
        { name: t("user_mgmt.shipping_address") },
        { name: t("user_mgmt.purchasing_information") }, 
        { name: t("user_mgmt.user_review_history") }
    ];

    return (
        <Tabs isFitted my={5} variant="enclosed">
            <TabList>
                {tabs.map((tab: any, index: number) => {
                    return (
                        <Tab
                            key={index}
                            fontSize={{ base: "xs", md: "sm", lg: "md" }}
                            _selected={{
                                background: globalStyles.colors.btn.blue,
                                color: useColorModeValue("gray.100", "gray.300")
                            }}
                        >
                            {tab.name}
                        </Tab>
                    );
                })}
            </TabList>

            <TabPanels>
                <TabPanel>
                    <ViewUser />
                </TabPanel>
                <TabPanel>
                    {UserDetail.length > 0 ? (
                        <Grid
                            templateColumns={{
                                base: "repeat(1, 1fr)",
                                md: "repeat(2, 1fr)",
                                lg: "auto-fill, minmax(200px,1fr)"
                            }}
                            gap={4}
                        >
                            {UserDetail.map((address: any) => {
                                return (
                                    <GridItem w="full" key={address._id}>
                                        <ShippingAddress address={address} />
                                    </GridItem>
                                );
                            })}
                        </Grid>
                    ) : (
                        <Flex h={"full"} w={"full"} alignItems={"center"} justifyContent={"center"} mt={6}>
                            {t("user_mgmt.address_is_not_saved")}
                        </Flex>
                    )}
                </TabPanel>
                <TabPanel>
                    {PurchaseInformation.length > 0 ? (
                        <Grid
                            templateColumns={{
                                base: "repeat(1, 1fr)",
                                md: "repeat(2, 1fr)",
                                lg: "auto-fill, minmax(200px,1fr)"
                            }}
                            gap={4}
                            h={"full"}
                        >
                            {PurchaseInformation.map((data: any) => {
                                return (
                                    <GridItem w="full" key={data._id} h={"full"}>
                                        <PurchaseCard data={data} />
                                    </GridItem>
                                );
                            })}
                        </Grid>
                    ) : (
                        <Flex h={"full"} w={"full"} alignItems={"center"} justifyContent={"center"} mt={6}>
                            {t("user_mgmt.no_order_found")}
                        </Flex>
                    )}
                </TabPanel>
                <TabPanel>
                    {ReviewHistory.length > 0 ? (
                        <Grid
                            templateColumns={{
                                base: "repeat(1, 1fr)",
                                md: "repeat(2, 1fr)",
                                lg: "auto-fill, minmax(200px,1fr)"
                            }}
                            gap={4}
                        >
                            {ReviewHistory.map((history: any, i: number) => {
                                return (
                                    <GridItem w="full" key={history._id}>
                                        <ReviewCard reviewTableData={history} />
                                    </GridItem>
                                );
                            })}
                        </Grid>
                    ) : (
                        <Flex h={"full"} w={"full"} alignItems={"center"} justifyContent={"center"} mt={6}>
                            {t("user_mgmt.no_ratings_found")}
                        </Flex>
                    )}
                </TabPanel>
            </TabPanels>
        </Tabs>
    );
};

export default UserInformationTabs;
