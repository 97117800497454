import { useCallback, useEffect, useMemo, useState } from "react";
import { Badge, Box, Button, Flex, Icon, Input, Select, Text, useDisclosure, useToast } from "@chakra-ui/react";

// redux
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../store";
import { setSelectedCategory } from "../../../store/actions/category";
import CategoryService from "../../../services/CategoryService";

import { useTranslation } from "react-i18next";
import { globalStyles } from "../../../theme/styles";

// icons
import { FaPlus } from "react-icons/fa";

// component
import DataTableComponent from "../../../components/dataTable/DataTable";
import { ScrollToTop } from "../../../components/scroll/ScrollToTop";
import LoaderComponent from "../../../components/LoaderComponent";

// modals
import AddCategory from "../../../components/modals/AddCategory";
import config from "../../../utils/config";

const Category = () => {
    // redux
    const dispatch = useDispatch<AppDispatch>();
    const categoryTableData = useSelector((state: any) => state?.Category?.allCategory);

    const { t } = useTranslation();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const getAllCategories = () => {
        setIsLoading(true);

        dispatch(
            CategoryService.getAllCategories(
                (responseData: any) => {
                    setIsLoading(false);
                },
                (errorData: any) => {}
            )
        );
    };

    useEffect(() => {
        ScrollToTop();
        getAllCategories();
    }, []);

    const handleAddCategory = () => {
        onOpen();
        dispatch(setSelectedCategory({ create: true }));
    };

    const handleEditCategory = (row: any) => {
        onOpen();
        dispatch(setSelectedCategory({ ...row, edit: true }));
    };

    const [tableColumn, setTableColumn] = useState<any[]>([
        {
            id: 1,
            name: t("common.id"),
            sortable: true,
            omit: false,
            cell: (row: any) => (
                <Text
                    as={"span"}
                    cursor={"pointer"}
                    _hover={{ textDecoration: "underline" }}
                    onClick={() => handleEditCategory(row)}
                    color={"red"}
                    fontWeight={"normal"}
                >
                    #{row?._id.substr(row._id.length - 5)}
                </Text>
            )
        },
        {
            id: 2,
            name: t("category_mgmt.category_name"),
            selector: (row: any) => row?.name,
            sortable: true,
            omit: false
        },
        {
            id: 3,
            name: t("category_mgmt.status"),
            sortable: true,
            wrap: true,
            omit: false,
            cell: (row: any) => (
                <Badge as={"span"} variant="solid" colorScheme={row.enabled ? "green" : "blackAlpha"}>
                    {row.enabled ? t("status.active") : t("status.deactive")}
                </Badge>
            )
        }
    ]);

    // filter

    const [searchForm, setSearchForm] = useState({
        name: "",
        enabled: ""
    });

    const filteredCategory = useMemo(() => {
        let filterData = categoryTableData?.length > 0 ? [...categoryTableData] : [];

        if (searchForm.name?.trim()) {
            const lowerName = searchForm.name?.toLowerCase();
            filterData = filterData.filter((category) => category.name?.toLowerCase().includes(lowerName));
        }

        if (searchForm.enabled) {
            filterData = filterData.filter((category) => category?.enabled.toString().includes(searchForm.enabled));
        }

        return filterData;
    }, [categoryTableData, searchForm]);

    const handlerSearchValue = useCallback((event: any, keyName: any) => {
        const value = event.target.value;

        setSearchForm((prev) => {
            return { ...prev, [keyName]: value };
        });
    }, []);

    return (
        <Box>
            {isLoading ? (
                <LoaderComponent />
            ) : (
                <Box w="full">
                    <AddCategory isOpen={isOpen} onClose={onClose} getAllCategories={getAllCategories} />

                    <Flex justify="space-between" align="center" flexDir={{ base: "column", md: "row" }} gap={3}>
                        {/* heading  */}
                        <Text fontSize={"2xl"} fontWeight={"bold"} lineHeight="full" textAlign={"left"}>
                            {t("category_mgmt.category_management")}
                        </Text>
                        {/* Add Category  */}
                        <Button
                            bgColor={globalStyles.colors.btn.blue}
                            color={"white"}
                            leftIcon={<FaPlus />}
                            onClick={() => handleAddCategory()}
                        >
                            {t("category_mgmt.add_category")}
                        </Button>
                    </Flex>

                    {/* filter_data */}

                    <Flex
                        w="full"
                        gap={5}
                        bg={"white"}
                        p={3}
                        borderRadius="lg"
                        shadow="sm"
                        align="center"
                        flexDir={{ base: "column", md: "row" }}
                        my={3}
                    >
                        <Text fontWeight="semibold" color={"gray.600"} w={{ base: "full", md: "32" }}>
                            {t("common.filter_data")} :
                        </Text>
                        <Input
                            type={"text"}
                            name="name"
                            value={searchForm.name}
                            onChange={(e) => handlerSearchValue(e, "name")}
                            placeholder={t("category_mgmt.enter_category_name")}
                            variant="main"
                            w={{ base: "full", md: "3xs" }}
                        />
                        <Flex alignItems={"center"} gap={3}>
                            <Select
                                name={"enabled"}
                                value={searchForm.enabled}
                                onChange={(e) => handlerSearchValue(e, "enabled")}
                                variant="main"
                                w={"3xs"}
                            >
                                {config.CATEGORY_STATUS.map((status) => (
                                    <option value={status.value} key={status.label}>
                                        {t(`status.${status.label}`)}
                                    </option>
                                ))}
                            </Select>
                        </Flex>
                    </Flex>

                    <Box>
                        <DataTableComponent
                            column={tableColumn}
                            data={filteredCategory}
                            handleChange={() => {}}
                            selectableRows={false}
                        />
                    </Box>
                </Box>
            )}
        </Box>
    );
};

export default Category;
