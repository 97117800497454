import React, { useEffect, useState } from "react";
import { Box, Flex, Grid, GridItem, HStack, Image, Skeleton, Stack, Text } from "@chakra-ui/react";
// icons
import { FaAngleRight, FaAngleLeft } from "react-icons/fa";
import { globalStyles } from "../../../../theme/styles";
interface ProductCarouselProps {
    cover: any;
    images: any;
}
const ProductCarousel: React.FC<ProductCarouselProps> = ({ cover, images }) => {
    const productImages: any = [];
    productImages.push(cover);
    if (images && images.length > 0) {
        for (let i = 0; i < images.length; i++) {
            productImages.push(images[i]);
        }
    }
    const [currentSlide, setCurrentSlide] = useState(0);
    const slidesCount = productImages.length;
    const [activeImage, setActiveImage] = useState(cover);
    const prevSlide = () => {
        setCurrentSlide((s) => (s === 0 ? slidesCount - 1 : s - 1));
    };
    const nextSlide = () => {
        setCurrentSlide((s) => (s === slidesCount - 1 ? 0 : s + 1));
    };
    const setSlide = (slide: any) => {
        setCurrentSlide(slide.id - 1);
    };
    useEffect(() => {
        setActiveImage(productImages[currentSlide]);
    }, [currentSlide]);
    return (
        <Box>
            {cover ? (
                <Box>
                    <Flex w="full" pos="relative" overflow="hidden">
                        <Flex w="full" transition={"all .5s"} ml={`-${currentSlide * 100}%`}>
                            {productImages.map((slide: any, i: number) => (
                                <Box
                                    key={`slide-${i}`}
                                    boxSize="full"
                                    flex="none"
                                    h={"sm"}
                                    display={"flex"}
                                    justifyContent={"center"}
                                >
                                    <Image
                                        src={slide}
                                        alt="carousel image"
                                        align={"center"}
                                        h={"full"}
                                        objectFit="cover"
                                    />
                                </Box>
                            ))}
                        </Flex>

                        {/* left arrow  */}
                        <Text
                            cursor={"pointer"}
                            pos={"absolute"}
                            top={"41%"}
                            w={"auto"}
                            p={2}
                            bgColor={"blackAlpha.600"}
                            fontWeight={"bold"}
                            transition={"0.6s ease"}
                            borderRadius={"full"}
                            userSelect={"none"}
                            _hover={{
                                opacity: 0.8,
                                bg: "blackAlpha.800"
                            }}
                            left="0"
                            onClick={prevSlide}
                            ml={2}
                        >
                            <FaAngleLeft size={"1.4em"} color={"white"} />
                        </Text>
                        {/* right arrow  */}
                        <Text
                            cursor={"pointer"}
                            pos={"absolute"}
                            top={"41%"}
                            w={"auto"}
                            p={2}
                            bgColor={"blackAlpha.600"}
                            fontWeight={"bold"}
                            transition={"0.6s ease"}
                            borderRadius={"full"}
                            userSelect={"none"}
                            _hover={{
                                opacity: 0.8,
                                bg: "blackAlpha.800"
                            }}
                            right="0"
                            onClick={nextSlide}
                            mr={2}
                        >
                            <FaAngleRight size={"1.4em"} color={"white"} />
                        </Text>
                    </Flex>
                    ​{/* image grid  */}​
                    <Grid templateColumns="repeat(5, 1fr)" my={3} gap={3}>
                        {productImages.map((product: any, i: number) => {
                            return (
                                <GridItem
                                    w={"full"}
                                    key={i}
                                    // bgColor={activeImage === product ? "black" : ""}
                                    // border={activeImage === product ? "1px solid" : ""}
                                    borderColor={"black"}
                                    onClick={() => {
                                        setSlide(product);
                                    }}
                                    cursor={"pointer"}
                                    h={"0"}
                                    display={"flex"}
                                    justifyContent={"center"}
                                >
                                    {/* <Image src={product} objectFit={"cover"} align={"center"} h={"full"} /> */}
                                </GridItem>
                            );
                        })}
                    </Grid>
                </Box>
            ) : (
                <Skeleton w={"96"} h={"sm"} />
            )}
        </Box>
    );
};
export default ProductCarousel;
