import { useEffect, useState } from "react";
import { Box, Button, Flex, Text, useToast } from "@chakra-ui/react";

import { useDispatch, useSelector } from "react-redux";
import DashboardService from "../../services/DashboardService";

import { useTranslation } from "react-i18next";

import dayjs from "dayjs";

import { ScrollToTop } from "../../components/scroll/ScrollToTop";
import { globalStyles } from "../../theme/styles";

// Date picker
import DatePicker from "react-datepicker";

import LoaderComponent from "../../components/LoaderComponent";
import Dashboard from "../../views/admin/default";

const HomeAdmin = () => {
    const dispatch = useDispatch();

    //   date
    const today = new Date();
    const [startDate, setStartDate] = useState(new Date(today.getFullYear(), today.getMonth(), 1));
    const [endDate, setEndDate] = useState(today);

    const { t } = useTranslation();
    const toast = useToast();

    const [isLoading, setIsLoading] = useState(false);

    const onChange = (dates: any) => {
        const [start, end] = dates;
        setStartDate(start); 
        setEndDate(end);
    };

    //dashboard api
    const getDashobardDetail = (startDate: any, endDate: any) => {
        setIsLoading(true);
        dispatch(
            DashboardService.getDashboardDetails(
                { startDate, endDate },
                (responseData: any) => {},
                (errorData: any) => {
                    toast({
                        title: errorData?.message ? errorData?.message : errorData.response?.data?.message,
                        status: "error",
                        variant: "solid",
                        duration: 2000,
                        position: "top-right",
                        isClosable: true
                    });
                }
            )
        );

        dispatch(
            DashboardService.getPieChartDetails(
                (responseData: any) => {},
                (errorData: any) => {
                    toast({
                        title: errorData?.message ? errorData?.message : errorData.response?.data?.message,
                        status: "error",
                        variant: "solid",
                        duration: 2000,
                        position: "top-right",
                        isClosable: true
                    });
                }
            )
        );

        dispatch(
            DashboardService.getWeeklyRevenueDetails(
                (responseData: any) => {},
                (errorData: any) => {
                    toast({
                        title: errorData?.message ? errorData?.message : errorData.response?.data?.message,
                        status: "error",
                        variant: "solid",
                        duration: 2000,
                        position: "top-right",
                        isClosable: true
                    });
                }
            )
        );
        dispatch(
            DashboardService.getWeeklyProductsSold(
                (responseData: any) => {},
                (errorData: any) => {
                    toast({
                        title: errorData?.message ? errorData?.message : errorData.response?.data?.message,
                        status: "error",
                        variant: "solid",
                        duration: 2000,
                        position: "top-right",
                        isClosable: true
                    });
                }
            )
        );
        dispatch(
            DashboardService.getSixMonthRevenue(
                (responseData: any) => {
                    setIsLoading(false);
                },
                (errorData: any) => {
                    toast({
                        title: errorData?.message ? errorData?.message : errorData.response?.data?.message,
                        status: "error",
                        variant: "solid",
                        duration: 2000,
                        position: "top-right",
                        isClosable: true
                    });
                }
            )
        );
    };

    useEffect(() => {
        ScrollToTop();
        getDashobardDetail(dayjs(startDate).format("YYYY/MM/DD"), dayjs(endDate).format("YYYY/MM/DD"));
    }, []);

    return (
        <Box>
            {isLoading ? (
                <LoaderComponent />
            ) : (
                <Box>
                    <Box px={{ base: 2, md: 0 }}>
                        {/* heading  */}
                        <Text fontSize={"2xl"} fontWeight={"bold"} lineHeight="full" textAlign={"left"}>
                            {t("dashboard.dashboard")}
                        </Text>

                        {/* date picking  */}
                        <Flex
                            w="full"
                            alignItems={{ base: "baseline", md: "center" }}
                            justify="space-between"
                            direction={{ base: "column-reverse", sm: "row" }}
                            mt={6}
                            gap={2}
                        >
                            <Flex
                                alignItems={{ base: "flex-start", md: "center" }}
                                direction={{ base: "column", md: "row" }}
                                justifyContent={{ base: "flex-start" }}
                                gap={4}
                            >
                                <Text fontSize="md" fontWeight="medium">
                                    {t("common.select_date_range")}
                                </Text>
                                <DatePicker
                                    dateFormat="yyyy/MM/dd"
                                    selected={startDate}
                                    placeholderText={t("common.select_date_range")}
                                    onChange={onChange}
                                    startDate={startDate}
                                    endDate={endDate}
                                    selectsRange
                                    className="custom"
                                    popperClassName="popper-class"
                                    popperPlacement="bottom-start"
                                    todayButton="Today"
                                    showPopperArrow={false}
                                    maxDate={new Date()}
                                    popperModifiers={[
                                        {
                                            name: "offset",
                                            options: {
                                                offset: [0, 0]
                                            }
                                        },
                                        {
                                            name: "preventOverflow",
                                            options: {
                                                rootBoundary: "viewport",
                                                tether: false,
                                                altAxis: true
                                            }
                                        }
                                    ]}
                                />
                                <Button
                                    bgColor={globalStyles.colors.btn.blue}
                                    onClick={() => {
                                        let EndDate = endDate;
                                        if (!endDate) {
                                            EndDate = startDate;
                                        }
                                        getDashobardDetail(
                                            dayjs(startDate).format("YYYY/MM/DD"),
                                            dayjs(EndDate).format("YYYY/MM/DD")
                                        );
                                    }}
                                    color={"white"}
                                >
                                    {t("common.show_data")}
                                </Button>
                            </Flex>
                        </Flex>
                    </Box>

                    <Dashboard />
                </Box>
            )}
        </Box>
    );
};

export default HomeAdmin;
