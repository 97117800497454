import { combineReducers } from "redux";
import User from "./reducers/user";
import Category from "./reducers/category";
import Group from "./reducers/group";
import Auth from "./reducers/Auth";
import Product from "./reducers/product";
import Sales from "./reducers/Sales";
import Inquiry from "./reducers/inquiry";
import Review from "./reducers/review";
import Dashboard from "./reducers/dashboard";

const rootReducer = combineReducers({
    Auth,
    User,
    Category,
    Group,
    Product,
    Sales,
    Review,
    Dashboard,
    Inquiry
});

export type AppState = ReturnType<typeof rootReducer>;

export default rootReducer;
