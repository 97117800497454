import { useState, useEffect } from "react";
import {
    Button,
    Flex,
    FormControl,
    FormLabel,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Switch,
    Text,
    useDisclosure,
    useToast
} from "@chakra-ui/react";

// redux
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../store";
import { setSelectedCategory } from "../../store/actions/category";
import CategoryService from "../../services/CategoryService";

import * as yup from "yup";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";

import { FaTrashAlt } from "react-icons/fa";
import { globalStyles } from "../../theme/styles";
import SmallInputField from "../fields/SmallInputField";

interface IProps {
    isOpen: boolean;
    onClose: () => void;
    getAllCategories: any;
}

const AddCategory = ({ isOpen, onClose, getAllCategories }: IProps) => {
    // redux
    const categoryDetails = useSelector((state: any) => state.Category.selectedCategory);
    const dispatch = useDispatch<AppDispatch>();

    const { t } = useTranslation();
    const toast = useToast();
    const [categoryEnabled, setCategoryEnabled] = useState(false);

    const categorySchema = yup.object().shape({
        name: yup.string().required(t("form_errors.required_fields"))
    });

    const onSubmit = async (values: any, actions: any) => {
        // create
        if (categoryDetails?.create) {
            const data = {
                name: values.name,
                enabled: categoryEnabled
            };

            dispatch(
                CategoryService.createCategory(
                    { data },
                    (responseData: any) => {
                        toast({
                            title: t("messages.category_create_success"),
                            status: "success",
                            variant: "solid",
                            duration: 2000,
                            position: "top-right",
                            isClosable: true
                        });
                        getAllCategories();
                        handleModalClose();
                    },
                    (errorData: any) => {
                        toast({
                            title: errorData?.message ? errorData?.message : errorData.response?.data?.message,
                            status: "error",
                            variant: "solid",
                            duration: 2000,
                            position: "top-right",
                            isClosable: true
                        });
                    }
                )
            );
        }
        // edit
        else if (categoryDetails?.edit) {
            const data = {
                data: {
                    name: values.name,
                    enabled: categoryEnabled
                },
                id: categoryDetails?._id
            };

            dispatch(
                CategoryService.updateCategory(
                    { data },
                    (responseData: any) => {
                        toast({
                            title: t("messages.category_update_success"),
                            status: "success",
                            variant: "solid",
                            duration: 2000,
                            position: "top-right",
                            isClosable: true
                        });
                        getAllCategories();
                        handleModalClose();
                    },
                    (errorData: any) => {
                        toast({
                            title: errorData?.message ? errorData?.message : errorData.response?.data?.message,
                            status: "error",
                            variant: "solid",
                            duration: 2000,
                            position: "top-right",
                            isClosable: true
                        });
                    }
                )
            );
        }

        await new Promise((resolve) => setTimeout(resolve, 1000));
        actions.resetForm();
    };

    const { values, errors, touched, isSubmitting, handleBlur, handleChange, handleSubmit, setFieldValue } = useFormik({
        initialValues: {
            name: ""
        },
        validationSchema: categorySchema,
        onSubmit
    });

    useEffect(() => {
        if (categoryDetails?.edit) {
            setFieldValue("name", categoryDetails?.name);
            setCategoryEnabled(categoryDetails?.enabled);
        } else if (categoryDetails?.create) {
            setFieldValue("name", "");
            setCategoryEnabled(false);
        }
    }, [categoryDetails?.edit, categoryDetails?.create]);

    const handleSwitch = () => {
        if (categoryEnabled) {
            return setCategoryEnabled(false);
        }
        setCategoryEnabled(true);
    };

    const handleModalClose = () => {
        dispatch(setSelectedCategory({}));
        onClose();
    };

    const handleDeleteCategory = () => {
        dispatch(
            CategoryService.deleteCategory(
                { id: categoryDetails._id },
                (responseData: any) => {
                    toast({
                        title: t("messages.category_delete_success"),
                        status: "success",
                        variant: "solid",
                        duration: 2000,
                        position: "top-right",
                        isClosable: true
                    });
                    getAllCategories();
                    handleModalClose();
                },
                (errorData: any) => {
                    toast({
                        title: t("messages.category_delete_reject"),
                        status: "error",
                        variant: "solid",
                        duration: 2000,
                        position: "top-right",
                        isClosable: true
                    });
                }
            )
        );
    };

    const modal2 = useDisclosure();

    return (
        <>
            <Modal isOpen={isOpen} onClose={() => handleModalClose()}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>
                        {categoryDetails?.edit ? t("category_mgmt.edit_category") : t("category_mgmt.add_category")}
                    </ModalHeader>
                    <ModalCloseButton />
                    <form onSubmit={handleSubmit}>
                        <ModalBody>
                            <SmallInputField
                                label={t("category_mgmt.enter_category")}
                                name={"name"}
                                type={"string"}
                                value={values.name}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                errors={errors.name}
                                touched={touched.name}
                            />

                            <FormControl display="flex" alignItems="center">
                                <FormLabel htmlFor="email-alerts" mb="0" fontSize={"md"} fontWeight={"light"}>
                                    {t("category_mgmt.enable_this_category")}?
                                </FormLabel>
                                <Switch
                                    id="email-alerts"
                                    name="enabled"
                                    isChecked={categoryEnabled}
                                    onChange={handleSwitch}
                                />
                            </FormControl>
                        </ModalBody>

                        <ModalFooter>
                            <Flex w={"full"} justifyContent={"space-between"}>
                                {categoryDetails?.edit && (
                                    <Flex
                                        bgColor={"red.500"}
                                        w={8}
                                        h={8}
                                        justifyContent={"center"}
                                        alignItems={"center"}
                                        borderRadius={"xl"}
                                        cursor={"pointer"}
                                        onClick={() => {}}
                                    >
                                        <FaTrashAlt onClick={modal2.onOpen} color="white" />
                                    </Flex>
                                )}

                                <Flex>
                                    <Button
                                        bgColor={globalStyles.colors.btn.success}
                                        color={"white"}
                                        mr={3}
                                        type={"submit"}
                                        isLoading={isSubmitting}
                                    >
                                        {t("category_mgmt.save")}
                                    </Button>
                                    <Button
                                        bgColor={globalStyles.colors.btn.blue}
                                        color={"white"}
                                        onClick={() => handleModalClose()}
                                    >
                                        {t("category_mgmt.cancel")}
                                    </Button>
                                </Flex>
                            </Flex>
                        </ModalFooter>
                    </form>
                </ModalContent>
            </Modal>

            {/* modal for delete  */}
            <Modal isOpen={modal2.isOpen} onClose={modal2.onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{t("category_mgmt.delete_category")}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        {t("messages.category_delete_confirm")} <br /> {t("messages.this_action_is_irreversible")}
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            colorScheme="red"
                            onClick={() => {
                                handleDeleteCategory();
                            }}
                            mr={3}
                        >
                            {t("user_mgmt.confirm")}
                        </Button>
                        <Button variant="solid" colorScheme={"blue"} onClick={modal2.onClose}>
                            {t("user_mgmt.cancel")}
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};

export default AddCategory;
