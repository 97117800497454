import React, { useEffect } from "react";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    FormControl,
    FormLabel,
    Input,
    Button,
    Textarea,
    useToast,
    Text
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";

import ReviewService from "../../services/ReviewService";

import * as yup from "yup";
import { useFormik } from "formik";
import dayjs from "dayjs";

import { useTranslation } from "react-i18next";
import { globalStyles } from "../../theme/styles";
import { AppDispatch } from "../../store";

interface AddReplyProps {
    isOpen: any;
    onClose: any;
    getReviewsByDate: any;
}

const AddReply: React.FC<AddReplyProps> = ({ isOpen, onClose, getReviewsByDate }) => {
    const disaptch = useDispatch<AppDispatch>();
    const Review = useSelector((state: any) => state?.Review?.selectedReview);

    const toast = useToast();
    const today = new Date();

    const { t } = useTranslation();

    const onSubmit = async (values: any, actions: any) => {
        disaptch(
            ReviewService.replyReview(
                { data: values },
                (responseData: any) => {
                    getReviewsByDate(
                        dayjs(new Date(today.getFullYear(), today.getMonth(), 1)).format("YYYY/MM/DD"),
                        dayjs(today).format("YYYY/MM/DD")
                    );
                    onClose();
                },
                (errorData: any) => {
                    toast({
                        title: errorData?.message ? errorData?.message : errorData.response?.data?.message,
                        status: "error",
                        variant: "solid",
                        duration: 2000,
                        position: "top-right",
                        isClosable: true
                    });
                }
            )
        );
    };

    const reviewSchema = yup.object().shape({
        admin_reply: yup.string().required(t("form_errors.required_fields"))
    });

    const { values, errors, touched, isSubmitting, handleBlur, handleChange, handleSubmit, setFieldValue } = useFormik({
        initialValues: {
            review_id: "",
            admin_reply: ""
        },
        validationSchema: reviewSchema,
        onSubmit
    });

    useEffect(() => {
        setFieldValue("review_id", Review?._id);
        setFieldValue("admin_reply", Review?.admin_reply ? Review?.admin_reply : "");
    }, [Review]);

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <form onSubmit={handleSubmit}>
                    <ModalHeader>{t("reviews.reply")}</ModalHeader>
                    <ModalCloseButton />

                    <ModalBody pb={6}>
                        <FormControl mt={4}>
                            <Textarea
                                name="admin_reply"
                                value={values.admin_reply}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isInvalid={Boolean(errors.admin_reply && touched.admin_reply)}
                                errorBorderColor="red.300"
                                placeholder={t("reviews.enter_reply_here")}
                            />
                            {errors.admin_reply && touched.admin_reply && (
                                <Text fontSize={"sm"} mt={1} color={"red.300"}>
                                    {errors.admin_reply}
                                </Text>
                            )}
                        </FormControl>
                    </ModalBody>

                    <ModalFooter>
                        <Button bgColor={globalStyles.colors.btn.success} color={"white"} mr={3} type={"submit"}>
                            {t("reviews.save")}
                        </Button>
                        <Button bgColor={globalStyles.colors.btn.blue} color={"white"} onClick={onClose}>
                            {t("reviews.cancel")}
                        </Button>
                    </ModalFooter>
                </form>
            </ModalContent>
        </Modal>
    );
};

export default AddReply;
