import {
    API_INVOCATION,
    GET_DASHBOARD_DETAILS,
    GET_PIECHART_DETAILS,
    GET_WEEKLY_PRODUCTS_SELL,
    GET_WEEKLY_REVENUE_DETAILS,
    LAST_SIX_MONTH_REVENUE
} from "../store/actionTypes";
import { GET } from "../utils/apiConstant";
import {
    DASHBOARD_DETAILS_URL,
    PIE_CHART_URL,
    SIX_MONTH_REVENUE_URL,
    WEEKLY_PRODUCT_SOLD_URL,
    WEEKLY_REVENUE_URL
} from "../utils/url";

class Dashboard {
    // getDashboardDetails
    getDashboardDetails(_payload: any, resolve: any, reject: any) {
        const payload = {
            action: GET_DASHBOARD_DETAILS,
            method: GET,
            apiConfig: {},
            url: `${DASHBOARD_DETAILS_URL}?start_date=${_payload.startDate}&end_date=${_payload.endDate}`,
            resolve,
            reject
        };
        return { type: API_INVOCATION, payload };
    }

    // getPieChartDetails
    getPieChartDetails(resolve: any, reject: any) {
        const payload = {
            action: GET_PIECHART_DETAILS,
            method: GET,
            apiConfig: {},
            url: PIE_CHART_URL,
            resolve,
            reject
        };
        return { type: API_INVOCATION, payload };
    }

    // getWeeklyRevenueDetails
    getWeeklyRevenueDetails(resolve: any, reject: any) {
        const payload = {
            action: GET_WEEKLY_REVENUE_DETAILS,
            method: GET,
            apiConfig: {},
            url: WEEKLY_REVENUE_URL,
            resolve,
            reject
        };
        return { type: API_INVOCATION, payload };
    }

    // getWeeklyProductsSold
    getWeeklyProductsSold(resolve: any, reject: any) {
        const payload = {
            action: GET_WEEKLY_PRODUCTS_SELL,
            method: GET,
            apiConfig: {},
            url: WEEKLY_PRODUCT_SOLD_URL,
            resolve,
            reject
        };
        return { type: API_INVOCATION, payload };
    }

    //getSixMonthRevenue
    getSixMonthRevenue(resolve: any, reject: any) {
        const payload = {
            action: LAST_SIX_MONTH_REVENUE,
            method: GET,
            apiConfig: {},
            url: SIX_MONTH_REVENUE_URL,
            resolve,
            reject
        };
        return { type: API_INVOCATION, payload };
    }
}

export default new Dashboard();
