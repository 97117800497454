import {
    GET_ALL_PRODUCTS_SUCCESS,
    GET_PRODUCT_REVIEWS_SUCCESS,
    GET_PRODUCT_SUCCESS,
    EMPTY_SELECTED_PRODUCT
} from "../actionTypes";

const INITIAL_STATE = {
    allProducts: [],
    selectedProduct: {},
    productReview: []
};
const Product = (state = INITIAL_STATE, action: { type: string; payload: any }) => {
    switch (action.type) {
        case GET_ALL_PRODUCTS_SUCCESS:
            return {
                ...state,
                allProducts: action.payload.data.rows
            };
        case GET_PRODUCT_SUCCESS:
            return {
                ...state,
                selectedProduct: action.payload.data
            };
        case EMPTY_SELECTED_PRODUCT:
            return {
                ...state,
                selectedProduct: {}
            };
        case GET_PRODUCT_REVIEWS_SUCCESS:
            return {
                ...state,
                productReview: action.payload.data.rows[0]
            };
        default:
            return state;
    }
};

export default Product;
