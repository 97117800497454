import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Badge, Box, Button, Flex, Input, Select, Text, useToast, Grid, GridItem, Icon } from "@chakra-ui/react";

// redux
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../store";

import CategoryService from "../../../services/CategoryService";
import GroupService from "../../../services/GroupService";
import ProductService from "../../../services/ProductService";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

// icons
import { FaPlus } from "react-icons/fa";

// components
import DataTableComponent from "../../../components/dataTable/DataTable";
import { ScrollToTop } from "../../../components/scroll/ScrollToTop";
import { globalStyles } from "../../../theme/styles";

import config from "../../../utils/config";
import LoaderComponent from "../../../components/LoaderComponent";
import { numberWithCommas } from "../../../utils/helperFunctions";

const Product = () => {
    // redux
    const dispatch = useDispatch<AppDispatch>();
    const ProductTableData = useSelector((state: any) => state?.Product?.allProducts);
    const CategoryTableData = useSelector((state: any) => state?.Category?.allCategory);
    const GroupTableData = useSelector((state: any) => state?.Group?.allGroup);

    const toast = useToast();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const getAllProducts = () => {
        setIsLoading(true);
        dispatch(
            ProductService.getAllProducts(
                (responseData: any) => {
                    setIsLoading(false);
                },
                (errorData: any) => {}
            )
        );
    };

    const getCategoryAndGroupData = () => {
        dispatch(
            CategoryService.getAllCategories(
                (responseData: any) => {},
                (errorData: any) => {}
            )
        );

        dispatch(
            GroupService.getAllGroups(
                (responseData: any) => {},
                (errorData: any) => {}
            )
        );
    };

    useEffect(() => {
        ScrollToTop();
        getCategoryAndGroupData();
        getAllProducts();
    }, []);

    const handleViewProduct = (row: any) => {
        navigate(`/product-management/${row._id}`);
    };

    const [tableColumn, setTableColumn] = useState<any[]>([
        {
            id: 1,
            name: t("product_mgmt.id").toUpperCase(),
            sortable: true,
            wrap: 0,

            cell: (row: any) => (
                <Text
                    as={"span"}
                    cursor={"pointer"}
                    _hover={{ textDecoration: "underline" }}
                    onClick={() => handleViewProduct(row)}
                    color={"red"}
                    fontWeight={"normal"}
                >
                    #{row?._id && row?._id?.substr(row?._id.length - 5)}
                </Text>
            )
        },
        {
            id: 2,
            name: t("product_mgmt.product_number").toUpperCase(),
            selector: (row: any) => row.product_number,
            wrap: true,
            omit: false
        },
        {
            id: 3,
            name: t("product_mgmt.product_name"),
            selector: (row: any) => row.name,
            grow: 1.3,
            sortable: true,
            wrap: true,
            omit: false
        },
        {
            id: 4,
            name: t("product_mgmt.category"),
            selector: (row: any) => (row?.category?.name ? row?.category?.name : "-"),
            sortable: true,
            wrap: true,
            omit: false
        },
        {
            id: 5,
            name: t("product_mgmt.group"),
            selector: (row: any) => (row?.group?.name ? row?.group?.name : "-"),
            sortable: true,
            wrap: true,
            omit: false
        },

        {
            id: 6,
            name: t("product_mgmt.amount"),
            sortable: true,
            wrap: true,
            omit: false,
            cell: (row: any) => <Box as="span"> &#165;{row.price && numberWithCommas(Number(row.price))}</Box>
        },

        {
            id: 7,
            name: t("product_mgmt.status"),
            selector: (row: any) => row.status,
            sortable: true,
            cell: (row: any) => (
                <Badge
                    variant="solid"
                    colorScheme={
                        row.status === "ACTIVE"
                            ? "green"
                            : row.status === "DEACTIVATED"
                            ? "red"
                            : row.status === "DEACTIVATED"
                            ? "blackAlpha"
                            : "blue"
                    }
                >
                    {row.status === "ACTIVE" ? t("status.active") : t("status.deactive")}
                </Badge>
            ),

            omit: false
        }
    ]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [searchForm, setSearchForm] = useState({
        product: "",
        category: "",
        group: "",
        status: ""
    });

    const filteredProducts = useMemo(() => {
        let filterData = ProductTableData.length > 0 ? [...ProductTableData] : [];

        if (searchForm.product?.trim()) {
            filterData = filterData.filter((product) =>
                product?.name?.toString().toLowerCase().includes(searchForm.product)
            );
        }

        if (searchForm.category?.trim()) {
            filterData = filterData.filter((product) =>
                product?.category?.name?.toString().includes(searchForm.category)
            );
        }

        if (searchForm.group?.trim()) {
            filterData = filterData.filter((product) => product?.group?.name?.toString().includes(searchForm.group));
        }

        if (searchForm.status?.trim()) {
            filterData = filterData.filter((product) => product?.status.includes(searchForm.status));
        }

        return filterData;
    }, [ProductTableData, searchForm]);

    const handlerSearchValue = useCallback((event: any, keyName: any) => {
        const value = event.target.value;

        setSearchForm((prev) => {
            return { ...prev, [keyName]: value };
        });
    }, []);

    const handleCreateProduct = () => {
        navigate("/product-management/create");
    };

    return (
        <Box>
            {isLoading ? (
                <LoaderComponent />
            ) : (
                <Box w="full">
                    <Box w="full">
                        {/* heading  */}
                        <Flex justify="space-between" align="center" flexDir={{ base: "column", md: "row" }} gap={3}>
                            <Text fontSize={"2xl"} fontWeight={"bold"} lineHeight="full" textAlign={"left"}>
                                {t("product_mgmt.product_management")}
                            </Text>

                            <Button
                                bgColor={globalStyles.colors.btn.blue}
                                color={"white"}
                                onClick={() => handleCreateProduct()}
                                leftIcon={<FaPlus />}
                            >
                                {t("product_mgmt.create_product")}
                            </Button>
                        </Flex>
                    </Box>

                    {/* filters  */}

                    <Grid
                        templateColumns={{
                            base: "repeat(1, 1fr)",
                            md: "repeat(3, 1fr)",
                            lg: "repeat(5, 1fr)"
                        }}
                        templateRows={{
                            base: "repeat(4, 1fr)",
                            md: "repeat(2, 1fr)",
                            lg: "repeat(1, 1fr)"
                        }}
                        mt={3}
                        mb={2}
                        p={3}
                        justifyContent={{
                            base: "center"
                        }}
                        gap={3}
                        alignItems="center"
                        bg={"white"}
                        borderRadius="lg"
                        shadow="sm"
                    >
                        <GridItem w="full">
                            <Text pr={{ base: "unset", md: "2" }} fontSize="md" fontWeight="medium" flex={2}>
                                {t("common.filter_data")} :
                            </Text>
                        </GridItem>

                        <GridItem w="full">
                            <Input
                                type={"text"}
                                name="product"
                                value={searchForm.product}
                                onChange={(e) => {
                                    handlerSearchValue(e, "product");
                                }}
                                placeholder={t("product_mgmt.enter_product_name")}
                                variant="main"
                            />
                        </GridItem>

                        <GridItem w="full">
                            <Select
                                name={"category"}
                                value={searchForm.category}
                                variant="main"
                                onChange={(e) => {
                                    handlerSearchValue(e, "category");
                                }}
                            >
                                <option value={""}>{t("status.ALL")}</option>
                                {CategoryTableData.map((category: any) => {
                                    return (
                                        <option value={category.name} key={category._id}>
                                            {category.name}
                                        </option>
                                    );
                                })}
                            </Select>
                        </GridItem>
                        <GridItem w="full">
                            <Select
                                name={"group"}
                                value={searchForm.group}
                                variant="main"
                                onChange={(e) => {
                                    handlerSearchValue(e, "group");
                                }}
                            >
                                <option value={""}>{t("status.ALL")}</option>
                                {GroupTableData.map((group: any) => {
                                    return (
                                        <option value={group.name} key={group._id}>
                                            {group.name}
                                        </option>
                                    );
                                })}
                            </Select>
                        </GridItem>
                        <GridItem w="full">
                            <Select
                                name={"group"}
                                value={searchForm.status}
                                variant="main"
                                onChange={(e) => {
                                    handlerSearchValue(e, "status");
                                }}
                            >
                                {config.PRODUCT_STATUS.map((product: any) => {
                                    return (
                                        <option value={product.value} key={product.value}>
                                            {t(`status.${product.label}`)}
                                        </option>
                                    );
                                })}
                            </Select>
                        </GridItem>
                    </Grid>

                    <Box>
                        <DataTableComponent
                            column={tableColumn}
                            data={filteredProducts}
                            handleChange={() => {}}
                            selectableRows={false}
                        />
                    </Box>
                </Box>
            )}
        </Box>
    );
};

export default Product;
