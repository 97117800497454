import { Flex, Circle, Box, Badge, useColorModeValue, Text, Button } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { BsStar, BsStarFill, BsStarHalf } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { globalStyles } from "../../../../theme/styles";
import { useSelector } from "react-redux";
import { adjustPincode, handleDownloadPDF, numberWithCommas } from "../../../../utils/helperFunctions";
import { CgLayoutGrid } from "react-icons/cg";
interface IProps {
    data: any;
}
const PurchaseCard = ({ data }: IProps) => {
    const navigate = useNavigate();
    const handleViewOrder = (id: string) => {
        navigate(`/sales-management/${id}`);
    };

    const { t } = useTranslation();

    return (
        <Box bg={"white"} width="full" rounded="lg" shadow="base" minH={"2xs"}>
            <Box p="6">
                <Box display="flex" alignItems="baseline" justifyContent="space-between">
                    <Badge
                        rounded="full"
                        px="2"
                        fontSize="0.8em"
                        colorScheme={
                            data.status === "DELIVERED"
                                ? "green"
                                : data.status === "CANCEL"
                                ? "red"
                                : data.status === "PAYMENT-PENDING"
                                ? "orange"
                                : "blue"
                        }
                    >
                        <Text as="span"> {t(`status.${data.status}`)}</Text>
                    </Badge>

                    <Text fontSize="xs">{dayjs(data?.order_date).format("YYYY/MM/DD HH:mm")}</Text>
                </Box>
                <Flex mt="1" justifyContent="space-between" alignItems="center">
                    <Box
                        fontSize="lg"
                        fontWeight="bold"
                        as="h4"
                        lineHeight="tight"
                        cursor={"pointer"}
                        onClick={() => {
                            handleViewOrder(data?._id);
                        }}
                        color={"red.600"}
                    >
                        #{data?._id}
                    </Box>
                </Flex>
                <Box> &#165;{data?.total_amount && numberWithCommas(Number(data?.total_amount))}</Box>
                <Flex direction={"column"} my={3}>
                    <Text fontSize="md">{data?.user?.name ? data.user.name : data.address?.user_name}</Text>
                    <Text fontSize="md">
                        {`${data?.address?.zipcode && adjustPincode(data?.address?.zipcode)} , ${
                            data?.address?.state
                        } , ${data?.address?.city} , ${data?.address?.street_address}`}
                    </Text>

                    {data.status != "CANCEL" && (
                        <Button
                            bgColor={globalStyles.colors.btn.blue}
                            color={"white"}
                            mt={3}
                            onClick={() => handleDownloadPDF(data?._id)}
                        >
                            {t("sales_mgmt.download_invoice")}
                        </Button>
                    )}
                </Flex>
            </Box>
        </Box>
    );
};

export default PurchaseCard;
