import { Box, Divider, Flex, Text, useColorModeValue, VStack } from "@chakra-ui/react";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { BsStar, BsStarFill, BsStarHalf } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import RatingStar from "../../../../components/star/RatingStar";

interface Iprops {
    reviewTableData: any;
}

const ReviewCard = ({ reviewTableData }: Iprops) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleViewProduct = (id: string) => {
        navigate(`/product-management/${id}`);
        // sessionStorage.setItem("selectedtabadmin", "products");
    };

    return (
        <Box bg={"white"} width="full" position={"relative"} rounded="lg" shadow="base" minH={"60"}>
            <Box padding={"6"}>
                <Flex mt="1">
                    <Box fontSize="2xl" fontWeight="semibold" as="h4" lineHeight="tight">
                        <Text
                            fontSize="xl"
                            fontWeight="semibold"
                            cursor={"pointer"}
                            onClick={() => {
                                handleViewProduct(reviewTableData?.product?._id);
                            }}
                        >
                            {reviewTableData?.product?.name}
                        </Text>

                        <RatingStar rating={reviewTableData?.review_point} />

                        <VStack align="flex-start" spacing={3} mt={3}>
                            <Text fontSize="sm" fontWeight="normal">
                                {reviewTableData?.content}
                            </Text>
                            <Divider />
                            <Box
                                position={"absolute"}
                                bottom={5}
                                w={"90%"}
                                display={"flex"}
                                justifyContent={"space-between"}
                            >
                                <Text fontSize="sm" mt={2} fontWeight="medium">
                                    {t("user_mgmt.status")} :
                                    <Text
                                        as="span"
                                        fontSize="sm"
                                        fontWeight="bold"
                                        color={reviewTableData?.enabled ? "green.500" : "red.500"}
                                        border="2px solid"
                                        rounded="full"
                                        borderColor={reviewTableData?.enabled ? "green.500" : "red.500"}
                                        p={1}
                                        mx={1}
                                    >
                                        {t(`status.${reviewTableData?.enabled ? "active" : "deactive"}`)}
                                    </Text>
                                </Text>

                                <Text fontSize={"lg"}>
                                    {dayjs(reviewTableData?.createdAt).format("YYYY/MM/DD HH:mm")}
                                </Text>
                            </Box>
                        </VStack>
                    </Box>
                </Flex>
            </Box>
        </Box>
    );
};

export default ReviewCard;
