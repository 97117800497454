import { Box, Flex, Text, Select, useColorModeValue } from "@chakra-ui/react";
import Card from "../../../../components/card/Card";
import PieChart from "../../../../components/charts/PieChart";
import { VSeparator } from "../../../../components/separator/Separator";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { useSelector } from "react-redux";

interface ConversionProps {
    title: string;
    datas: any;
}

const Conversion: React.FC<ConversionProps> = ({ title, datas }) => {
    const PieChartData = useSelector((state: any) => state?.Dashboard?.pieChartDetails);

    const { t } = useTranslation();

    const [productNames, setProductNames] = useState([]);

    useEffect(() => {
        const names: any = [];
        for (let i = 0; i < PieChartData.length; i++) {
            const name = PieChartData[i].productName;

            names.push(name);
        }
        setProductNames(names);
    }, [PieChartData]);

    const pieChartOptions = {
        labels: productNames,
        colors: [
            "#4318FF",
            "#6AD2FF",
            "#2ecc71",
            "#ff5733",
            "#641E16",
            "#4A235A",
            "#4A235A",
            "#154360",
            "#ff0000",
            "#000000",
            "#383CC1",
            "#3DBE29",
            "#D9D55B",
            "#8D3DAF",
            "#758283",
            "#FF6666",
            "#03C6C7"
        ],
        chart: {
            width: "200px"
        },
        states: {
            hover: {
                filter: {
                    type: "none"
                }
            }
        },
        legend: {
            show: false
        },
        dataLabels: {
            enabled: false
        },
        hover: { mode: null },
        plotOptions: {
            donut: {
                expandOnClick: false,
                donut: {
                    labels: {
                        show: false
                    }
                }
            }
        },
        fill: {
            colors: [
                "#4318FF",
                "#6AD2FF",
                "#2ecc71",
                "#ff5733",
                "#641E16",
                "#4A235A",
                "#4A235A",
                "#154360",
                "#ff0000",
                "#000000",
                "#383CC1",
                "#3DBE29",
                "#D9D55B",
                "#8D3DAF",
                "#758283",
                "#FF6666",
                "#03C6C7"
            ]
        },
        tooltip: {
            enabled: true,
            theme: "dark"
        }
    };

    const today = new Date();
    const month = dayjs(today).format("MM");

    return (
        <Card p={4} align="center" direction="column" w="full">
            <Flex
                px={{ base: "0px", "2xl": "10px" }}
                justifyContent="space-between"
                alignItems="center"
                w="full"
                mb={2}
            >
                <Text color={"secondaryGray.900"} fontSize="md" fontWeight="600" mt={2}>
                    {title} - {Number(month)} 月
                </Text>
            </Flex>

            <Flex justifyContent={"center"} alignItems={"center"} h={"full"}>
                {datas.length > 0 && <PieChart h="full" w="full" chartData={datas} chartOptions={pieChartOptions} />}
            </Flex>

            {/* <Card bg={"white"} flexDirection="row" shadow="md" w="full" p={3} px={5} mt={3} mx="auto">
                <Flex justifyContent="space-between" w="full" alignItems="center">
                    {datas.map((data: any, i: number) => {
                        return (
                            <Flex direction="column" py={2} key={i}>
                                <Flex align="center">
                                    <Box h="8px" w="8px" bg="brand.500" borderRadius="50%" me="4px" />
                                    <Text fontSize="xs" color="secondaryGray.600" fontWeight={"semibold"} mb="5px">
                                        商品 {i + 1}
                                    </Text>
                                </Flex>
                                <Text fontSize="lg" color={"secondaryGray.900"} fontWeight="700">
                                    {data}
                                </Text>
                            </Flex>
                        );
                    })}
                </Flex>
            </Card> */}
        </Card>
    );
};

export default Conversion;
