import { useCallback, useEffect, useMemo, useState } from "react";
import {
    Badge,
    Box,
    Button,
    Flex,
    Input,
    Select,
    Text,
    useToast,
    Grid,
    GridItem,
    useDisclosure,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter
} from "@chakra-ui/react";

import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { globalStyles } from "../../../theme/styles";

// components
import DataTableComponent from "../../../components/dataTable/DataTable";
import DatePicker from "react-datepicker";

import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../store";
import InquireService from "../../../services/InquireService";
import config from "../../../utils/config";
import LoaderComponent from "../../../components/LoaderComponent";
import ExportBtn from "../../../components/button/ExportBtn";
import { FaTrash } from "react-icons/fa";
import DeleteInquiry from "../../../components/modals/DeleteInquiry";

export default function Inquiry() {
    const toast = useToast();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const disaptch = useDispatch<AppDispatch>();
    const today = new Date();

    const [startDate, setStartDate] = useState(new Date(today.getFullYear(), today.getMonth(), 1));
    const [endDate, setEndDate] = useState(today);
    const inquiryTableData = useSelector((state: any) => state?.Inquiry?.allInquires);

    const [isLoading, setIsLoading] = useState(false);
    const [exportData, setExportData] = useState([]);

    //inquiry
    const [inquiry, setInquiry] = useState<any>({});

    const handleViewInquiry = (row: any) => {
        navigate(`/inquiry-management/${row._id}`);
    };

    const handleViewUser = (id: any) => {
        navigate(`/user-management/${id}`);
    };

    const { isOpen: isDeleteOpen, onOpen: onDeleteOpen, onClose: onDeleteClose } = useDisclosure();

    const [deleteInquiryId, setDeleteInquiryId] = useState("");

    const handleDelete = (row: any) => {
        setDeleteInquiryId(row?._id);
        onDeleteOpen();
    };

    const tableColumn = [
        {
            id: 1,
            name: t("common.id").toUpperCase(),
            sortable: true,
            wrap: true,
            omit: false,
            cell: (row: any) => (
                <Box
                    as="span"
                    cursor={"pointer"}
                    _hover={{ textDecoration: "underline" }}
                    onClick={() => handleViewInquiry(row)}
                    color={"red"}
                    fontWeight={"normal"}
                >
                    #{row?._id && row?._id?.substr(row._id.length - 5)}
                </Box>
            )
        },
        {
            id: 2,
            name: t("inquiries.user_name"),
            sortable: true,
            wrap: true,
            omit: false,
            cell: (row: any) => (
                <Box
                    as="span"
                    cursor={"pointer"}
                    _hover={{ textDecoration: "underline" }}
                    onClick={() => handleViewUser(row?.user)}
                >
                    {row?.name}
                </Box>
            )
        },
        {
            id: 3,
            name: t("inquiries.date"),
            selector: (row: any) => dayjs(row?.createdAt).format("YYYY/MM/DD HH:mm"),
            sortable: true,
            wrap: true,
            omit: false
        },
        {
            id: 4,
            name: t("inquiries.inquiry_title"),
            selector: (row: any) => row?.title,
            wrap: true,
            omit: false
        },
        {
            id: 5,
            name: t("inquiries.status"),
            selector: (row: any) => row.status,
            sortable: true,
            cell: (row: any) => (
                <Badge
                    variant="solid"
                    colorScheme={
                        row.status === "INQUIRY_ACCEPTED"
                            ? "cyan"
                            : row.status === "INQUIRY_REJECTED"
                            ? "red"
                            : row.status === "INQUIRY_PENDING"
                            ? "telegram"
                            : row.status === "INQUIRY_FINISH"
                            ? "green"
                            : "yellow"
                    }
                >
                    <Text as="span"> {t(`status.${row.status}`)}</Text>
                </Badge>
            ),
            omit: false
        },
        {
            id: 8,
            name: t("common.action"),
            sortable: true,
            omit: false,
            cell: (row: any) => (
                <FaTrash
                    cursor="pointer"
                    onClick={() => {
                        handleDelete(row);
                        // setInquiry(row);
                    }}
                />
            )
        }
    ];

    //inquiry using get Date
    const getInquiryByDate = (startDate: any, endDate: any) => {
        setIsLoading(true);
        disaptch(
            InquireService.getInquiryByDate(
                { startDate, endDate },
                (responseData: any) => {
                    setIsLoading(false);
                },
                (errorData: any) => {}
            )
        );
    };
    useEffect(() => {
        window.scrollTo(0, 0);
        getInquiryByDate(dayjs(startDate).format("YYYY/MM/DD"), dayjs(endDate).format("YYYY/MM/DD"));
    }, []);

    const onChange = (dates: any) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    };

    // search form
    const emptySearchForm = {
        name: "",
        status: ""
    };

    const [searchForm, setSearchForm] = useState(emptySearchForm);

    const filteredInquiry = useMemo(() => {
        let filterData = inquiryTableData.length > 0 ? [...inquiryTableData] : [];

        if (searchForm.name?.trim()) {
            const lowerName = searchForm.name.toLowerCase();
            filterData = filterData.filter((inquiry) => inquiry?.name.toLowerCase().includes(lowerName));
        }

        if (searchForm.status) {
            filterData = filterData.filter((inquiry) => inquiry.status.includes(searchForm.status));
        }

        return filterData;
    }, [inquiryTableData, searchForm]);

    const handlerSearchValue = useCallback((event: any, keyName: any) => {
        const value = event.target.value;

        setSearchForm((prev) => {
            return { ...prev, [keyName]: value };
        });
    }, []);

    // export

    const handleChange = ({ selectedRows }: { selectedRows: any }) => {
        setExportData(selectedRows);
    };

    const { isOpen, onOpen, onClose } = useDisclosure();

    return (
        <>
            <Box>
                {isLoading ? (
                    <LoaderComponent />
                ) : (
                    <Box w="full">
                        <DeleteInquiry
                            isOpen={isDeleteOpen}
                            onClose={onDeleteClose}
                            getInquiryByDate={getInquiryByDate}
                            deleteInquiryId={deleteInquiryId}
                        />
                        <Box px={{ base: 2, md: 0 }}>
                            {/* heading  */}
                            <Text fontSize={"2xl"} fontWeight={"bold"} lineHeight="full" textAlign={"left"}>
                                {t("inquiries.inquiries")}
                            </Text>
                        </Box>

                        {/* date picking  */}

                        <Flex
                            w="full"
                            alignItems={{ base: "baseline", md: "center" }}
                            justify="space-between"
                            direction={{ base: "column-reverse", sm: "row" }}
                            mt={6}
                            gap={2}
                        >
                            <Flex
                                alignItems={{ base: "flex-start", md: "center" }}
                                direction={{ base: "column", md: "row" }}
                                justifyContent={{ base: "flex-start" }}
                                gap={4}
                            >
                                <Text fontSize="md" fontWeight="medium">
                                    {t("common.select_date_range")}
                                </Text>
                                <DatePicker
                                    dateFormat="yyyy/MM/dd"
                                    selected={startDate}
                                    placeholderText={t("common.select_date_range")}
                                    onChange={onChange}
                                    startDate={startDate}
                                    maxDate={new Date()}
                                    endDate={endDate}
                                    selectsRange
                                    className="custom"
                                    popperClassName="popper-class"
                                    popperPlacement="bottom-start"
                                    todayButton="Today"
                                    showPopperArrow={false}
                                    popperModifiers={[
                                        {
                                            name: "offset",
                                            options: {
                                                offset: [0, 0]
                                            }
                                        },
                                        {
                                            name: "preventOverflow",
                                            options: {
                                                rootBoundary: "viewport",
                                                tether: false,
                                                altAxis: true
                                            }
                                        }
                                    ]}
                                />
                                <Button
                                    bgColor={globalStyles.colors.btn.blue}
                                    onClick={() => {
                                        let EndDate = endDate;
                                        if (!endDate) {
                                            EndDate = startDate;
                                        }
                                        getInquiryByDate(
                                            dayjs(startDate).format("YYYY/MM/DD"),
                                            dayjs(EndDate).format("YYYY/MM/DD")
                                        );
                                    }}
                                    color={"white"}
                                >
                                    {t("common.show_data")}
                                </Button>
                            </Flex>

                            <ExportBtn exportData={exportData} fileName={t("inquiry")} />
                        </Flex>

                        {/* filters  */}

                        <Flex
                            w="full"
                            gap={5}
                            bg={"white"}
                            p={3}
                            borderRadius="lg"
                            shadow="sm"
                            align="center"
                            flexDir={{ base: "column", md: "row" }}
                            my={3}
                        >
                            <Text fontWeight="semibold" color={"gray.600"} w={{ base: "full", md: "32" }}>
                                {t("common.filter_data")} :
                            </Text>

                            <Input
                                type={"text"}
                                name="name"
                                value={searchForm.name}
                                onChange={(e) => handlerSearchValue(e, "name")}
                                placeholder={t("user_mgmt.type_name")}
                                variant="main"
                                w={{ base: "full", md: "3xs" }}
                            />

                            <Select
                                name="status"
                                value={searchForm.status}
                                onChange={(e) => {
                                    handlerSearchValue(e, "status");
                                }}
                                w={{ base: "full", md: "3xs" }}
                            >
                                {config.INQUIRY_STATUS.map((inquiry: any, i: number) => {
                                    return (
                                        <option value={inquiry.value} key={inquiry.label}>
                                            {t(`status.${inquiry.label}`)}
                                        </option>
                                    );
                                })}
                            </Select>
                        </Flex>

                        <Box mt={2}>
                            <DataTableComponent
                                column={tableColumn}
                                data={filteredInquiry}
                                handleChange={handleChange}
                                selectableRows={true}
                            />
                        </Box>
                    </Box>
                )}
            </Box>

            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Delete Inquiry</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>Are you sure ? you want to Delete this Inquiry </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="red" onClick={() => {}} mr={3}>
                            yes
                        </Button>
                        <Button
                            variant="solid"
                            colorScheme={"blue"}
                            onClick={() => {
                                onClose();

                                handleDelete(inquiry);
                                //     getReviewsByDate(
                                //         dayjs(startDate).format("YYYY/MM/DD"),
                                //         dayjs(endDate).format("YYYY/MM/DD")
                                //     );
                            }}
                        >
                            no
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
}
