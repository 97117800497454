import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { ChakraProvider } from "@chakra-ui/react";
import App from "./App";
import store from "./store";
import customTheme from "./theme/theme";

import "react-datepicker/dist/react-datepicker.css";
import "./index.css";

//i18 instance
import i18n from "./i18n/config";
import { I18nextProvider } from "react-i18next";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
    <Provider store={store}>
        <I18nextProvider i18n={i18n}>
            <ChakraProvider theme={customTheme}>
                <App />
            </ChakraProvider>
        </I18nextProvider>
    </Provider>
);
