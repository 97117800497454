import { Box, useStyleConfig } from "@chakra-ui/react";

const Card = ({ variant, children, ...rest }: any) => {
    const styles = useStyleConfig("Card", { variant });

    return (
        <Box __css={styles} {...rest} shadow="sm">
            {children}
        </Box>
    );
};

export default Card;
