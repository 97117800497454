import { Box, Image } from "@chakra-ui/react";
import { BlankStar, FullStar } from "../icons/Icons";

interface RatingProps {
    rating: number;
}

const RatingStar = ({ rating }: RatingProps) => {
    return (
        <Box display="flex" alignItems="center">
            {Array(5)
                .fill("")
                .map((_, i) => {
                    const roundedRating = Math.round(rating * 2) / 2;

                    if (roundedRating - i >= 1) {
                        return <FullStar marginLeft={"0.7"} key={i} />;
                    }
                    if (roundedRating - i === 0.5) {
                        return <Image src="/images/star/halfstar.svg" width={4} marginLeft={"0.7"} key={i} />;
                    }
                    return <BlankStar marginLeft={"0.7"} key={i} />;
                })}
        </Box>
    );
};

export default RatingStar;
