import {
    API_INVOCATION,
    ACTIVE_GROUP,
    CREATE_GROUP,
    DELETE_GROUP,
    GET_ALL_GROUPS,
    UPDATE_GROUP
} from "../store/actionTypes";
import { DELETE, GET, POST, PATCH } from "../utils/apiConstant";
import { GROUP } from "../utils/url";

class GroupService {
    getAllGroups(resolve: any, reject: any) {
        const payload = {
            action: GET_ALL_GROUPS,
            method: GET,
            apiConfig: {},
            url: GROUP,
            resolve,
            reject
        };
        return { type: API_INVOCATION, payload };
    }
    createGroup(_payload: any, resolve: any, reject: any) {
        const payload = {
            action: CREATE_GROUP,
            method: POST,
            apiConfig: {},
            url: GROUP,
            resolve,
            reject,
            data: _payload
        };
        return { type: API_INVOCATION, payload };
    }
    updateGroup(_payload: any, resolve: any, reject: any) {
        const payload = {
            action: UPDATE_GROUP,
            method: PATCH,
            apiConfig: {},
            url: `${GROUP}/${_payload.id}`,
            resolve,
            reject,
            data: _payload.data
        };
        return { type: API_INVOCATION, payload };
    }
    deleteGroup(_payload: any, resolve: any, reject: any) {
        const payload = {
            action: DELETE_GROUP,
            method: DELETE,
            apiConfig: {},
            url: `${GROUP}/delete-group/${_payload.id}`,
            resolve,
            reject
        };
        return { type: API_INVOCATION, payload };
    }

    getActiveGroup(resolve: any, reject: any) {
        const payload = {
            action: ACTIVE_GROUP,
            method: GET,
            apiConfig: {},
            url: `${GROUP}/?enabled=true`,
            resolve,
            reject
        };
        return { type: API_INVOCATION, payload };
    }
}

export default new GroupService();
