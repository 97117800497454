import { useState, useEffect, useMemo, useCallback } from "react";
import {
    Badge,
    Box,
    Button,
    Flex,
    HStack,
    Image,
    Input,
    Select,
    Text,
    useToast,
    VStack,
    Avatar,
    Grid,
    GridItem
} from "@chakra-ui/react";
import dayjs from "dayjs";

// redux
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../store";

//component
import DataTableComponent from "../../../components/dataTable/DataTable";
import { ScrollToTop } from "../../../components/scroll/ScrollToTop";

import { useTranslation } from "react-i18next";

import DatePicker from "react-datepicker";
import { globalStyles } from "../../../theme/styles";
import UserDetailService from "../../../services/UserDetailService";
import LoaderComponent from "../../../components/LoaderComponent";

import config from "../../../utils/config";
import ExportBtn from "../../../components/button/ExportBtn";
import { createPhoneNumber } from "../../../utils/helperFunctions";

const Users = () => {
    const toast = useToast();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const disaptch = useDispatch<AppDispatch>();
    const today = new Date();

    const [startDate, setStartDate] = useState(new Date(today.getFullYear(), today.getMonth(), 1));
    const [endDate, setEndDate] = useState(today);
    const userTableData = useSelector((state: any) => state?.User?.allUsers);

    const [isLoading, setIsLoading] = useState(false);
    const [exportData, setExportData] = useState([]);

    const [tableColumn, setTableColumn] = useState<any[]>([
        {
            id: 1,
            name: t("common.id").toUpperCase(),
            wrap: 0,

            cell: (row: any) => (
                <Text
                    as={"span"}
                    cursor={"pointer"}
                    _hover={{ textDecoration: "underline" }}
                    onClick={() => handleViewUser(row)}
                    color={"red"}
                    fontWeight={"normal"}
                >
                    #{row._id && row._id?.substring(row._id.length - 5)}
                </Text>
            )
        },
        {
            id: 2,
            name: t("user_mgmt.name").toUpperCase(),
            grow: 1.3,
            cell: (row: any) => {
                let fullName = row.name;
                let profile = "";

                if (row?.last_name === undefined) {
                    fullName = row?.name;
                } else {
                    fullName = row.last_name + " " + row.first_name;
                }

                if (row.profile) {
                    profile = row?.profile?.url;
                }
                return (
                    <Flex onClick={() => handleViewUser(row._id)} w={"32"} gap={3} alignItems={"center"}>
                        {/* <Avatar name={fullName} cursor={"pointer"} size="sm" textAlign={"center"} /> */}

                        {profile === "" ? (
                            <Avatar cursor={"pointer"} size="sm" textAlign={"center"} name={fullName} />
                        ) : (
                            <Avatar cursor={"pointer"} size="sm" textAlign={"center"} src={profile} />
                        )}

                        <Text>{fullName}</Text>
                    </Flex>
                );
            },

            wrap: 0,
            omit: false
        },

        {
            id: 4,
            name: t("user_mgmt.email").toUpperCase(),
            selector: (row: any) => (row.email ? row.email : "-"),
            sortable: true,
            omit: false
        },
        {
            id: 5,
            name: t("user_mgmt.phone_number").toUpperCase(),
            selector: (row: any) => (row.phone_number ? createPhoneNumber(row?.phone_number) : "-"),
            sortable: true,
            wrap: true,
            omit: false
        },
        {
            id: 6,
            name: t("user_mgmt.last_login").toUpperCase(),
            selector: (row: any) => dayjs(row.last_login).format("YYYY/MM/DD HH:mm"),
            sortable: true,
            wrap: true,
            omit: false
        },
        {
            id: 7,
            name: t("user_mgmt.account_status").toUpperCase(),
            selector: (row: any) => row.status,
            sortable: true,
            cell: (row: any) => (
                <Badge
                    variant="solid"
                    colorScheme={row.status === "ACTIVE" ? "green" : row.status === "DELETED" ? "red" : "blackAlpha"}
                >
                    {t(`status.${row.status}`)}
                </Badge>
            ),

            omit: false
        }
    ]);

    const onChange = (dates: any) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    };

    useEffect(() => {
        ScrollToTop();
    }, []);

    const handleViewUser = (row: any) => {
        navigate(`/user-management/${row._id}`);
        // sessionStorage.setItem("selectedtabadmin", "users");
    };

    // filter

    const [searchForm, setSearchForm] = useState({
        name: "",
        email: "",
        phone: "",
        status: ""
    });

    const filteredUsers = useMemo(() => {
        let filterData = userTableData?.length > 0 ? [...userTableData] : [];
        if (searchForm.name?.trim()) {
            const lowerName = searchForm.name?.toLowerCase();
            filterData = filterData.filter((row) => {
                if (row.last_name || row.first_name)
                    return (
                        String(row.last_name + " " + row.first_name)
                            .toLowerCase()
                            .includes(lowerName) ||
                        String(row.last_name + row.first_name)
                            .toLowerCase()
                            .includes(lowerName) ||
                        String(row.first_name + " " + row.last_name)
                            .toLowerCase()
                            .includes(lowerName) ||
                        String(row.first_name + row.last_name)
                            .toLowerCase()
                            .includes(lowerName)
                    );

                if (row.name) return String(row.name).toLowerCase().includes(lowerName);
            });
        }

        if (searchForm.email?.trim()) {
            const lowerEmail = searchForm.email?.toLowerCase();
            return (filterData = filterData.filter((user) => user.email?.toLowerCase().includes(lowerEmail)));
        }

        if (searchForm.phone?.trim()) {
            return (filterData = filterData.filter((user) => user.phone_number?.includes(searchForm.phone)));
        }

        if (searchForm.status) {
            return (filterData = filterData.filter((user) => user.status.includes(searchForm.status)));
        }

        return filterData;
    }, [userTableData, searchForm]);

    const handlerSearchValue = useCallback((event: any, keyName: any) => {
        const value = event.target.value;

        setSearchForm((prev) => {
            return { ...prev, [keyName]: value };
        });
    }, []);

    // export

    const handleChange = ({ selectedRows }: { selectedRows: any }) => {
        setExportData(selectedRows);
    };

    //get user by dates
    const getUsersByDate = (startDate: any, endDate: any) => {
        setIsLoading(true);
        disaptch(
            UserDetailService.getUserByDate(
                { startDate, endDate },
                (responseData: any) => {
                    setIsLoading(false);
                },
                (errorData: any) => {
                    toast({
                        title: errorData?.message ? errorData?.message : errorData.response?.data?.message,
                        status: "error",
                        variant: "solid",
                        duration: 2000,
                        position: "top-right",
                        isClosable: true
                    });
                }
            )
        );
    };
    useEffect(() => {
        getUsersByDate(dayjs(startDate).format("YYYY/MM/DD"), dayjs(endDate).format("YYYY/MM/DD"));
    }, []);

    // excel

    const getExcelData = async () => {
        const userId = exportData.map((data: any) => data?._id);

        const excelData: any = await new Promise((resolve, reject) => {
            disaptch(
                UserDetailService.getExcelData(
                    { userId },
                    (responseData: any) => {
                        resolve(responseData.data.rows);
                    },
                    (errorData: any) => {
                        toast({
                            title: errorData?.message ? errorData?.message : errorData.response?.data?.message,
                            status: "error",
                            variant: "solid",
                            duration: 2000,
                            position: "top-right",
                            isClosable: true
                        });
                        reject();
                    }
                )
            );
        });

        return excelData;
    };

    return (
        <Box>
            {isLoading ? (
                <LoaderComponent />
            ) : (
                <Box w="full">
                    <Box px={{ base: 2, md: 0 }}>
                        {/* heading  */}
                        <Text fontSize={"2xl"} fontWeight={"bold"} lineHeight="full" textAlign={"left"}>
                            {t("user_mgmt.users_management")}
                        </Text>

                        {/* date picking  */}
                        <Flex
                            w="full"
                            alignItems={{ base: "baseline", md: "center" }}
                            justify="space-between"
                            direction={{ base: "column-reverse", sm: "row" }}
                            mt={6}
                            gap={2}
                        >
                            <Flex
                                alignItems={{ base: "flex-start", md: "center" }}
                                direction={{ base: "column", md: "row" }}
                                justifyContent={{ base: "flex-start" }}
                                gap={4}
                            >
                                <Text fontSize="md" fontWeight="medium">
                                    {t("common.select_date_range")}
                                </Text>
                                <DatePicker
                                    dateFormat="yyyy/MM/dd"
                                    selected={startDate}
                                    placeholderText={t("common.select_date_range")}
                                    onChange={onChange}
                                    startDate={startDate}
                                    endDate={endDate}
                                    selectsRange
                                    className="custom"
                                    popperClassName="popper-class"
                                    popperPlacement="bottom-start"
                                    todayButton="Today"
                                    showPopperArrow={false}
                                    maxDate={new Date()}
                                    popperModifiers={[
                                        {
                                            name: "offset",
                                            options: {
                                                offset: [0, 0]
                                            }
                                        },
                                        {
                                            name: "preventOverflow",
                                            options: {
                                                rootBoundary: "viewport",
                                                tether: false,
                                                altAxis: true
                                            }
                                        }
                                    ]}
                                />
                                <Button
                                    bgColor={globalStyles.colors.btn.blue}
                                    onClick={() => {
                                        let EndDate = endDate;
                                        if (!endDate) {
                                            EndDate = startDate;
                                        }
                                        getUsersByDate(
                                            dayjs(startDate).format("YYYY/MM/DD"),
                                            dayjs(EndDate).format("YYYY/MM/DD")
                                        );
                                    }}
                                    color={"white"}
                                >
                                    {t("common.show_data")}
                                </Button>
                            </Flex>

                            <ExportBtn exportData={exportData} fileName={t("users")} getExcelData={getExcelData} />
                        </Flex>
                    </Box>

                    {/* filters  */}

                    <Grid
                        templateColumns={{
                            base: "repeat(1, 1fr)",
                            md: "repeat(3, 1fr)",
                            lg: "repeat(5, 1fr)"
                        }}
                        templateRows={{
                            base: "repeat(4, 1fr)",
                            md: "repeat(2, 1fr)",
                            lg: "repeat(1, 1fr)"
                        }}
                        mt={3}
                        mb={2}
                        p={3}
                        justifyContent={{
                            base: "center"
                        }}
                        gap={3}
                        alignItems="center"
                        bg={"white"}
                        borderRadius="lg"
                        shadow="sm"
                    >
                        <GridItem w="full">
                            <Text pr={{ base: "unset", md: "2" }} fontSize="md" fontWeight="medium" flex={2}>
                                {t("common.filter_data")} :
                            </Text>
                        </GridItem>
                        <GridItem w="full">
                            <Input
                                type={"text"}
                                name="name"
                                value={searchForm.name}
                                onChange={(e) => handlerSearchValue(e, "name")}
                                placeholder={t("user_mgmt.type_name")}
                                variant="main"
                            />
                        </GridItem>
                        <GridItem w="full">
                            <Input
                                type={"text"}
                                name="searchEmail"
                                value={searchForm.email}
                                onChange={(e) => handlerSearchValue(e, "email")}
                                placeholder={t("user_mgmt.type_email")}
                                variant="main"
                            />
                        </GridItem>
                        <GridItem w="full">
                            <Input
                                type={"text"}
                                name="searchPhone"
                                value={searchForm.phone}
                                onChange={(e) => handlerSearchValue(e, "phone")}
                                placeholder={t("user_mgmt.type_mobile")}
                                variant="main"
                            />
                        </GridItem>
                        <GridItem w="full">
                            <Select
                                name="status"
                                value={searchForm.status}
                                onChange={(e) => handlerSearchValue(e, "status")}
                                variant="main"
                            >
                                {config.USER_PROFILE_STATUS.map((user: any, i: number) => {
                                    return (
                                        <option value={user.value} key={i}>
                                            {t(`status.${user.label}`)}
                                        </option>
                                    );
                                })}
                            </Select>
                        </GridItem>
                    </Grid>

                    <Box>
                        <DataTableComponent
                            column={tableColumn}
                            data={filteredUsers}
                            handleChange={handleChange}
                            selectableRows={true}
                        />
                    </Box>
                </Box>
            )}
        </Box>
    );
};

export default Users;
