import { GET_ALL_SALES_DETAILS_SUCCESS, GET_ORDER_DETAIL_SUCCESS, SELECTED_SALES } from "../actionTypes";

const INITIAL_STATE = {
    allSales: [],
    selectedSales: {}
};

const Sales = (state = INITIAL_STATE, action: { type: string; payload: any }) => {
    switch (action.type) {
        case GET_ALL_SALES_DETAILS_SUCCESS:
            return {
                ...state,
                allSales: [...action.payload.data.rows]
            };
        case GET_ORDER_DETAIL_SUCCESS:
            return {
                ...state,
                selectedSales: action.payload.data
            };
        default:
            return state;
    }
};

export default Sales;
