import {
    API_INVOCATION,
    ACTIVE_CATEGORY,
    CREATE_CATEGORY,
    DELETE_CATEGORY,
    GET_ALL_CATEGORIES,
    UPDATE_CATEGORY
} from "../store/actionTypes";
import { DELETE, GET, POST, PATCH } from "../utils/apiConstant";
import { CATEGORY } from "../utils/url";

class CategoryService {
    getAllCategories(resolve: any, reject: any) {
        const payload = {
            action: GET_ALL_CATEGORIES,
            method: GET,
            apiConfig: {},
            url: CATEGORY,
            resolve,
            reject
        };
        return { type: API_INVOCATION, payload };
    }
    createCategory(_payload: any, resolve: any, reject: any) {
        const payload = {
            action: CREATE_CATEGORY,
            method: POST,
            apiConfig: {},
            url: CATEGORY,
            resolve,
            reject,
            data: _payload.data
        };
        return { type: API_INVOCATION, payload };
    }
    updateCategory(_payload: any, resolve: any, reject: any) {
        const payload = {
            action: UPDATE_CATEGORY,
            method: PATCH,
            apiConfig: {},
            url: `${CATEGORY}/${_payload.data.id}`,
            resolve,
            reject,
            data: _payload.data.data
        };
        return { type: API_INVOCATION, payload };
    }
    deleteCategory(_payload: any, resolve: any, reject: any) {
        const payload = {
            action: DELETE_CATEGORY,
            method: DELETE,
            apiConfig: {},
            url: `${CATEGORY}/delete-category/${_payload.id}`,
            resolve,
            reject
        };
        return { type: API_INVOCATION, payload };
    }

    getActiveCategory(resolve: any, reject: any) {
        const payload = {
            action: ACTIVE_CATEGORY,
            method: GET,
            apiConfig: {},
            url: `${CATEGORY}/?enabled=true`,
            resolve,
            reject
        };
        return { type: API_INVOCATION, payload };
    }
}

export default new CategoryService();
