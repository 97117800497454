import React, { useEffect, useState } from "react";
import {
    Stack,
    Flex,
    Button,
    Text,
    Box,
    Grid,
    GridItem,
    Select,
    Textarea,
    Input,
    useToast,
    Badge
} from "@chakra-ui/react";

import { AppDispatch } from "../../../store";
import { useDispatch, useSelector } from "react-redux";

import SalesService from "../../../services/SalesService";

import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { globalStyles } from "../../../theme/styles";

import * as yup from "yup";
import { useFormik } from "formik";

import ReturnButton from "../../../components/fields/ReturnButton";
import OrderDetailsTab from "./orderInfo/OrderDetailsTab";
import OrderProductsInfo from "./orderInfo/OrderProductsInfo";
import LoaderComponent from "../../../components/LoaderComponent";
import config from "../../../utils/config";
import { handleDownloadPDF, numberWithCommas } from "../../../utils/helperFunctions";

const OrderInformation = () => {
    const navigate = useNavigate();
    const disaptch = useDispatch<AppDispatch>();
    const [isLoading, setIsLoading] = useState(false);
    const { t } = useTranslation();

    const { orderId } = useParams();
    const OrderData = useSelector((state: any) => state?.Sales?.selectedSales);

    const toast = useToast();

    const onSubmit = async (values: any, actions: any) => {
        disaptch(
            SalesService.addOrderData(
                { data: values },
                (responseData: any) => {
                    getOrderDetail();
                    toast({
                        title: responseData?.message ? responseData?.message : responseData.response?.data?.message,
                        status: "success",
                        variant: "solid",
                        duration: 2000,
                        position: "top-right",
                        isClosable: true
                    });
                },
                (errorData: any) => {
                    toast({
                        title: errorData?.message ? errorData?.message : errorData.response?.data?.message,
                        status: "error",
                        variant: "solid",
                        duration: 2000,
                        position: "top-right",
                        isClosable: true
                    });
                }
            )
        );
    };

    const { values, errors, touched, isSubmitting, handleBlur, handleChange, handleSubmit, setFieldValue } = useFormik({
        initialValues: {
            tracking_id: "",
            status: "ORDER",
            memo: "",
            order_id: orderId
        },
        onSubmit
    });

    const [subTotalAmount, setSubTotalAmount] = useState(0);

    const getOrderDetail = () => {
        setIsLoading(true);
        disaptch(
            SalesService.getOrder(
                { id: orderId },
                (responseData: any) => {
                    setIsLoading(false);

                    const items = responseData.data.items;
                    const array: any = [];

                    items.map((item: any) => {
                        array.push(Number(item.price) * Number(item.items));
                    });

                    const sumWithInitial = array.reduce((a: any, b: any) => Number(a) + Number(b), 0);

                    setSubTotalAmount(sumWithInitial);

                    setFieldValue("tracking_id", responseData?.data?.tracking_id);
                    if (responseData?.data?.status === "PAYMENT-PENDING") {
                        setFieldValue("status", "ORDER");
                    } else {
                        setFieldValue("status", responseData?.data?.status);
                    }
                    setFieldValue("memo", responseData?.data?.memo);
                },

                (errorData: any) => {
                    toast({
                        title: errorData?.message ? errorData?.message : errorData.response?.data?.message,
                        status: "error",
                        variant: "solid",
                        duration: 2000,
                        position: "top-right",
                        isClosable: true
                    });
                }
            )
        );
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        getOrderDetail();
    }, []);

    return (
        <Box>
            {isLoading ? (
                <LoaderComponent />
            ) : (
                <Box px={{ base: 4, md: 8, xl: 2 }} py={5}>
                    <Flex w="full">
                        <ReturnButton />
                    </Flex>
                    <Flex flexDir={"column"} gap={5} my={5}>
                        <Flex gap={3} flexDir={{ base: "column", lg: "row" }}>
                            <Flex flex={2}>
                                <OrderDetailsTab />
                            </Flex>
                            <Flex flexDir={{ base: "row", lg: "column" }} flex={1} gap={5}>
                                <Flex flex={1}>
                                    {/* price  */}
                                    <Box p={4} bgColor={"white"} w={"full"} rounded={"lg"} shadow={"lg"}>
                                        <Box w={"70%"}>
                                            <Text fontWeight={"semibold"} fontSize={"2xl"}>
                                                {t("sales_mgmt.price")}
                                            </Text>

                                            <Flex flexDir={"column"} gap={2} ml={7} mt={3}>
                                                <Flex>
                                                    <Text flex={1}>{t("sales_mgmt.sub_total")}:</Text>
                                                    <Text flex={1} textAlign={"end"}>
                                                        &#165;{numberWithCommas(subTotalAmount)}
                                                    </Text>
                                                </Flex>
                                                <Flex>
                                                    <Text flex={1}>{t("sales_mgmt.shipping")}:</Text>
                                                    <Text flex={1} textAlign={"end"}>
                                                        &#165;
                                                        {OrderData?.delivery_charge &&
                                                            numberWithCommas(
                                                                Number(OrderData?.delivery_charge).toFixed()
                                                            )}
                                                    </Text>
                                                </Flex>

                                                {OrderData?.payment_type === "COD" && (
                                                    <Flex>
                                                        <Text flex={1}>{t("sales_mgmt.cod_charges")}:</Text>
                                                        <Text flex={1} textAlign={"end"}>
                                                            &#165;
                                                            {OrderData?.cod_charge &&
                                                                numberWithCommas(Number(OrderData?.cod_charge))}
                                                        </Text>
                                                    </Flex>
                                                )}

                                                <Flex>
                                                    <Text flex={1} fontWeight={"semibold"}>
                                                        {t("sales_mgmt.total")}:
                                                    </Text>
                                                    <Text flex={1} fontWeight={"semibold"} textAlign={"end"}>
                                                        &#165;
                                                        {OrderData?.total_amount &&
                                                            numberWithCommas(Number(OrderData?.total_amount))}
                                                    </Text>
                                                </Flex>
                                            </Flex>
                                        </Box>
                                    </Box>
                                </Flex>

                                <Flex flex={1}>
                                    {/* invoice  */}

                                    {OrderData.status != "CANCEL" && (
                                        <Box p={4} bgColor={"white"} w={"full"} rounded={"lg"} shadow={"lg"}>
                                            <Text fontWeight={"semibold"} fontSize={"2xl"}>
                                                {t("sales_mgmt.invoice")}
                                            </Text>

                                            <Flex flexDir={"column"} gap={2} mt={3}>
                                                {/* <Flex>
                                                    <Text flex={1}>{t("sales_mgmt.invoice_numer")} :</Text>
                                                    <Text flex={1}> </Text>
                                                </Flex>
                                                <Flex>
                                                    <Text flex={1}>{t("sales_mgmt.tax_number")}:</Text>
                                                    <Text flex={1}> </Text>
                                                </Flex> */}
                                                <Flex>
                                                    <Button
                                                        bgColor={globalStyles.colors.btn.blue}
                                                        color={"white"}
                                                        onClick={() => handleDownloadPDF(orderId)}
                                                    >
                                                        {t("sales_mgmt.download_invoice")}
                                                    </Button>
                                                </Flex>
                                            </Flex>
                                        </Box>
                                    )}
                                </Flex>
                            </Flex>
                        </Flex>

                        <OrderProductsInfo />

                        {OrderData.status === "CANCEL" ? (
                            ""
                        ) : (
                            <Flex w={"full"} gap={4}>
                                {/* add_additional_details */}
                                <Box w={"full"} bgColor={"white"} rounded={"lg"} shadow={"lg"} p={4}>
                                    <Flex>
                                        <Text fontWeight={"semibold"} fontSize={"2xl"}>
                                            {t("product_mgmt.add_additional_details")}
                                        </Text>
                                    </Flex>

                                    <form onSubmit={handleSubmit}>
                                        <Flex gap={2} flexDir={"column"} width={"60%"}>
                                            {/* tracking_id */}
                                            <Flex alignItems={"center"} justifyContent={"space-between"} width={"full"}>
                                                <Text>{t("sales_mgmt.tracking_id")}:</Text>
                                                <Input
                                                    name={"tracking_id"}
                                                    w={"lg"}
                                                    type={"text"}
                                                    value={values.tracking_id}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                            </Flex>

                                            {/* select_status */}
                                            <Flex alignItems={"center"} justifyContent={"space-between"} width={"full"}>
                                                <Text>{t("product_mgmt.select_status")}:</Text>

                                                <Select
                                                    name="status"
                                                    w={"lg"}
                                                    value={values.status}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    variant="main"
                                                    rounded={"none"}
                                                >
                                                    <option value={"ORDER"}>{t(`status.ORDER`)}</option>
                                                    {/* <option value={"OUT-OF-DELIVERED"}>
                                                        {t(`status.OUT-OF-DELIVERED`)}
                                                    </option> */}
                                                    <option value={"DELIVERED"}>{t(`status.DELIVERED`)}</option>

                                                    {OrderData.status == "DELIVERED" ? (
                                                        ""
                                                    ) : (
                                                        <option value="CANCEL">{t(`status.CANCEL`)}</option>
                                                    )}

                                                    {/* 
                                                    {config.ORDER_STATUS2.map((order: any) => {
                                                        return (
                                                            <option value={order.value} key={order.value}>
                                                                {t(`status.${order.label}`)}
                                                            </option>
                                                        );
                                                    })} */}
                                                </Select>
                                            </Flex>

                                            <Flex justifyContent={"space-between"} width={"full"}>
                                                <Text>{t("inquiries.add_memo")}:</Text>
                                                <Textarea
                                                    name={"memo"}
                                                    value={values.memo}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    h={"32"}
                                                    w={"lg"}
                                                />
                                            </Flex>
                                        </Flex>

                                        <Button
                                            bgColor={globalStyles.colors.btn.blue}
                                            type={"submit"}
                                            color={"white"}
                                            isLoading={isSubmitting}
                                        >
                                            {t("product_mgmt.save")}
                                        </Button>
                                    </form>
                                </Box>
                            </Flex>
                        )}
                    </Flex>
                </Box>
            )}
        </Box>
    );
};

export default OrderInformation;
