import { GET_ALL_REVIEWS_SUCCESS, SELECTED_REVIEW } from "../actionTypes";

const INITIAL_STATE = {
    allReviews: [],
    selectedReview: {}
};
const Review = (state = INITIAL_STATE, action: { type: string; payload: any }) => {
    switch (action.type) {
        case GET_ALL_REVIEWS_SUCCESS:
            return {
                ...state,
                allReviews: action.payload.data.rows
            };

        case SELECTED_REVIEW:
            return {
                ...state,
                selectedReview: action.payload
            };

        default:
            return state;
    }
};

export default Review;
