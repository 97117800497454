import { Box, Grid, GridItem, Image, Text, useToast } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DataTableComponent from "../../../../components/dataTable/DataTable";
import { numberWithCommas } from "../../../../utils/helperFunctions";

const OrderProductsInfo = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const OrderData = useSelector((state: any) => state?.Sales?.selectedSales);
    const [tableColumn, setTableColumn] = useState<any[]>([
        {
            id: 1,
            name: t("sales_mgmt.image").toUpperCase(),
            wrap: true,
            cell: (row: any) => (
                <Image
                    as={"img"}
                    onClick={() => {
                        navigate(`/product-management/${row?.product_id?._id}`);
                        // sessionStorage.setItem("selectedtabadmin", "products");
                    }}
                    src={row?.cover_photo?.url}
                    w={20}
                    cursor={"pointer"}
                />
            )
        },
        {
            id: 2,
            name: t("sales_mgmt.product_number").toUpperCase(),
            selector: (row: any) => row?.product_id?.product_number,
            wrap: true,
            omit: false
        },
        {
            id: 3,
            name: t("sales_mgmt.name").toUpperCase(),
            selector: (row: any) => row?.name,
            wrap: true,
            omit: false
        },

        {
            id: 4,
            name: t("sales_mgmt.qty").toUpperCase(),
            selector: (row: any) => numberWithCommas(Number(row?.items)),
            wrap: true,
            omit: false
        },
        {
            id: 5,
            name: t("sales_mgmt.price").toUpperCase(),
            selector: (row: any) => "¥" + numberWithCommas(Number(row?.price)),
            omit: false
        },
        {
            id: 6,
            name: t("sales_mgmt.total").toUpperCase(),
            selector: (row: any) => "¥" + numberWithCommas(Number(row?.items) * Number(row?.price)),
            sortable: true,
            wrap: true,
            omit: false
        }
    ]);

    return (
        <Box bgColor={"white"} rounded={"lg"} shadow={"lg"} p={3} w={"full"}>
            <Text fontWeight={"semibold"} fontSize={"2xl"}>
                {t("sales_mgmt.order_items")}
            </Text>

            <Box as="table" my={6} w="full">
                <DataTableComponent
                    column={tableColumn}
                    data={OrderData?.items}
                    handleChange={() => {}}
                    selectableRows={false}
                />
            </Box>
        </Box>
    );
};

export default OrderProductsInfo;
