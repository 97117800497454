import React from "react";
import { Box, Flex, Icon, Text, useColorModeValue } from "@chakra-ui/react";
import BarChart from "../../../../components/charts/BarChart";
import Card from "../../../../components/card/Card";

// Assets
import { RiArrowDownSFill, RiArrowUpSFill } from "react-icons/ri";
import { useTranslation } from "react-i18next";

interface DailyTrafficProps {
    title: string;
    total: string;
    data: any;
}

const DailyTraffic: React.FC<DailyTrafficProps> = ({ title, total, data }) => {
    const textColor = useColorModeValue("secondaryGray.900", "white");
    const { t } = useTranslation();

    const arrayData = Object.keys(data).map((key) => data[key]);
    arrayData.pop();
    arrayData.pop();

    const barChartDataDailyTraffic = [
        {
            name: title,
            data: arrayData
        }
    ];

    const today = new Date();
    const first = today.getDate() - today.getDay(); // First day is the day of the month - the day of the week
    const second = first + 1;
    const third = first + 2;
    const fourth = first + 3;
    const fifth = first + 4;
    const sixth = first + 5;
    const seven = first + 6;

    const barChartOptionsDailyTraffic = {
        chart: {
            toolbar: {
                show: false
            }
        },
        tooltip: {
            style: {
                fontSize: "12px",
                fontFamily: undefined
            },
            onDatasetHover: {
                style: {
                    fontSize: "12px",
                    fontFamily: undefined
                }
            },
            theme: "dark"
        },
        xaxis: {
            categories: [
                `${new Date(today.setDate(first)).getDate()}日(日)`,
                `${new Date(today.setDate(second)).getDate()}日(月)`,
                `${new Date(today.setDate(third)).getDate()}日(火)`,
                `${new Date(today.setDate(fourth)).getDate()}日(水)`,
                `${new Date(today.setDate(fifth)).getDate()}日(木)`,
                `${new Date(today.setDate(sixth)).getDate()}日(金)`,
                `${new Date(today.setDate(seven)).getDate()}日(土)`
            ],
            show: false,
            labels: {
                show: true,
                style: {
                    colors: "#A3AED0",
                    fontSize: "14px",
                    fontWeight: "500"
                }
            },
            axisBorder: {
                show: false
            },
            axisTicks: {
                show: false
            }
        },
        yaxis: {
            show: false,
            color: "black",
            labels: {
                show: true,
                style: {
                    colors: "#CBD5E0",
                    fontSize: "14px"
                }
            }
        },
        grid: {
            show: false,
            strokeDashArray: 5,
            yaxis: {
                lines: {
                    show: true
                }
            },
            xaxis: {
                lines: {
                    show: false
                }
            }
        },
        fill: {
            type: "gradient",
            gradient: {
                type: "vertical",
                shadeIntensity: 1,
                opacityFrom: 0.7,
                opacityTo: 0.9,
                colorStops: [
                    [
                        {
                            offset: 0,
                            color: "#4318FF",
                            opacity: 1
                        },
                        {
                            offset: 100,
                            color: "rgba(67, 24, 255, 1)",
                            opacity: 0.28
                        }
                    ]
                ]
            }
        },
        dataLabels: {
            enabled: false
        },
        plotOptions: {
            bar: {
                borderRadius: 10,
                columnWidth: "40px"
            }
        }
    };

    return (
        <Card align="center" direction="column" w="full">
            <Flex justify="space-between" align="start" px="10px" pt="5px">
                <Flex flexDirection="column" align="start" me="20px">
                    <Flex w="100%">
                        <Text me="auto" color="secondaryGray.600" fontSize="sm" fontWeight="500">
                            {title}
                        </Text>
                    </Flex>
                    <Flex align="end">
                        <Text color={textColor} fontSize="34px" fontWeight="700" lineHeight="100%">
                            {total}
                        </Text>
                    </Flex>
                </Flex>

                <Flex align="center">
                    {data?.growth > 0 ? (
                        <Icon as={RiArrowUpSFill} color="green.500" />
                    ) : (
                        <Icon as={RiArrowDownSFill} color="red.500" />
                    )}

                    {data?.growth > 0 ? (
                        <Text color={"green.500"} fontSize="sm" fontWeight="700">
                            {data.growth}%
                        </Text>
                    ) : (
                        <Text color={"red.500"} fontSize="sm" fontWeight="700">
                            {data.growth}%
                        </Text>
                    )}
                </Flex>
            </Flex>
            <Box h="240px" mt="auto">
                {Object.keys(data).length > 0 && (
                    <BarChart chartData={barChartDataDailyTraffic} chartOptions={barChartOptionsDailyTraffic} />
                )}
            </Box>
        </Card>
    );
};

export default DailyTraffic;
