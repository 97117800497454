import { Box, Text } from "@chakra-ui/react";
import DataTable from "react-data-table-component";
import "./rdtc.scss";
import { useTranslation } from "react-i18next";

interface IProps {
    column: any[];
    data: any[];
    selectableRows: boolean;
    handleChange: any;
}

const DataTableComponent = ({ column, data, handleChange, selectableRows }: IProps) => {
    const { t } = useTranslation();

    const paginationComponentOptions = {
        rowsPerPageText: t("rows_per_page")
    };

    return (
        <Box mb={3}>
            <DataTable
                columns={column}
                data={data}
                pagination
                paginationPerPage={50}
                paginationRowsPerPageOptions={[5, 50, 100, 200, 300, 500]}
                persistTableHead
                responsive
                selectableRows={selectableRows}
                onSelectedRowsChange={handleChange}
                selectableRowsHighlight
                highlightOnHover
                paginationComponentOptions={paginationComponentOptions}
                noDataComponent={<Text my={4}>{t("there_are_no_records_to_display")}</Text>}
            />
        </Box>
    );
};

export default DataTableComponent;
