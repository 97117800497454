// Chakra imports
import { Box, Button, Flex, Icon, Text, useColorModeValue } from "@chakra-ui/react";
import Card from "../../../../components/card/Card";
import LineChart from "../../../../components/charts/LineChart";

import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { numberWithCommas } from "../../../../utils/helperFunctions";

export default function TotalSpent({ ...rest }: any) {
    //setting Data value
    const SixMonthRevenueData = useSelector((state: any) => state?.Dashboard?.SixMonthRevenue);

    const [lastSixMonthData, setLastSixMonthData] = useState([]);

    const { t } = useTranslation();
    const textColor = useColorModeValue("secondaryGray.900", "white");
    const date = new Date();

    useEffect(() => {
        const arrayData: any = Object.keys(SixMonthRevenueData).map((key) =>
            numberWithCommas(Number(SixMonthRevenueData[key]))
        );
        arrayData.shift();
        arrayData.reverse();
        setLastSixMonthData(arrayData);
    }, [SixMonthRevenueData]);

    function addMonths(months: any) {
        date.setMonth(date.getMonth() + months);
        return date.getMonth();
    }

    const lineChartDataTotalSpent = [
        {
            name: t("dashboard.total_earnings"),
            data: lastSixMonthData
        }
    ];

    function setMonth(defineMonth: number) {
        let month = new Date(date.getFullYear(), date.getMonth() - defineMonth, 1).getMonth();
        if (month == 12) {
            month = 1;
        } else {
            month = Number(month) + 1;
        }
        return month;
    }

    const lineChartOptionsTotalSpent = {
        chart: {
            toolbar: {
                show: false
            },
            dropShadow: {
                enabled: true,
                top: 13,
                left: 0,
                blur: 10,
                opacity: 0.1,
                color: "#4318FF"
            }
        },
        colors: ["#4318FF", "#39B8FF"],
        markers: {
            size: 0,
            colors: "white",
            strokeColors: "#7551FF",
            strokeWidth: 3,
            strokeOpacity: 0.9,
            strokeDashArray: 0,
            fillOpacity: 1,
            discrete: [],
            shape: "circle",
            radius: 2,
            offsetX: 0,
            offsetY: 0,
            showNullDataPoints: true
        },
        tooltip: {
            theme: "dark"
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: "smooth",
            type: "line"
        },
        xaxis: {
            type: "numeric",
            categories: [
                `${setMonth(5)}月`,
                `${setMonth(4)}月`,
                `${setMonth(3)}月`,
                `${setMonth(2)}月`,
                `${setMonth(1)}月`,
                `${setMonth(0)}月`,
                `${addMonths(-1)}月`
            ],
            labels: {
                style: {
                    colors: "#A3AED0",
                    fontSize: "12px",
                    fontWeight: "500"
                }
            },
            axisBorder: {
                show: false
            },
            axisTicks: {
                show: false
            }
        },
        yaxis: {
            show: false
        },
        legend: {
            show: false
        },
        grid: {
            show: false,
            column: {
                color: ["#7551FF", "#39B8FF"],
                opacity: 0.5
            }
        },
        color: ["#7551FF", "#39B8FF"]
    };

    return (
        <Card justifyContent="center" align="center" direction="column" w="full" mb="0px" {...rest}>
            <Text textAlign="left" fontWeight="semibold" fontSize="lg">
                {t("dashboard.total_earnings_six_month")}
            </Text>
            <Flex w="full" flexDirection={{ base: "column", lg: "row" }} gap={4}>
                <Flex mt={5}>
                    <Text color={textColor} fontSize="34px" textAlign="start" fontWeight="700" lineHeight="100%">
                        &#165;
                        {SixMonthRevenueData &&
                            SixMonthRevenueData?.total &&
                            numberWithCommas(SixMonthRevenueData?.total)}
                    </Text>
                </Flex>
            </Flex>
            <Box minH="260px" minW="75%" mt="auto">
                {lastSixMonthData.length > 0 && (
                    <LineChart chartData={lineChartDataTotalSpent} chartOptions={lineChartOptionsTotalSpent} />
                )}
            </Box>
        </Card>
    );
}
